
export class Questionnaire {
  questions: Question[];
  questionnaireDesc: string = "";
  questionnaireName: string = "";
  controlName: string = "";
  isActive: boolean = false;
  isDraft: boolean = false;
  publishDate: string;
  createdOn: string;
  createdBy: string = "";
  lastUpdatedOn: string;
  lastupdatedBy: string = "";
  version: string = "";
  questionnaireId: string = "";
}

export class Question {
  questionnaireId: string = "";
  questionId: string = "";
  questionText: string = "";
  triggerType: number = 0;
  triggerQuestionID: string = "";
  triggerOption: number = 0;
  controlName: string = "";
  controlDisplayName: string = "";
  questionShortdesc: string = "";
  questionType: number;
  questionNumber: number;
  questionOrder: number;
  isMandatory: boolean = false;
  isActive: boolean = false;
  createdOn: string;
  createdBy: string = "";
  lastUpdatedOn: string;
  lastupdatedBy: string = "";
  options: QuestionOptions[];
  fileName: string = "";
  isReferenceQuestion: boolean;
  isNewQuestion: boolean = false;
}

export class QuestionOptions {
  id: string = "";
  name: string = "";
  order: number
}

export enum QuestionType {
  text = 1,
  textarea,
  dropdown,
  checkbox,
  radio,
  file,
  grid,
  label
}
export enum TriggerType {
  disable = 1,
  hide
}

