import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  text: string = 'Sample';
  //options: TooltipOptions = {
  //  tooltipInvokeType: 'hover',
  //  tooltipPosition: 'top',
  //  tooltipIndent: 15,
  //  tooltipIsOutsideClick: false,
  //  tooltipHasBeak: true,
  //  tooltipIsDisabled: false,
  //  tooltipType: 'regular',
  //  tooltipSize: 'md'
  //};
  constructor() { }

  ngOnInit(): void {
  }

}
