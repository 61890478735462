import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Observable } from 'rxjs';
import { MasterData } from '../../dynamic-form-components/models/question-base';
import { ApiService } from '../../shared/common-services/api.service';
import { CommonService } from '../../shared/common-services/common.service';
import { ConfigService } from '../../shared/common-services/config.service';

declare var $: any;
@Injectable({
  providedIn: 'root'
})

export class ManageEntityService extends ApiService {
  url = 'https://localhost:44308/api';
  http: any;


  constructor(protected router: Router, protected httpClient: HttpClient, protected config: ConfigService,
    protected authService: MsalService, protected commonService: CommonService) {
    super(router, httpClient, config, authService);
  }

  getData(type: string): Observable<MasterData[]> {
    let url = '/api/MasterData/' + type
    return this.get<any>(url);
  }

  downloadMasterData(type: string){
    let url = "/api/MasterData/DownloadBandEntities/" + type;
    return this.get<any>(url);
}

  getByParentEntityId(type: string, parentEntityId: string): Observable<MasterData[]> {
    let url = '/api/MasterData/GetItemsByParent/' + type + "/" + parentEntityId;
    return this.get<any>(url);
  }
  addData(formData: any) {
    return this.post('/api/MasterData/AddorUpdateMasterData', formData);
  }

  editData(formData: any) {
    return this.post('/api/MasterData/AddorUpdateMasterData', formData);
  }

  deleteData(type: string, id: string, parentTypeId: string) {

    return this.delete('/api/MasterData/delete/' + type + '/' + id + '/' + parentTypeId);
  }

  uploadData(file: File, createdBy: string): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('createdBy', createdBy);
    let url = this.config.getSettings('apiBaseURL') + "/api/MasterData/UploadBannedEntities"
    return this.httpClient.post(url, formData, { headers: new HttpHeaders({ 'Authorization': 'true' }) });

  }

}

