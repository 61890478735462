import { Component, Inject, OnInit, ElementRef, ViewChild, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ManageEntityService } from './services/manage-entity.service';
import { ToastrService } from 'ngx-toastr';
import { fileSizeValidator, requiredFileType } from '../dynamic-form-components/services/question-control.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { SpinnerService } from '../shared/spinner/spinner.service';



@Component({
  selector: 'upload-banned-entity',
  templateUrl: './upload-banned-entity.component.html'
})

export class UploadBannedEntityComponent implements OnInit {

  form?: FormGroup;
  controls: any;
  allControlMetaData: any;
  metaData: any;
  file: File;
  @ViewChild('fileUploader') fileUploader: ElementRef
  ErrorMessage: string ="";
  constructor(private fb: FormBuilder, private manageservice: ManageEntityService, private spinnerService: SpinnerService, private toastr: ToastrService, public dialogRef: MatDialogRef<UploadBannedEntityComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      file: new FormControl("", [Validators.required]),
      fileName: new FormControl("", [Validators.required, requiredFileType(['xlsx'])]),
    });
  }
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }
  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

  isFieldValid(field: string) {
    return !this.form.get(field).valid && this.form.get(field).touched && this.form.get(field).enabled;
  }
  handleUpload(event) {
    this.file = event.target.files[0];
    this.form?.controls['file'].setValidators([fileSizeValidator(this.file)]);
    this.form?.controls['file'].setValue("");
    this.form?.controls["fileName"].setValue(this.file.name);
  }
  onSubmit() {
    let isValid = this.form.get('file').valid && this.form?.controls["fileName"].valid;
    if (isValid) {
      this.spinnerService.showSpinner();
      var loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
      this.manageservice.uploadData(this.file, loggedInUser.name).subscribe(res => {
        if (res.isValid == false) {
          if (res.errorMessage && res.errorMessage.length == 0) {
            this.toastr.error('An error occured while uploading file');
          }
          else {
            this.toastr.error(res.errorMessage);
          }
        }
        else {
          this.reset();
          if (res.bannedEntityWithErrors != null && res.bannedEntityWithErrors.length != 0) {
            let rowNumbers = "";
            res.bannedEntityWithErrors.forEach((value,index) => {              
              if ((index+1) < res.bannedEntityWithErrors.length) {
                rowNumbers += value.rowNumber.toString() + ", ";
              } else if ((index+1) == res.bannedEntityWithErrors.length) {
                rowNumbers += value.rowNumber.toString();
              }

            });
            this.ErrorMessage += "Missing mandatory data in Row ";
            this.ErrorMessage += res.bannedEntityWithErrors.length == 1 ? "No : " : "Nos : ";
            this.ErrorMessage += rowNumbers;
            this.toastr.success("Uploaded remaining data successfully");
          } else {
            this.toastr.success("Uploaded successfully");
          }
          this.closeDialog();
        }
        this.spinnerService.hideSpinner();
      }),
        (e => {
          this.spinnerService.hideSpinner();
          this.toastr.error(e);
        });
    }
    else
      this.validateAllFormFields(this.form);
  }
  reset() {
    this.fileUploader.nativeElement.value = "";
    this.form?.controls["fileName"].setValue("");
  }
  closeDialog() {
    this.dialogRef.close({ data: { "entitylist": this.data.entityList, "errorMsg": this.ErrorMessage }});    
  }
}


