import { Component, Inject, OnInit } from '@angular/core';
import { UserManagementService } from "../user-management/services/user-management.service";
import { SpinnerService } from '../shared/spinner/spinner.service';
import { ActivatedRoute, Router } from '@angular/router';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import { RoleControls } from './models/role-controls';
import { ToastrService } from 'ngx-toastr';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from '../shared/common-services/common.service';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';

export class UserAttribute {
  emailAddress: string;
  firstName: string;
  lastName: string;
  fullName: string;
  alias: string;
  isActive: boolean;
}
export class UserRole {
  roleName: string;
  allowedSecurityGroups: Array<SecurityGroup>;
  updatedBy: string;
  createdBy: string;
  updatedOn: Date;
  createdOn: Date;
  isActive: boolean;
}
export class SecurityGroup {
  groupName: string;
  groupDisplayName: string;
  groupType: string;
  securityActions: Array<SecurityAction>;
}
export class SecurityAction {
  actionName: string;
}

export class User {
  userAttributes: UserAttribute;
  roles: Array<UserRole>;
}
 
@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})

export class CreateUserComponent implements OnInit {
  isNoUserFound: boolean;
  isAdminAcordianOpen: boolean;
  isContributeAcordianOpen: boolean;
  isViewOnlyAcordianOpen: boolean;
  isShowmoreButton: boolean = true;
  roles: Array<any> = [];
  controls: Array<any> ;
  rolesNcontrols: RoleControls= new RoleControls();   
  adminControls: Array<any> = [];
  dataownerControls: Array<any> = [];
  viewonlyControls: Array<any> = [];
  selectedRoleValue: Array<any> = [];
  users: Array<any> = [];
  isUserSelected: boolean = true;
  selectedUserDisplayName: string = '';
  selectedUserEmailAddress: string = '';
  user: User;
  createUser: string = '';
  selectedUseralias: string = '';
  viewonlyControlAttributes: Array<any> = [];
  dataownerControlAttributes: Array<any> = [];
  adminControlAttributes: Array<any> = [];
  roleSelectedforUser: Array<any> = [];
  userAttribute: UserAttribute;
  pageNumber: number = 1;
  headerControlText = '';
  headerText: string = 'Create User';
  buttonText: string = 'Create User';
  isMatInputDisabled: boolean = false;
  userList: any;
  isAccordianselected: Array<any>;
  errorCount: number = 0;
  loadUser: any;
  paginatorLink: string;
  searchstring: any = '';
  tempUserList: Array<any> = [];
  loggedInUser: any;
  constructor(public dialogRef: MatDialogRef<CreateUserComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private userManagementService: UserManagementService, private spinnerService: SpinnerService, private route: ActivatedRoute, private router: Router, private toastr: ToastrService, private commonService: CommonService) {
   this.controls = JSON.parse(sessionStorage.getItem("Controls"));
    this.roles = JSON.parse(sessionStorage.getItem("Roles"));
    this.rolesNcontrols.controls = JSON.parse(sessionStorage.getItem("controlsToDisplay"));
    this.rolesNcontrols.roles = JSON.parse(sessionStorage.getItem("Roles"));
    this.loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));  
  }

  ngOnInit(): void {

    if (this.data.alias != '') {
      this.buttonText = 'Save';
      this.headerText = 'Edit User';
      this.spinnerService.showSpinner();
      this.getUserByAlias(this.data.alias);
         
      this.isMatInputDisabled = true;
    }
    else {
      this.buttonText = 'Create User';
      this.headerText = 'Create User';
      this.isMatInputDisabled = false;
    }

    this.viewonlyControlAttributes = [];
    this.dataownerControlAttributes = [];
    this.adminControlAttributes = [];
    this.roleSelectedforUser = [];
    this.userAttribute = null;
   
  }

  closeDialog() {
    this.dialogRef.close({ data: [this.data.userList, this.data.originalList] });
  }

  selectedAdminControls(role: any, e: any) {
    const index = this.selectedRoleValue.indexOf('Administrator', 0);
    if (index > -1) {
    }
  }

  selectedDataownerControls(role: any, e: any) {
    if (e.target.checked) {
      this.dataownerControls.push(role);
    } else {
      const index = this.dataownerControls.indexOf(role, 0);
      if (index > -1) {
        this.dataownerControls.splice(index, 1);
      }
    }
  }

  selectedviewonlyControls(role: any, e: any) {
    if (e.target.checked) {
      this.viewonlyControls.push(role);
    } else {
      const index = this.viewonlyControls.indexOf(role, 0);
      if (index > -1) {
        this.viewonlyControls.splice(index, 1);
      }
    }
  }

  adminRoleSelected() {
    const index = this.selectedRoleValue.indexOf('Administrator', 0);
    if (index > -1) {
      this.controls.forEach((element) => {
        this.adminControls.push(element);
      });
    }
    else {
      this.adminControls = [];
    }
  }

  selectedRole(role: any, e: any) {
    if (e.target.checked) {
      this.selectedRoleValue.push(role);
      this.headerControlText = 'Controls for ' + role;
      if (role == 'Administrator') {
        this.adminRoleSelected();
      }
    } else {
      const index = this.selectedRoleValue.indexOf(role, 0);
      if (index > -1) {
        this.selectedRoleValue.splice(index, 1);
      }
      this.adminRoleSelected();
      if (role == 'Contributor') {
        this.dataownerControls = [];
      } else if (role == 'View Only') {
        this.viewonlyControls = [];
      }
      this.headerControlText = '';
    }
  }

  selectedControls(role: string, control: string, event: any) {
    if (role == 'Administrator') {
      this.selectedAdminControls(control, event);
    } else if (role == 'Contributor') {
      this.selectedDataownerControls(control, event);
    } else if (role == 'View Only') {
      this.selectedviewonlyControls(control, event);
    }
  }

  //getRoles() {
  //  this.spinnerService.showSpinner();
  //  this.userManagementService.getRoles().subscribe((result: any) => {
  //    if (result != null) {
  //      result.forEach((element: {
  //        roleName: any;
  //      }) => {
  //        this.roles.push(element.roleName);
  //        this.rolesNcontrols.roles.push(element.roleName);
  //      });
  //    }
  //    this.spinnerService.hideSpinner();
  //  });
  //}

  getControls() {
    this.spinnerService.showSpinner();
    this.userManagementService.getControls().subscribe((result: any) => {
      if (result != null) {
        this.controls = [];
        result.forEach((element: any) => {         
          this.controls.push(element.controlName);
          this.rolesNcontrols.controls.push({ controlName: element.controlName, controlDisplayName: element.controlDisplayName });
        });
      }
      this.spinnerService.hideSpinner();
    }, error => {
        this.toastr.error = error;
        this.spinnerService.hideSpinner();
        }
    )
  }

  isShowmore() {
    this.pageNumber = this.pageNumber + 1;
    this.isShowmoreButton = true;
    this.spinnerService.showSpinner();
    this.commonService.getUserByTerm(10, this.searchstring, this.paginatorLink).subscribe((result: any) => {
      if (result != undefined || result != null) {
        this.loadUser = result;
        if (this.loadUser != undefined && this.loadUser.value.length < 10) {
          this.isShowmoreButton = false;
        }
        this.loadUser.value.forEach((element: any) => {
          this.tempUserList.push({ userName: element.displayName, emailAddress: element.mail, alias: element.mail });
        });
      }
      this.paginatorLink = this.loadUser["@odata.nextLink"];
      this.users = this.tempUserList;
      this.spinnerService.hideSpinner();
      // result.loadUser = null;
    });
  }

  onInputChanged(event: any) {
    this.isShowmoreButton = true;
    this.users = [];
    this.isUserSelected = false;
    if ((event.target as HTMLInputElement).value.length >= 4) {
      this.users = [];
      this.spinnerService.showSpinner();
      setTimeout(() => {
        this.commonService.getUserByTerm(10, (event.target as HTMLInputElement).value, null).subscribe((result: any) => {
          if (result != undefined || result != null) {
            this.userList = result;
            if ((event.target as HTMLInputElement).value != this.searchstring) {
              this.tempUserList = [];
              this.userList.value.forEach((element: any) => {
                if (element != null) {
                  if (!(element.officeLocation! = null)) {
                    if ((element.userPrincipalName.toLowerCase().indexOf('@deloitte.com') > -1) &&( element.userPrincipalName.toLowerCase().indexOf('usa-') !=0)) {
                      this.tempUserList.push({ userName: element.displayName, emailAddress: element.userPrincipalName != null ? element.userPrincipalName.toLowerCase() : null, alias: element.userPrincipalName != null ? element.userPrincipalName.toLowerCase() : null });
                    }
                  }
                }
              });
              this.isNoUserFound = false;
            }
            this.paginatorLink = this.userList["@odata.nextLink"];
          }
          if (this.userList != undefined && this.userList.value.length < 10) {
            this.isShowmoreButton = false;
          }
          if (this.userList != undefined && this.userList.value.length == 0) {
            this.isNoUserFound = true;
          }
          this.searchstring = (event.target as HTMLInputElement).value;
          this.users = this.tempUserList;
          this.spinnerService.hideSpinner();

        });
      }, 300);

    }
    else if ((event.target as HTMLInputElement).value.length == 0) {
      this.users = [];
      this.spinnerService.hideSpinner();
    }
    else {
      this.users = [];
      this.spinnerService.hideSpinner();
    }
  }

  selectedUser(partner: any, index: any) {
    this.selectedUserDisplayName = this.users[index].userName;
    this.selectedUserEmailAddress = this.users[index].emailAddress;
    this.selectedUseralias = this.users[index].alias;
    this.isUserSelected = true;
  }
 
  addUser() {
    var roleName: string = '';
    if (this.validation() == 0) {

      this.userAttribute = {
        emailAddress: this.selectedUserEmailAddress,
        firstName: (this.selectedUserDisplayName.split(',')[1] != undefined || this.selectedUserDisplayName.split(',')[1] != null) ? this.selectedUserDisplayName.split(',')[1].trim() : null,
        lastName: (this.selectedUserDisplayName.split(',')[0] != undefined || this.selectedUserDisplayName.split(',')[0] != null) ? this.selectedUserDisplayName.split(',')[0].trim() : null,
        fullName: this.selectedUserDisplayName,
        alias: this.selectedUseralias,
        isActive: true
      };

      if (this.adminControls != null && this.adminControls.length > 0) {
        this.adminControls.forEach((element: any) => {
          this.adminControlAttributes.push({ groupName: element });
        });
      }

      if (this.dataownerControls != null && this.dataownerControls.length > 0) {
        this.dataownerControls.forEach((element: any) => {
          this.dataownerControlAttributes.push({ groupName: element });
        });
      }

      if (this.viewonlyControls != null && this.viewonlyControls.length > 0) {
        this.viewonlyControls.forEach((element: any) => {
          this.viewonlyControlAttributes.push({ groupName: element });
        });
      }

      if (this.selectedRoleValue != null && this.selectedRoleValue.length > 0) {
        this.roleSelectedforUser =new Array<UserRole>();
        this.roles.forEach((element) => {         
          this.roleSelectedforUser.push({
            roleName: element,
            isActive: this.selectedRoleValue.indexOf(element) > -1 ? true : false,
            updatedBy: this.loggedInUser.name,
            updatedOn: new Date(),
            allowedSecurityGroups: element != 'Administrator' ? (element == 'Contributor' ? this.dataownerControlAttributes : this.viewonlyControlAttributes) : this.adminControlAttributes,
            securityActions: null,
            roleAttributes: null
          });         
        });
      }

      this.roleSelectedforUser.forEach((element) => {
        if (element.allowedSecurityGroups.length != 0)
          roleName = roleName + ', ' + element.roleName;
      });
      roleName = roleName.substring(2);
      this.user = {
        userAttributes: this.userAttribute,
        roles: this.roleSelectedforUser
      }

      this.spinnerService.showSpinner();
      this.userManagementService.addUser(this.user).subscribe(
        userAlias => {
          var index = this.data.userList.findIndex(a => a.alias == this.selectedUserEmailAddress);          
          if (index > -1) {
            this.data.userList[index] = { userName: this.selectedUserDisplayName, role: roleName, updatedBy: this.loggedInUser.name, updatedOn: new Date(), alias: this.selectedUserEmailAddress }
          }
          else {
            this.data.userList.push({ userName: this.selectedUserDisplayName, role: roleName, updatedBy: this.loggedInUser.name, updatedOn: new Date(), alias: this.selectedUserEmailAddress })
            this.data.originalList.push(this.user);
          }
          if (this.buttonText == 'Save') {
            this.toastr.success('User Saved Successfully');            
          }
          else {
            this.toastr.success('User Created Successfully');           
            var queueNotification = { "notificationTemplateId": "5", "controlName": "", "createdBy": this.loggedInUser.name, "user": this.user.userAttributes.alias, "userName": this.user.userAttributes.firstName};
            this.userManagementService.addQueueNotification(queueNotification).subscribe(msg => {
            });
          }
          this.dialogRef.close({ data: [this.data.userList, this.data.originalList] });
          this.spinnerService.hideSpinner();
        });
    }
  }
  private getServerErrorMessage(error: HttpErrorResponse): string {
    switch (error.status) {
      case 404: {
        return `Not Found: ${error.message}`;
      }
      case 403: {
        return `Access Denied: ${error.message}`;
      }
      case 500: {
        return `Internal Server Error: ${error.message}`;
      }
      default: {
        return `Unknown Server Error: ${error.message}`;
      }

    }
  }

  errorMsg: string;
  updateUser() {
    this.spinnerService.showSpinner();
    this.userManagementService.updateUser("").pipe(catchError(error => {     
      if (error.error instanceof ErrorEvent) {
        this.errorMsg = `Error: ${error.error.message}`;
      }
      else {
        this.errorMsg = this.getServerErrorMessage(error);
      }
      this.spinnerService.hideSpinner();
      this.toastr.error( this.errorMsg);
      return throwError(this.errorMsg);     
      })
    );
  }
 
  validation(): number {
    this.errorCount = 0;

    if (!this.selectedUserEmailAddress) {
      this.toastr.error('Please select the User');
      this.errorCount = 1;
    }
    else if ((this.data.userList.filter(item => item.alias == this.selectedUseralias).length > 0) && this.buttonText != 'Save') {
      this.toastr.error("User Already Exists. Please Edit the Existing user");
      this.errorCount = 1;
    }
    else if (this.selectedRoleValue.length == 0) {
      this.toastr.error("Please select Role for User");
      this.errorCount = 1;
    }
    else if (!(((this.selectedRoleValue.indexOf('View Only') > -1) && (this.viewonlyControls.length > 0)) || this.selectedRoleValue.indexOf('View Only') <= -1)) {
      this.toastr.error("Please select control for View Only");
      this.errorCount = 1;
    }
    else if (!(((this.selectedRoleValue.indexOf('Contributor') > -1) && (this.dataownerControls.length > 0)) || this.selectedRoleValue.indexOf('Contributor') <= -1)) {
       this.toastr.error("Please select control for Contributor");
       this.errorCount = 1;
    }
    return this.errorCount;
  }

  getUserByAlias(alias: string) {

   // this.spinnerService.showSpinner();
    //setTimeout(() => {
    this.userManagementService.getUserByAlias(alias).subscribe((result: any) => {

      if (result != null) {

        this.user = result;
        console.log("get user by Alis:"+this.user);
        this.selectedUserEmailAddress = result.userAttributes.emailAddress;
        this.selectedUserDisplayName = result.userAttributes.fullName;
        var roles = result.roles;
        this.selectedUseralias = result.userAttributes.alias;
        roles.forEach((element: any) => {
          var roleName = element.roleName;
          if (element.allowedSecurityGroups.length > 0) {
            this.selectedRoleValue.push(element.roleName);
          }
          if (roleName == 'Administrator') {
            this.isAdminAcordianOpen = false;
            this.adminRoleSelected();
          }
          else if (roleName == 'Contributor') {
            this.isContributeAcordianOpen = false;
            if (element.allowedSecurityGroups) {
              element.allowedSecurityGroups.forEach((element: any) => {
                if (element.groupName != 'general')
                this.dataownerControls.push(element.groupName);
              });
            }
          }
          else if (roleName == 'View Only') {
            console.log("Role :" + roleName);            
            this.isViewOnlyAcordianOpen = false;
            if (element.allowedSecurityGroups) {
              element.allowedSecurityGroups.forEach((element: any) => {
                if (element.groupName!='general')
                  this.viewonlyControls.push(element.groupName);
                console.log("Group :" + element.groupName)
              });
            }
          }
        });
      }
      this.spinnerService.hideSpinner();
    });
    //}, 300);
  }

  goToUserManagement() {
    //this.router.navigate(['/users'], { queryParamsHandling: 'preserve' });
  }

  isAccordianOpenClick(role: string) {
    if (role == 'Administrator') {
      this.isAdminAcordianOpen = false;
    } else if (role == 'Contributor') {
      this.isContributeAcordianOpen = false;
    } else if (role == 'View Only') {
      this.isViewOnlyAcordianOpen = false;
    }
  }

  isAccordianCloseClick(role: string) {
    if (role == 'Administrator') {
      this.isAdminAcordianOpen = true;
    } else if (role == 'Contributor') {
      this.isContributeAcordianOpen = true;
    } else if (role == 'View Only') {
      this.isViewOnlyAcordianOpen = true;
    }
  }
 
  isAccordianClick(role: string): boolean {
    var isChecked: boolean;
    if (role == 'Administrator') {
      isChecked = (this.selectedRoleValue.indexOf(role) > -1 && this.isAdminAcordianOpen);
    } else if (role == 'Contributor') {
      isChecked = (this.selectedRoleValue.indexOf(role) > -1 && this.isContributeAcordianOpen);
    } else if (role == 'View Only') {
      isChecked = (this.selectedRoleValue.indexOf(role) > -1 && this.isViewOnlyAcordianOpen);;
    }
    return isChecked
  }

  isControlChecked(role: string, control: string): boolean {
    var isChecked: boolean = false;
    if (role == 'Administrator') {
      isChecked= this.adminControls.indexOf(control) > -1;
    } else if (role == 'Contributor') {
      isChecked= this.dataownerControls.indexOf(control) > -1;
    } else if (role == 'View Only') {
      isChecked = this.viewonlyControls.indexOf(control) > -1;
    }
    return isChecked;
  }

  isAdmin(role: string): boolean {
    var isChecked: boolean = false;
    if (role == 'Administrator') {
      isChecked =true;
    }
    return isChecked;
  }

  hasAccess(groupName: string, securityAction: string): boolean {
    return true;
  }
}


