import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, InjectionToken } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { ReactiveFormsModule, FormsModule  } from '@angular/forms';
import { MsalModule, MsalInterceptor, MSAL_CONFIG, MSAL_CONFIG_ANGULAR, MsalService, MsalAngularConfiguration, MsalGuard, BroadcastService } from '@azure/msal-angular';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CreateUserComponent } from './create-user/create-user.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { DynamicFormComponent } from './dynamic-form-components/dynamic-form.component';
import { DynamicFormQuestionComponent } from './dynamic-form-components/dynamic-form-question.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ManageAttestationComponent } from './manage-attestation/manage-attestation.component';
import { SpinnerComponent } from './shared/spinner/spinner.component';
import { SpinnerService } from './shared/spinner/spinner.service';
import { GridComponent } from './dynamic-form-components/grid.component';//pagination
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { FieldErrorDisplayComponent } from './dynamic-form-components/field-error-display.component';
import { ToastrModule } from 'ngx-toastr';
import { MatSortModule } from '@angular/material/sort';
import { MatDialogModule } from '@angular/material/dialog';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { DashboardDetailsComponent } from './dashboard-details/dashboard-details.component';
import { Configuration } from 'msal';
import { ConfigService } from './shared/common-services/config.service';
import { SiteheaderComponent } from './shared/site-header/site-header.component';
import { SitefooterComponent } from './shared/site-footer/site-footer.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { DashboardService } from './dashboard/services/dashboard.service';
import { CommonService } from './shared/common-services/common.service';
import { MsalApplicationModule } from './msal-application.module';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { LoginComponent } from './login/login.component';
import { UserRoleManagementComponent } from './user-role-management/user-role-management.component';
import { CreateRoleComponent } from './create-role/create-role.component';
import { ManageEntityComponent } from './manage-entity/manage-entity.component';
import { ManageQuestionnaireComponent } from './manage-questionnaire/manage-questionnaire.component';
import { AddBannedEntityComponent } from './add-banned-entity/add-banned-entity.component';
import { ArraySortPipe } from './manage-questionnaire/services/questionnaire.service';
import { UploadBannedEntityComponent } from './manage-entity/upload-banned-entity.component';
import { ReprocessComponent } from './reprocess/reprocess.component';
import { EmailtemplateComponent } from './emailtemplate/emailtemplate.component';
import { CKEditorModule } from 'ckeditor4-angular';
import {EmailtemplateService } from './emailtemplate/services/emailtemplate.service';
import { NotificationDashboardComponent } from './notification-dashboard/notification-dashboard.component';
import { ManageControlmatadataComponent } from './manage-controlmatadata/manage-controlmatadata.component';
import { WarningcomponentComponent } from './warningcomponent/warningcomponent.component';



@NgModule({
  declarations: [
    LoginComponent,
    UnauthorizedComponent,
    AppComponent,
    SiteheaderComponent,
    SitefooterComponent,
    HomeComponent,
    DashboardComponent,
    UserManagementComponent,
    CreateUserComponent,
    ManageAttestationComponent,
    SpinnerComponent,
    DynamicFormComponent,
    DynamicFormQuestionComponent,
    FieldErrorDisplayComponent,
    GridComponent,
    DashboardDetailsComponent,
    FileUploadComponent,
    UserRoleManagementComponent,
    CreateRoleComponent,
    ManageEntityComponent,
    AddBannedEntityComponent,
    UploadBannedEntityComponent,
    ManageQuestionnaireComponent,
    ArraySortPipe,
    ReprocessComponent,
    EmailtemplateComponent,
    NotificationDashboardComponent,
    ManageControlmatadataComponent,
    WarningcomponentComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatAutocompleteModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      timeOut: 990000,
      closeButton: true,
      preventDuplicates: true
    }),
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatDialogModule,
    MsalApplicationModule.forRoot('assets/config.json'),
    MatChipsModule,
    FormsModule,
    MatFormFieldModule,
    MatTooltipModule,
    CKEditorModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule
  ],
  exports: [
  ],
 

  providers: [SpinnerService,  DashboardService, CommonService, ConfigService, MsalApplicationModule, BroadcastService, MsalGuard,EmailtemplateService,
    //{ provide: APP_INITIALIZER, useFactory: (config: AppConfig) => () => config.load(), deps: [AppConfig], multi: true },
    //{ provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
    //{
    //  provide: HTTP_INTERCEPTORS,
    //  useClass: SpinnerInterceptor,
    //  multi: true,
    //},
    //{
    //  provide: HTTP_INTERCEPTORS,
    //  useClass: HttpErrorInterceptor,
    //  multi: true
    //},
  ],
  bootstrap: [AppComponent]

})
export class AppModule {

}







