import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService, BroadcastService } from '@azure/msal-angular';
import { InteractionRequiredAuthError } from 'msal';
import { Subscription } from 'rxjs';
import { CommonService } from '../shared/common-services/common.service';
import { ConfigService } from '../shared/common-services/config.service';
import { SpinnerService } from '../shared/spinner/spinner.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})


export class LoginComponent implements OnInit {

  public isAuthenticated = false;
  public isLoggedIn = false;
  previousUrl: string = null;
  showComponent: boolean = false;
  currentUrl: string = null;
  script: any;

  constructor(private msalService: MsalService, private broadcastService: BroadcastService, private spinnerService: SpinnerService,  private router: Router,
    private commonService: CommonService, protected config: ConfigService, private _renderer: Renderer2) {
    this.commonService.loggedInUser = this.msalService.getAccount();
    this.spinnerService.showSpinner();
    localStorage.setItem("loggedInUser", JSON.stringify(this.msalService.getAccount()));
    if (!this.commonService.loggedInUser) {
      this.spinnerService.hideSpinner();
      this.login();
    }
    else {
      this.isAuthenticated = true;
      var loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
      this.commonService.getAllSecurityAction(loggedInUser.userName.toLowerCase()).subscribe(
        (result: any) => {
          localStorage.setItem("SecurityAction", JSON.stringify(result));
          // this.router.navigateByUrl("/homecomponent")
          this.spinnerService.hideSpinner();
          this.router.navigate(['/home']);
          this.showComponent = true;
        }, 
        (error: any) => {
           this.spinnerService.hideSpinner();
          if (error instanceof InteractionRequiredAuthError) {
            this.login();
          }
        }
      ); 
           
    }
  }
  ngOnInit() {

    this.checkoutAccount();
    this.broadcastService.subscribe('msal:loginSuccess', () => {
      console.log("from maslogin sucess broadcast");
      var loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
      this.commonService.getAllSecurityAction(loggedInUser.userName.toLowerCase()).subscribe(
        (result: any) => {
          localStorage.setItem("SecurityAction", JSON.stringify(result));
          if (localStorage.getItem('foo')) {
            localStorage.removeItem('foo');
          }
          // this.router.navigateByUrl("/homecomponent")
           this.router.navigate(['/home']);
          this.showComponent = true;
        });
      this.commonService.getRoles();
      this.commonService.getControls();
      this.isAuthenticated = true;
    });

    this.msalService.handleRedirectCallback((authError, response) => {
      if (authError) {
        console.error('Redirect Error: ', authError.errorMessage);
        return;
      }
      //console.log('Redirect Success: ', response);
    });
  }
  checkoutAccount() {
    this.isLoggedIn = !!this.msalService.getAccount();
  }

  login() {
    this.msalService.loginRedirect();
  }

  logout() {
    this.msalService.logout();
  }
  //ngOnDestroy() {
  //  this.broadcastService.getMSALSubject().next(1);

  //  if (this.subscription) {
  //    this.subscription.unsubscribe();
  //  }
  //  this.msalService.logout();
  //  localStorage.clear();
  //}
  createOneTrustScriptTag() {
    this.script = this._renderer.createElement('script');
    this.script.src = this.config.getSettings('OneTrustSrc');
    this._renderer.setAttribute(this.script, "data-domain-script", this.config.getSettings('AdminOneTrustDataDomainScript'));
    var footer: any;
    footer = document.getElementById("footer");    
    if (footer != null || footer != undefined)
      footer.appendChild(this.script);
  }
}
