<div [formGroup]="form">
  <div class="sub_questions">
    <div class="question_row" style="margin-top:0px;" [ngSwitch]="question.questionType" [ngClass]="{'container800' : question.questionType != questionType.grid}">
      <div class="full_width dot" *ngIf="question.questionText && question.questionText.length>0">
        <h2>
          {{question.questionText}}
        </h2>
      </div>
      <div class="full_width margintop-20" *ngSwitchCase="questionType.radio">
        <div *ngFor="let opt of question.options">
          <label class="container-rad">
            <span class="check_txt"></span>
            <input type="radio"
                   [name]="question.questionId"
                   [formControlName]="question.questionId"
                   [value]="opt.id" (change)="triggerQuestion(question)">
            <span class="checkmark"></span>
          </label>
          <span class="font14-black txt_check">{{opt.name}}</span>
        </div>
      </div>
      <div class="full_width margintop-20" *ngSwitchCase="questionType.dropdown">
        <div class="form-group" style="margin-bottom:20px">
          <select class="form-control form_txt" [name]="question.questionId" [formControlName]="question.questionId">
            <option value="" disabled>--Select--</option>
            <option *ngFor="let opt of question.options" [value]="opt.id">{{opt.name}}</option>
          </select>
        </div>
      </div>
      <div class="full_width margintop-20" *ngSwitchCase="questionType.checkbox" [formGroupName]="question.questionId">
        <div *ngFor="let opt of question.options">
          <label class="container-cr">
            <span class="check_txt"></span>
            <input type="checkbox"
                   [name]="question.questionId"
                   [formControlName]="question.questionId + '-' + opt.id"
                   [value]="opt.id">
            <span class="checkmark"></span>
          </label>
          <span [ngClass]="{'bold' : isDisClaimerQuestion(question)==true}" class="font14-black txt_check">{{opt.name}}</span>
        </div>
      </div>
      <div class="full_width margintop-20" *ngSwitchCase="questionType.text">
        <div class="form-group">
          <input [formControlName]="question.questionId"
                 [id]="question.questionId" [type]="text" class="form-control form_txt">

        </div>
      </div>
      <div class="full_width margintop-20" *ngSwitchCase="questionType.textarea">
        <div class="form-group">
          <textarea rows="4" cols="100" class="txt_area form-control" *ngSwitchCase="questionType.textarea" [formControlName]="question.questionId"
                    [id]="question.questionId"></textarea>

        </div>
      </div>
      <div class="full_width margintop-20" *ngSwitchCase="questionType.file">
        <div class="form-group">
         <!-- <input type='file' id='file' name="file" placeholder="Upload an Image" required  value={this.state.image}  onChange={this.uploadFile} />-->
          <input type="file" id="fileUpload" [ngClass]="{'pull-left' : question.fileName && question.fileName.length>0}" #fileUploader  (change)="handleUpload($event)" />
          <!--<span style="cursor:pointer;float:left" (click)="fileUploader.value=null; deleteFile(question.questionId)" *ngIf="question.fileName && question.fileName.length>0">X</span>-->
          <a *ngIf="question.attachment && question.attachment.length>0" style="cursor:pointer" (click)="downloadAttachment(question)"> {{question.attachment}}</a>
          <!--<span style="cursor:pointer;padding-right:10px;" (click)="fileUploader.value=null; deleteFile(question.questionId)"
          *ngIf="question.attachment && question.attachment.length>0">X</span>-->
        </div>
      </div>
      <div class="full_width margintop-20" *ngSwitchCase="questionType.grid">
        <div class="table-responsive assets_grid">
          <app-grid [question]="question" (addQuestion)="addQuestion($event)" [form]="form" [masterData]="masterData"></app-grid>
        </div>
      </div>
      <app-field-error-display *ngIf="question.questionType!=questionType.grid" [displayError]="isFieldValid(question.questionId)" errorMsg="{{errorMessage}}">
      </app-field-error-display>
    </div>
  </div>
</div>
