<div class="popup_container">
  <div class="full_width paddingbottom-20px">
    <h1>Upload Subsidiary Entity File</h1>
    <div mat-button class="right close_btn" (click)="closeDialog()">X</div>
  </div>

  <div class="full_width paddingbottom-20px" style="padding-bottom: 30px;">
    <div class="full_width" style="padding-bottom:40px;">
      <div class="full_width" style="padding-bottom: 10px;">
        <div class="left">
          <span class="dds-icon_sm dds-icon_info info_ico"></span>
          Upload file should have column headers "Subsidiary Name , Parent Name and Tag"
        </div>
      </div>
      </div>
    <form (ngSubmit)="onSubmit()" [formGroup]="form">
      <div class="row margintop-20">
        <div class="col-lg-12">
          <!--<label>Please upload the Banned Entity file</label>-->
          <div class="custom-file mb-3">
            <input type="file" formControlName="file" #fileUploader class="custom-file-input" (change)="handleUpload($event)" />
            <label class="custom-file-label" for="customFile">Choose file to upload</label>
          </div>
          <div>{{this.form.controls['fileName'].value}}</div>
          <span *ngIf="this.form.controls['file'].errors?.fileSizeValidator" class="validation-msg">
            Maximum allowed size is 5MB
          </span>
          <app-field-error-display [displayError]="isFieldValid('fileName')" errorMsg="Please upload excel">
          </app-field-error-display>
        </div>
      </div>
      <div class="left popup_bottom_btn margintop-20">
       
          <button style="margin-right:10px" type="submit" class="btn btn_primary btn_lg nav_btns_green">Submit</button>
          <button type="submit" class="btn btn_primary btn_lg nav_btns_green" (click)="closeDialog()">Cancel</button>
     
      </div>
    </form>
  </div>
</div>
