<div class="popup_container">
  <div class="full_width paddingbottom-20px">
    <h1 class="" style="color: #a94442;">{{headerText}}</h1>
    <div mat-button class="right close_btn" (click)="closeDialog(false)">X</div>
  </div>

  <div class="full_width paddingbottom-20px" style="padding-bottom: 30px;">
    <div class="form-group">
      <span for="warningmsg">{{warningmsg}}</span>
    </div>
  </div>

  <div class="left popup_bottom_btn">
    <button class="btn btn_primary btn_lg nav_btns_green" style="margin-right:10px" (click)="closeDialog(true)" mat-button>Ok</button>
    <button class="btn btn_primary btn_lg nav_btns_green" mat-button (click)="closeDialog(false)">Cancel</button>
  </div>

</div>
