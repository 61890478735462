<div class="container main_container">
  <div class="full_width">

    <div class="breadscrum_col">
      <ul>
        <li><a [routerLink]="'/home'"> My Dashboard</a></li>
        <li><span class="glyphicon glyphicon-menu-right cool_gray2"></span></li>
        <li>Manage Entity</li>
      </ul>
    </div>
  </div>

  <div class="full_width margintop-20 marginbottom-20">
    <div class="left entity_row">
      <form [formGroup]="form">
        <div class="left margin_right15">
          <div class="left">
            <label class="align_r margin_right15">Type</label>
          </div>
          <div class="left">
            <select formControlName="type" (change)="onParentChange()">
              <option value="" disabled>--Select--</option>
              <option value="Parent">Parent</option>
              <option value="Subsidiary">Subsidiary</option>
            </select>
          </div>
        </div>
        <div class="left" *ngIf="this.form.controls['type'].value=='Subsidiary'">
          <div class="left">
            <label class="align_r margin_right15">Parent</label>
          </div>
          <div class="left">
            <select (change)="getSubsidaries()" formControlName="parentEntity">
              <option value="" disabled>--Select--</option>
              <option *ngFor="let entity of parentEntities" value="{{entity.id}}">{{entity.name}}</option>
            </select>
          </div>
        </div>
      </form>
      <div class="modal fade" id="myModal" role="dialog">
        <div class="modal-dialog">

          <!-- Modal content-->
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal">&times;</button>

            </div>
            <div class="modal-body">
              <p>{{ErrMsg}}</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
            </div>
          </div>

        </div>
      </div>

    </div>
    <div class="right">
      <button id="idOpenModelButton" type="button" class="btn btn-info btn-lg" data-toggle="modal" data-target="#myModal" style="display:none;">Open Modal</button>
      <button type="button" style="color: #000 !important; margin-left: 20px; height: 40px;" class="btn btn_primary btns_download right" (click)="downloadBandEntity()" *ngIf="hasAccess('general','dashboard/report/download')"><b>Download Report</b></button>

      <button *ngIf="showuploadButton()" class="btn btn_primary btn_lg nav_btns_green right" (click)="uploadDialog()" color="primary" style="margin-left:20px">
        Upload Entity List
      </button>

      <button *ngIf="showAddButton()" class="btn btn_primary btn_lg nav_btns_green right" (click)="openDialog('')" color="primary">
        <span *ngIf="this.form.controls['type'].value=='Parent'">Add Parent Entity</span>
        <span *ngIf="this.form.controls['type'].value=='Subsidiary'">Add Subsidiary</span>
      </button>


    </div>
    
  </div>

  <div class="mat-elevation-z8 full_width margintop-10 grid_list" *ngIf="this.form.controls['type'].value">

    <div class="table-responsive">
      <table mat-table [dataSource]="UsersDataSource" #mytable class="my-table mat-elevation-z8" matSort>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>{{selectedEntityType}} Entity Name </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>


        <ng-container matColumnDef="tags">

          <th mat-header-cell *matHeaderCellDef>Tags</th>



          <td mat-cell *matCellDef="let element">
            <div class="tootltip_txt">
              <span mat-raised-button
                    matTooltip=" {{element.tags}}"
                    aria-label="Button that displays a tooltip when focused or hovered over">
                {{element.tags}}
              </span>
            </div>

          </td>
        </ng-container>

        <ng-container matColumnDef="updatedOn">
          <th mat-header-cell *matHeaderCellDef>Updated On</th>


          <td mat-cell *matCellDef="let element"> {{element.updatedOn|date:"MM-dd-yyyy"}} </td>
        </ng-container>

        <ng-container matColumnDef="updatedBy">
          <th mat-header-cell *matHeaderCellDef>Updated By</th>
          <td mat-cell *matCellDef="let element"> {{element.updatedBy}} </td>
        </ng-container>


        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef> Action </th>
          <td mat-cell *matCellDef="let element" class="action-link">
            <a (click)="openDialog(element)">Edit</a> |
            <a (click)="deleteEntityById(element.id)">Delete</a>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

    </div>
  </div>

</div>







