import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';




@Component({
  selector: 'app-create-role',
  templateUrl: './create-role.component.html',
  styleUrls: ['./create-role.component.scss']
})
export class CreateRoleComponent implements OnInit {

  headerText: string = 'Create User';

  constructor(public dialogRef: MatDialogRef<CreateRoleComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.headerText = 'Create Role';
  }

  closeDialog() {
    this.dialogRef.close({ data: this.data.userList });
  }

}
