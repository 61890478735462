<div class="container main_container">
  <div class="header_second">

    <div class="full_width">
      <div class="breadscrum_col">
        <ul>
          <li><a [routerLink]="'/home'"> My Dashboard</a></li>
          <li><span class="glyphicon glyphicon-menu-right cool_gray2"></span></li>
          <li>User Role Permission</li>
        </ul>
      </div>
    </div>   

    <div class="full_width margintop-10 grid_list">
      <div class="table-responsive">
        <table mat-table [dataSource]="UsersDataSource" matSort>
       
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>
              <span>Actions<span class="dds-icon_sm dds-icon_sort-down sort-down_ico"  mat-sort-header></span></span>
             <!-- <input (keyup)="applyFilter($event)" placeholder="Search" class="form-control" (focus)="setupFilter('actions')" #actions />-->
            </th>
            <td mat-cell *matCellDef="let row" style="align-content:center">{{row.actions}} </td>
          </ng-container>
           
          <ng-container matColumnDef="Administrator">
            <th mat-header-cell *matHeaderCellDef>
              <span >Administrator<span class="dds-icon_sm dds-icon_sort-down sort-down_ico" mat-sort-header></span></span>
              <!--<input (keyup)="applyFilter($event)" placeholder="Search" class="form-control" (focus)="setupFilter('Administrator')" />-->
            </th>

            <td mat-cell *matCellDef="let row" >
              <input type="checkbox" [checked]="row.Administrator" (change)="selectionChanged(row.actions,'Administrator', $event)" />
            </td>
          </ng-container>
 
          <ng-container matColumnDef="Contributor">
            <th mat-header-cell *matHeaderCellDef>
              <span >Contributor<span class="dds-icon_sm dds-icon_sort-down sort-down_ico" mat-sort-header></span></span>
              <!--<input (keyup)="applyFilter($event)" placeholder="Search" class="form-control" (focus)="setupFilter('Contributor')" />-->
            </th>
            <td mat-cell *matCellDef="let row" >
              <input type="checkbox" [checked]="row.Contributor" (change)="selectionChanged(row.actions,'Contributor', $event)" />
            </td>
          </ng-container>
         
          <ng-container matColumnDef="ViewOnly">
            <th mat-header-cell *matHeaderCellDef>
              <span   style="text-align:center;">View Only<span class="dds-icon_sm dds-icon_sort-down sort-down_ico" mat-sort-header></span></span>
              <!--<input (keyup)="applyFilter($event)" placeholder="Search" class="form-control" (focus)="setupFilter('ViewOnly')" />-->
            </th>
            <td mat-cell *matCellDef="let row" >
              <input  type="checkbox" [checked]="row.ViewOnly" (change)="selectionChanged(row.actions,'View Only', $event)"/>             
            </td>
          </ng-container>                    

          <tr mat-header-row *matHeaderRowDef="definedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: definedColumns;"></tr>         
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No User Found</td>
          </tr>
        </table>
      </div>
      <div class="left popup_bottom_btn">
        <button class="btn btn_primary btn_lg nav_btns_green" style="margin-bottom:20px;margin-right:10px" (click)="saveSecurityActionByRole()" mat-button>Save</button>
       
        <button class="btn btn_primary btn_lg nav_btns_green" style="margin-bottom:20px;" (click)="cancelSecurityActionByRole()"mat-button>Cancel</button>
      </div>
    </div>
  </div>
</div>
