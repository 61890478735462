
<form (ngSubmit)="onSubmit()" [formGroup]="form">
  <div class="container main_container">
    <div class="header_second">
      <div class="full_width">
        <div class="breadscrum_col col-lg-8">
          <ul>
            <li><a [routerLink]="'/home'"> My Dashboard</a></li>
            <li><span class="glyphicon glyphicon-menu-right cool_gray2"></span></li>
            <li>Manage Attestation Form</li>
          </ul>
        </div>

      </div>

      <div class="full_width margintop-20">
        <h1>
          Manage Attestation Form
        </h1>

      </div>
      <div class="full_width">
        <div class="container800">
          <!--<div class="attestaion_row" *ngIf="isNewForm">
            <div class="form-group">
              <label for="usr">Control Name:</label>
              <input type="text" formControlName="controlName" class="form-control form_txt" />
              <app-field-error-display [displayError]="isFieldValid('controlName')" errorMsg="{{errorMessage}}">
              </app-field-error-display>
            </div>
            <div class="form-group">
              <label for="usr">Display Name:</label>
              <input type="text" formControlName="displayName" class="form-control form_txt" />
              <app-field-error-display [displayError]="isFieldValid('displayName')" errorMsg="{{errorMessage}}">
              </app-field-error-display>
            </div>
          </div>-->

          <div class="attestaion_row">
            <label for="usr">Select Control:</label>
            <div class="form-group">

              <select class="form-control form_txt" (change)="onControlChange($event)" formControlName="controlName" style="display:inline !important">
                <option value="" disabled>--Select--</option>
                <option *ngFor="let control of controls" value="{{control.name}}">{{control.name}}</option>
              </select>
              <div style="float:right">
                <button type="button" *ngIf="this.form.controls['controlName'].value" (click)="createNewQuestionnaire(false)" class="btn btn-success">Create New Form</button>
              </div>
              <app-field-error-display [displayError]="isFieldValid('controlName')" errorMsg="{{errorMessage}}">
              </app-field-error-display>

            </div>
            <label for="usr" *ngIf="this.form.controls['controlName'].value">Display Name:</label>
            <div class="form-group" *ngIf="this.form.controls['controlName'].value">

              <input type="text" formControlName="displayName" class="form-control form_txt" />
              <app-field-error-display [displayError]="isFieldValid('displayName')" errorMsg="{{errorMessage}}">
              </app-field-error-display>
            </div>
          </div>

          <div *ngIf="questionnaire && questionnaire.questions.length>0 || isNewForm">
            <div class="full_width">
              <div *ngFor="let question of questionnaire.questions| sort:'questionOrder';let i=index ">
                <div [ngSwitch]="question.questionType">
                  <div class="full_width">
                    <div class="manda">
                      <label class="container-cr">
                        <span class="check_txt"></span>
                        <input type="checkbox" [formControlName]="question.questionId+'_required'"><span class="checkmark"></span>
                      </label>
                      <span class="font14-black txt_check">This question is mandatory</span>
                    </div>
                  </div>
                  <div class="full_width margintop-10">
                    <div class="col-md-12 left_0 right_0" style="width:106%">
                      <div class="form-group" style="float:left;width:94%">
                        <label for="usr">Question or statement</label>
                        <span *ngIf="question.questionType==questionType.grid" style="font-weight:bold"> - Grid</span>
                        <textarea class="form-control form_txt pull-left" [formControlName]="question.questionId" style="width:100%" placeholder="write a block question or statement"></textarea>

                        <app-field-error-display [displayError]="isFieldValid(question.questionId)" errorMsg="{{errorMessage}}">
                        </app-field-error-display>
                      </div>
                      <span class="dds-icon_sm dds-icon_arrow-long-up updown-sort" [ngClass]="{'sort_disabled' : i==0}" (click)="sortQuestion(i,question,true)">  </span>
                      <span class="dds-icon_sm dds-icon_arrow-long-down updown-sort" [ngClass]="{'sort_disabled' : i==questionnaire.questions.length-1}" (click)="sortQuestion(i,question,false)">  </span>
                    </div>
                  </div>
                  <div class="full_width margintop-10" *ngIf="question.isNewQuestion">
                    <div class="col-md-4 left_0">
                      <div class="form-group">
                        <label for="usr">Select a component</label>
                        <select class="form-control form_txt" [formControlName]="question.questionId+'_type'" (change)="onQuestionTypeChange(question)">
                          <option value="" disabled>--Select--</option>
                          <option *ngFor="let questionType of questionTypes" value="{{questionType.Value}}">{{questionType.Text}}</option>
                        </select>
                        <app-field-error-display [displayError]="isFieldValid(question.questionId+'_type')" errorMsg="{{errorMessage}}">
                        </app-field-error-display>
                      </div>
                    </div>
                  </div>
                  <div class="full_width" *ngIf="question.questionType== questionType.radio || question.questionType == questionType.checkbox || question.questionType == questionType.dropdown">
                    <div *ngFor="let option of question.options">
                      <div class="margintop-10 full_width">
                        <div class="left">
                          <span class="circle_r" *ngSwitchCase="questionType.radio"></span>
                          <span class="rect_c" *ngSwitchCase="questionType.checkbox"></span>
                          <input type="text" class="form-control form_txt font_12" [formControlName]="question.questionId+'_'+ option.id" placeholder="write a block question or statement">
                        </div>
                        <div class="left">
                          <span (click)="removeOption(question, option)" class="dds-icon_sm dds-icon_delete delete_ques pull-right" style="margin-top:7px;"></span>
                        </div>
                        <app-field-error-display [displayError]="isFieldValid(question.questionId+'_'+ option.id)" errorMsg="{{errorMessage}}">
                        </app-field-error-display>
                      </div>
                    </div>
                    <div class="full_width margintop-10">
                      <button class="btn add_btnq" (click)="addOption(question)">Add option</button>
                    </div>
                  </div>
                  <span *ngIf="this.form.controls[question.questionId].errors?.questionOptionsValidator" class="validation-msg">
                    Please add at least one option
                  </span>
                </div>
                <!--<div class="full_width" *ngSwitchCase="questionType.checkbox">
                  <div *ngFor="let option of question.options">
                    <div class="margintop-10">
                      <span class="rect_c"></span>
                      <input type="text" class="form-control form_txt font_12" [formControlName]="question.questionId+'_'+ option.id" placeholder="write a block question or statement">
                      <app-field-error-display [displayError]="isFieldValid(question.questionId+'_'+ option.id)" errorMsg="{{errorMessage}}">
                      </app-field-error-display>
                    </div>
                  </div>
                  <div class="full_width margintop-10">
                    <button class="btn add_btnq" (click)="addOption(question)">Add option</button>
                  </div>
                </div>
                <div class="full_width" *ngSwitchCase="questionType.dropdown">
                  <div *ngFor="let option of question.options">
                    <div class="margintop-10">
                      <input type="text" class="form-control form_txt font_12" [formControlName]="question.questionId+'_'+ option.id" placeholder="write a block question or statement">
                      <app-field-error-display [displayError]="isFieldValid(question.questionId+'_'+ option.id)" errorMsg="{{errorMessage}}">
                      </app-field-error-display>
                    </div>
                  </div>
                  <div class="full_width margintop-10">
                    <button class="btn add_btnq" (click)="addOption(question)">Add option</button>
                  </div>
                </div>-->
                <div class="full_width margintop-10">
                  <div class="left">
                    <button class="btn remove_ques" (click)="removeQuestion(question)">Remove Question <span class="dds-icon_sm dds-icon_delete delete_ques"></span></button>
                  </div>
                </div>
                <div class="margintop-10 marginbottom-10 col-md-12">

                </div>
              </div>
            </div>
            <div class="full_width margintop-20 marginbottom-20">
              <div class="right">
                <button class="btn add_ques" (click)="addQuestion(question)">Add New Question <span class="dds-icon_sm dds-icon_check check_ar" style="color:#FFF;"></span></button>
              </div>
            </div>
            <div class="full_width margintop-30" style="margin-bottom:30px">
              <div class="attestation-btn">
                <div>
                  <button type="submit" class="btn btn-default" [routerLink]="'/home'">Cancel</button>
                </div>
                <div>
                  <button type="submit" class="btn btn-success">Publish</button>
                </div>
                <div>
                  <button type="button" (click)="saveAsDrat()" class="btn btn-success">Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
