import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-warningcomponent',
  templateUrl: './warningcomponent.component.html',
  styleUrls: ['./warningcomponent.component.scss']
})
export class WarningcomponentComponent implements OnInit {
  headerText: string = '';
  warningmsg: string = '';

  constructor(public dialogRef: MatDialogRef<WarningcomponentComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private renderer: Renderer2,) { }

  ngOnInit(): void {
    this.headerText = this.data.headerText;
    this.warningmsg = this.data.msg;
    this.renderer.addClass(document.body, 'mangecontrolpage');
  }
  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'mangecontrolpage');
  }

  closeDialog(status:boolean) {
    this.dialogRef.close(status);
  }
}
