import { Component, Inject, OnInit, ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ManageEntityService } from '../manage-entity/services/manage-entity.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Tags } from '../manage-entity/manage-entity.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';


export interface MangeEntity {

  name: string;
  tags: Array<Tags>
  updatedOn: string;
  updatedBy: string;
}




@Component({
  selector: 'app-banned-entity',
  templateUrl: './add-banned-entity.component.html',
  styleUrls: ['./add-banned-entity.component.scss']
})

export class AddBannedEntityComponent implements OnInit {
  visible = true;
  errorCount: number = 0;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  AddentityCtrl = new FormControl();
  filteredAddentitys: Observable<string[]>;
  tags: string[] = [];
  headerControlText = '';
  headerText: string = 'Create Entity';
  isEntityEditMode = false;
  buttonText: string = 'Create Entity';
  entityList: any;
  entity: any;
  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  entityId = '';
  entityName: string = '';
  entityType: string = '';

  spinnerService: any;
  isMatInputDisabled: boolean;
  UsersDataSource: any;
  formSubmitAttempt: boolean;

  constructor(private manageservice: ManageEntityService, private toastr: ToastrService, public dialogRef: MatDialogRef<AddBannedEntityComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    //if entity existis don't push
    if ((value || '').trim()) {

      let list = this.tags.filter((item) => item.toLowerCase() == (value.toLowerCase()));
      if (list.length == 0) {
        this.tags.push(value.trim());
      }
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.AddentityCtrl.setValue(null);
  }


  remove(tag: string): void {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }
  selected(event: MatAutocompleteSelectedEvent): void {

    this.tags.push(event.option.viewValue);

    this.tagInput.nativeElement.value = '';
    this.AddentityCtrl.setValue(null);
    console.log(this.tags);
  }




  ngOnInit(): void {

    if (this.data && this.data.entity != '') {
      this.buttonText = 'Save';
      this.headerText = 'Edit Entity';
      this.isMatInputDisabled = true;
      this.isEntityEditMode = true;
      this.entityName = this.data.entity.name;
      this.entityType = this.data.type;
      this.entityId = this.data.entity.id;
      this.tags = this.data.entity.tags;
    }
    else {
      this.buttonText = 'Create Entity';
      this.isEntityEditMode = false;
      this.headerText = 'Create Entity';
      this.entityType = this.data.type;
      this.isMatInputDisabled = false;
      this.entityId = '';
    }


  }


  closeDialog() {
    this.dialogRef.close({ data: this.data.entityList });
  }
  createGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
  Save() {
    let filterlist = this.data.entityList.filter((item) => item.name.toLowerCase() == this.entityName.toLowerCase());
    if (!this.isEntityEditMode && filterlist.length) {
      this.toastr.error("Entity name Already Exists");
    }
    else {
      if (this.entityName == '') {

        alert('Please provide Entity name.')
        return;
      }
      var loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
      var temptags = [];
      for (let i = 0; i <= this.tags.length; i++) {
        if (this.tags[i])
          temptags.push({ "value": this.tags[i] });
      }
      let type = "";
      if (this.data.type == "Parent")
        type = "ParentProhibitedEntity";
      else
        type = "ProhibitedEntities";
      let obj =
        [
          {
            id: this.entityId ? this.entityId : this.createGuid(),
            name: this.entityName,
            tags: temptags,
            type: type,
            updatedBy: loggedInUser.name,
            updatedOn: new Date(),
            parentTypeId: this.data.parentTypeId
          }
        ]

      this.manageservice.addData(obj).subscribe(result => {
        if (this.isEntityEditMode) {
          this.toastr.success(this.entityType +' Entity Saved Successfully');
        } else {
          
          this.toastr.success(this.entityType+' Entity Created Successfully');
        }

        this.dialogRef.close({ data: obj });
      });
    }
  }
}


