<div class="popup_container">
  <div class="full_width paddingbottom-20px">
    <h1>{{headerText}}</h1>
    <div mat-button class="right close_btn" (click)="closeDialog()">X</div>
  </div>


  <div class="full_width paddingbottom-20px" style="padding-bottom: 30px;">
    <div class="form-group">
      <div class="full_width marginbottom-20">
        <!--<label for="name">Entity Name </label>-->
        <div class="full_width">
          <!--<span class="dds-icon_sm dds-icon_search search_ico"></span>-->
          <div class="sectionUser user_box">

            <div class="full_width">
              <label for="name">{{entityType}} Entity Name </label>
              <div class="full_width relative_p">
                <!--<span class="dds-icon_sm dds-icon_search search_ico"></span>-->
                <div class="sectionUser user_box">
                  <!--<div class="invalid-feedback">Entity name already exits  </div>-->

                  <div>
                    <input matInput [(ngModel)]="entityName"
                           placeholder="Enter Entity Name"
                           autocomplete="on"
                           class="form-control textentityName" style="padding-left:5px;">
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>


      <div class="full_width">
        <label for="name">Tags</label>
        <div class="sectionUser user_box">

          <div class="tags_list">
          
            <mat-form-field class="example-chip-list">
             
              <mat-chip-list #chipList aria-label="entity selection">
                <mat-chip *ngFor="let tag of tags"
                          [selectable]="selectable"
                          [removable]="removable"
                          (removed)="remove(tag)">
                  {{tag}}
                  <mat-icon matChipRemove *ngIf="removable" style="position:relative; z-index:7000"></mat-icon>
                  <span class="close_tag">X</span>
                </mat-chip>
                <input style="padding: 4px 0 4px 5px;" placeholder="Enter Tag Name"
                      
                       #tagInput
                       [formControl]="AddentityCtrl"
                       [matChipInputFor]="chipList"
                       [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                       (matChipInputTokenEnd)="add($event)">
              </mat-chip-list>
            </mat-form-field>
          </div>
        </div>



        <div class="left popup_bottom_btn">
          <button class="btn btn_primary btn_lg nav_btns_green" (click)="Save()" style="margin-right:20px" mat-button>Save</button>
          <button class="btn btn_primary btn_lg nav_btns_green" mat-button (click)="closeDialog()">Cancel</button>
        </div>
      </div>
      <!--<div>
    <button class="btn btn_primary btn_lg nav_btns_green" (click)="Save()" mat-button>Save</button>
  </div>-->


    </div>
    </div>
</div>
