<div class="divSpinnerContent loading mainspinner" *ngIf="active">
  <div class='uil-default-css' style='transform:scale(1);'>
    <div style='top:88px;left:98px;width:4px;height:24px;background:#85be06;-webkit-transform:rotate(0deg) translate(0,-50px);transform:rotate(0deg) translate(0,-50px);border-radius:2px;position:absolute;'></div>
    <div style='top:88px;left:98px;width:4px;height:24px;background:#85be06;-webkit-transform:rotate(10deg) translate(0,-50px);transform:rotate(10deg) translate(0,-50px);border-radius:2px;position:absolute;'></div>
    <div style='top:88px;left:98px;width:4px;height:24px;background:#85be06;-webkit-transform:rotate(20deg) translate(0,-50px);transform:rotate(20deg) translate(0,-50px);border-radius:2px;position:absolute;'></div>
    <div style='top:88px;left:98px;width:4px;height:24px;background:#85be06;-webkit-transform:rotate(30deg) translate(0,-50px);transform:rotate(30deg) translate(0,-50px);border-radius:2px;position:absolute;'></div>
    <div style='top:88px;left:98px;width:4px;height:24px;background:#85be06;-webkit-transform:rotate(40deg) translate(0,-50px);transform:rotate(40deg) translate(0,-50px);border-radius:2px;position:absolute;'></div>
    <div style='top:88px;left:98px;width:4px;height:24px;background:#85be06;-webkit-transform:rotate(50deg) translate(0,-50px);transform:rotate(50deg) translate(0,-50px);border-radius:2px;position:absolute;'></div>
    <div style='top:88px;left:98px;width:4px;height:24px;background:#85be06;-webkit-transform:rotate(60deg) translate(0,-50px);transform:rotate(60deg) translate(0,-50px);border-radius:2px;position:absolute;'></div>
    <div style='top:88px;left:98px;width:4px;height:24px;background:#85be06;-webkit-transform:rotate(70deg) translate(0,-50px);transform:rotate(70deg) translate(0,-50px);border-radius:2px;position:absolute;'></div>
    <div style='top:88px;left:98px;width:4px;height:24px;background:#85be06;-webkit-transform:rotate(80deg) translate(0,-50px);transform:rotate(80deg) translate(0,-50px);border-radius:2px;position:absolute;'></div>
    <div style='top:88px;left:98px;width:4px;height:24px;background:#85be06;-webkit-transform:rotate(90deg) translate(0,-50px);transform:rotate(90deg) translate(0,-50px);border-radius:2px;position:absolute;'></div>
    <div style='top:88px;left:98px;width:4px;height:24px;background:#85be06;-webkit-transform:rotate(100deg) translate(0,-50px);transform:rotate(100deg) translate(0,-50px);border-radius:2px;position:absolute;'></div>
    <div style='top:88px;left:98px;width:4px;height:24px;background:#85be06;-webkit-transform:rotate(110deg) translate(0,-50px);transform:rotate(110deg) translate(0,-50px);border-radius:2px;position:absolute;'></div>
    <div style='top:88px;left:98px;width:4px;height:24px;background:#85be06;-webkit-transform:rotate(120deg) translate(0,-50px);transform:rotate(120deg) translate(0,-50px);border-radius:2px;position:absolute;'></div>
    <div style='top:88px;left:98px;width:4px;height:24px;background:#85be06;-webkit-transform:rotate(130deg) translate(0,-50px);transform:rotate(130deg) translate(0,-50px);border-radius:2px;position:absolute;'></div>
    <div style='top:88px;left:98px;width:4px;height:24px;background:#85be06;-webkit-transform:rotate(140deg) translate(0,-50px);transform:rotate(140deg) translate(0,-50px);border-radius:2px;position:absolute;'></div>
    <div style='top:88px;left:98px;width:4px;height:24px;background:#85be06;-webkit-transform:rotate(150deg) translate(0,-50px);transform:rotate(150deg) translate(0,-50px);border-radius:2px;position:absolute;'></div>
    <div style='top:88px;left:98px;width:4px;height:24px;background:#85be06;-webkit-transform:rotate(160deg) translate(0,-50px);transform:rotate(160deg) translate(0,-50px);border-radius:2px;position:absolute;'></div>
    <div style='top:88px;left:98px;width:4px;height:24px;background:#85be06;-webkit-transform:rotate(170deg) translate(0,-50px);transform:rotate(170deg) translate(0,-50px);border-radius:2px;position:absolute;'></div>
    <div style='top:88px;left:98px;width:4px;height:24px;background:#85be06;-webkit-transform:rotate(180deg) translate(0,-50px);transform:rotate(180deg) translate(0,-50px);border-radius:2px;position:absolute;'></div>
    <div style='top:88px;left:98px;width:4px;height:24px;background:#85be06;-webkit-transform:rotate(190deg) translate(0,-50px);transform:rotate(190deg) translate(0,-50px);border-radius:2px;position:absolute;'></div>
    <div style='top:88px;left:98px;width:4px;height:24px;background:#85be06;-webkit-transform:rotate(200deg) translate(0,-50px);transform:rotate(200deg) translate(0,-50px);border-radius:2px;position:absolute;'></div>
    <div style='top:88px;left:98px;width:4px;height:24px;background:#85be06;-webkit-transform:rotate(210deg) translate(0,-50px);transform:rotate(210deg) translate(0,-50px);border-radius:2px;position:absolute;'></div>
    <div style='top:88px;left:98px;width:4px;height:24px;background:#85be06;-webkit-transform:rotate(220deg) translate(0,-50px);transform:rotate(220deg) translate(0,-50px);border-radius:2px;position:absolute;'></div>
    <div style='top:88px;left:98px;width:4px;height:24px;background:#85be06;-webkit-transform:rotate(230deg) translate(0,-50px);transform:rotate(230deg) translate(0,-50px);border-radius:2px;position:absolute;'></div>
    <div style='top:88px;left:98px;width:4px;height:24px;background:#85be06;-webkit-transform:rotate(240deg) translate(0,-50px);transform:rotate(240deg) translate(0,-50px);border-radius:2px;position:absolute;'></div>
    <div style='top:88px;left:98px;width:4px;height:24px;background:#85be06;-webkit-transform:rotate(250deg) translate(0,-50px);transform:rotate(250deg) translate(0,-50px);border-radius:2px;position:absolute;'></div>
    <div style='top:88px;left:98px;width:4px;height:24px;background:#85be06;-webkit-transform:rotate(260deg) translate(0,-50px);transform:rotate(260deg) translate(0,-50px);border-radius:2px;position:absolute;'></div>
    <div style='top:88px;left:98px;width:4px;height:24px;background:#85be06;-webkit-transform:rotate(270deg) translate(0,-50px);transform:rotate(270deg) translate(0,-50px);border-radius:2px;position:absolute;'></div>
    <div style='top:88px;left:98px;width:4px;height:24px;background:#85be06;-webkit-transform:rotate(280deg) translate(0,-50px);transform:rotate(280deg) translate(0,-50px);border-radius:2px;position:absolute;'></div>
    <div style='top:88px;left:98px;width:4px;height:24px;background:#85be06;-webkit-transform:rotate(290deg) translate(0,-50px);transform:rotate(290deg) translate(0,-50px);border-radius:2px;position:absolute;'></div>
    <div style='top:88px;left:98px;width:4px;height:24px;background:#85be06;-webkit-transform:rotate(300deg) translate(0,-50px);transform:rotate(300deg) translate(0,-50px);border-radius:2px;position:absolute;'></div>
    <div style='top:88px;left:98px;width:4px;height:24px;background:#85be06;-webkit-transform:rotate(310deg) translate(0,-50px);transform:rotate(310deg) translate(0,-50px);border-radius:2px;position:absolute;'></div>
    <div style='top:88px;left:98px;width:4px;height:24px;background:#85be06;-webkit-transform:rotate(320deg) translate(0,-50px);transform:rotate(320deg) translate(0,-50px);border-radius:2px;position:absolute;'></div>
    <div style='top:88px;left:98px;width:4px;height:24px;background:#85be06;-webkit-transform:rotate(330deg) translate(0,-50px);transform:rotate(330deg) translate(0,-50px);border-radius:2px;position:absolute;'></div>
    <div style='top:88px;left:98px;width:4px;height:24px;background:#85be06;-webkit-transform:rotate(340deg) translate(0,-50px);transform:rotate(340deg) translate(0,-50px);border-radius:2px;position:absolute;'></div>
    <div style='top:88px;left:98px;width:4px;height:24px;background:#85be06;-webkit-transform:rotate(350deg) translate(0,-50px);transform:rotate(350deg) translate(0,-50px);border-radius:2px;position:absolute;'></div>
    <div class="txtbold col-md-offset-6 col-lg-offset-6 col-sm-offset-6 col-xs-offset-5 leftmargin70">Please Wait...</div>
  </div>
</div>
