import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from '../../shared/common-services/api.service';
import { HttpClient } from "@angular/common/http";
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { ConfigService } from '../../shared/common-services/config.service';
import { MasterData } from '../../dynamic-form-components/models/question-base';
import { Observable } from 'rxjs';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class UserManagementService extends ApiService {

  constructor(protected router: Router, protected httpClient: HttpClient, protected config: ConfigService, protected authService: MsalService) {
    super(router, httpClient, config, authService);
  }

  getControls() {
    return this.get(environment.apiUrl + "/api/Dashboard");
  }

  addUser(body: any) {
    return this.post(environment.apiUrl +"/api/UserManagement/AddUser", body);
  }

  updateUser(body: any) {
    return this.post(environment.apiUrl +"/api/UserManagement/UpdateUser", body);
  }

  getUserByAlias(alias: any) {
   
    return  this.get(environment.apiUrl + "/api/UserManagement/User/" + alias);
   
  }

  getUserByTerm(term: any, limit: any, pageNumber: any) {
    return this.get(environment.apiUrl + "/api/UserManagement/ListUser/" + term + "/" + limit + "/" + pageNumber );
  }

  getAllUsers() {
    return this.get(environment.apiUrl + "/api/UserManagement/GetAllUserRoleList");
  }

  getActionByRole() {
    return this.get(environment.apiUrl + "/api/UserManagement/GetRoleSecurityAction" );
  }

  getAction(type: string): Observable<MasterData[]> {
    let url = '/api/MasterData/' + type
    return this.get<any>(url);
  }

  addActionByRole(body: any) {
    return this.post(environment.apiUrl + "/api/UserManagement/AddRoleAction", body);
  }

  addQueueNotification(queue: any) {
    let url = "/api/Notification/QueueNotification";
    return this.post<any>(url, queue);
  }
}
