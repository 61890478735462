<div class="container main_container">
  <div class="header_second">
    <div class="full_width">
      <div class="breadscrum_col">
        <ul>
          <li><a [routerLink]="'/home'"> My Dashboard</a></li>
          <li><span class="glyphicon glyphicon-menu-right cool_gray2"></span></li>
          <li><a [routerLink]="'/NotificationDashboard'">Manage Notification Templates</a></li>
          <li><span class="glyphicon glyphicon-menu-right cool_gray2"></span></li>
          <li>Email Template</li>
        </ul>
      </div>
    </div>
    <div class="full_width">
      <hr />
    </div>
    <div class="full_width margintop-10 template_row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <h1> Email Template </h1>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 margintop-10">

        <div class=" col-xs-6 col-sm-6 col-md-6 col-lg-6 no-padding ">
          <label class="col-xs-4 col-sm-4 col-md-4 col-lg-4 no-padding">Template Status : </label>
          <label class="switch" style="margin-left:7px;">
            <input type="checkbox" [checked]="emailActive" id="checkbox1" (change)="emailStatusChecked()">
            <span class="slider round"></span>
          </label>
        </div>

        <div class="  col-xs-6 col-sm-6 col-md-6 col-lg-6 padding_right-0" *ngIf="isTemplateIdShow">
          <label class="col-xs-2 col-sm-2 col-md-2 col-lg-2 no-padding" style="width:120px; padding-left:0px;">Template ID : </label>
          <label>{{templateIdValue}}</label>
        </div>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 margintop-20">

        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 no-padding" style="padding-right:0px;">
          <label class="col-xs-4 col-sm-4 col-md-4 col-lg-4 no-padding" style="padding-right:20px;">Email Category: </label>
          <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 no-padding" style="padding-left:3px">
            <div class="custom-control custom-radio">
              <input disabled id="eventtemplates" type="radio" class="custom-control-input" value="eventtemplates" name="templatesttype" [(ngModel)]="selectTemplate" (change)="changeType($event.target.value)">
              <label class="custom-control-label" for="eventtemplates" style="margin-left: 10px;">Trigger</label>
            </div>
          </div>
          <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 no-padding">
            <div class="custom-control custom-radio">
              <input disabled id="scheduletemplates" type="radio" class="custom-control-input" value="scheduletemplates" name="templatesttype" [(ngModel)]="selectTemplate" (change)="changeType($event.target.value)">
              <label class="custom-control-label" for="scheduletemplates" style="margin-left: 10px;">Schedule</label>
            </div>
          </div>

        </div>


        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 no-padding" style="padding-right:0px; padding-left:0px;">
          <label class="col-xs-4 col-sm-4 col-md-4 col-lg-4 no-padding" style="width:133px;">Frequency  : </label>
          <input type="text" class="form-control" style="width:52px; float:left; margin-right:7px;" [value]="emailfrequency" (input)="onInputChangedFrequency($event)" [disabled]="isDisable" />
          <label class="col-xs-4 col-sm-4 col-md-4 col-lg-4 no-padding" style="padding-left:5px;">Type : </label>
          <select formcontrolname="controlName" [(ngModel)]="selectFrequenctType" class="form-control form_txt" style="width: 170px;float:left;margin-right:8px; height:34px;" (change)="SelectFrequencyType($event)" [disabled]="isDisable">
            <option value="0">Select</option>
            <option value="Days">Days</option>
            <option value="Weekly">Weekly</option>
            <option value="Monthly">Monthly</option>
          </select>
        </div>
      </div>



      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 margintop-20">
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 no-padding">
          <label class="col-xs-4 col-sm-4 col-md-4 col-lg-4 no-padding">Email Type : </label>
          <select formcontrolname="controlName" class="form-control form_txt" disabled style="width:300px;" (change)="SelectNotificationId($event)" [(ngModel)]="selectCategory">
            <option value="0">Select</option>
            <option *ngFor="let category of categoryTypes" [value]="category">{{template2LabelMapping[category]}}</option>
          </select>
        </div>


        <div _ngcontent-ubo-c166="" class="col-xs-6 col-sm-6 col-md-6 col-lg-6" style="padding-right:0px;">

          <label _ngcontent-ubo-c166="" class="col-xs-2 col-sm-2 col-md-2 col-lg-2 no-padding" style="width: 120px;">Send Email : </label>
          <select formcontrolname="controlName" [(ngModel)]="emailtrigger" class="form-control form_txt" style="width: 90px;float:left;margin-right:8px; height:34px;" (change)="changeBeforeAfter($event.target.value)" [disabled]="isDisable">
            <option value="0">Select</option>
            <option value="Before">Before</option>
            <option value="After">After</option>
          </select>

          <label _ngcontent-ubo-c166="" class="col-xs-4 col-sm-4 col-md-4 col-lg-4 no-padding" style="width:145px;">On : </label>
          <select formcontrolname="controlName" [(ngModel)]="selectColumn" class="form-control form_txt" style="width: 169px;float:left;margin-right:8px; height:34px;" (change)="changeColumnValue($event.target.value)" [disabled]="isDisable">
            <option value="0">Select</option>
            <option value="LastUpdate">Last Update Date</option>
            <option value="NextUpdate">Next Update Date</option>
          </select>
        </div>

      </div>

      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 margintop-20">
        <div class="full_width">
          <label class="col-xs-2 col-sm-2 col-md-2 col-lg-2" style="padding-right:10px;">From  : </label>
          <input type="text" class="col-xs-10 col-sm-10 col-md-10 col-lg-10" style="padding-left: 5px; padding-right: 0px; height: 47px;" [value]="fromEmailAddress" disabled />
        </div>


        <!--<div class="col-xs-6 col-sm-6 col-md-6 col-lg-6" style="padding-right:0px;">
          <label class="col-xs-3 col-sm-3 col-md-3 col-lg-3 no-padding" style="width:120px;">To <span style="color:black;">*</span>  : </label>
          <input type="text" class="form-control" style="width:77%;" id="idTo" [value]="selectedUserEmailAddress" [attr.disabled]="isMatInputDisabled?'true':null"
                 matInput (input)="onInputChanged($event)" [matAutocomplete]="userOptions" autocomplete="off" />
          <mat-autocomplete #userOptions="matAutocomplete">
            <mat-option style="border-bottom-style: groove; font-size: 13px; height: 30px;"
                        *ngFor="let autocomleteValue of autocompleteValues ; let index=index" [value]="selectedUserEmailAddress"
                        (onSelectionChange)="selectedUser($event,index)">
              <div class="font14-black name_txt">{{autocomleteValue.displayname}} </div> <div class="mail_txt">{{autocomleteValue.displayaddress}}</div>

            </mat-option>
            <div style="margin-left:15px; margin-top:20px; margin-bottom:10px;" *ngIf="isShowmoreButton">
              <button class="btn btn_primary btn_lg nav_btns_green width_full" (click)="isShowmore()">Show More</button>
            </div>
          </mat-autocomplete>
          <div *ngIf="isNoUserFound">No User Found</div>
        </div>-->


      </div>


      <!--<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 margintop-20">
    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 no-padding">
      <label class="col-xs-4 col-sm-4 col-md-4 col-lg-4 no-padding">Cc  : </label>
      <input type="text" class="form-control" style="width:57%;" id="idCc" [value]="selectedCcUserEmailAddress" [attr.disabled]="isMatInputDisabled?'true':null"
             matInput (input)="onCcInputChanged($event)" [matAutocomplete]="userCcOptions" autocomplete="off" />
      <mat-autocomplete #userCcOptions="matAutocomplete">
        <mat-option style="border-bottom-style: groove; font-size: 13px; height: 30px;"
                    *ngFor="let autocomleteValue of autocompleteValues ; let index=index" [value]="selectedCcUserEmailAddress"
                    (onSelectionChange)="selectedCcUser($event,index)">
          <div class="font14-black name_txt">{{autocomleteValue.displayname}} </div> <div class="mail_txt">{{autocomleteValue.displayaddress}}</div>

        </mat-option>
        <div style="margin-left:15px; margin-top:20px; margin-bottom:10px;" *ngIf="isShowmoreButton">
          <button class="btn btn_primary btn_lg nav_btns_green width_full" (click)="isShowmore()">Show More</button>
        </div>
      </mat-autocomplete>
      <div *ngIf="isNoUserFound">No User Found</div>
    </div>
  </div>-->
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 margintop-20">
        <div class="full_width">
          <label class="col-xs-2 col-sm-2 col-md-2 col-lg-2" style="padding-right:10px;">To <span style="color:black;">*</span> : </label>
          <mat-form-field class="col-xs-10 col-sm-10 col-md-10 col-lg-10" style="padding-left: 5px; padding-right: 0px; height: 47px;">
            <mat-chip-list #toEmailChipList>
              <mat-chip *ngFor="let toUserValue of toUserValues" [selectable]="selectable" [removable]="removable" (removed)="removeTo(toUserValue)">
                {{toUserValue}}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
              </mat-chip>
              <input #toEmailInput type="text" (input)="onInputChanged($event)" id="idTo"
                     [formControl]="toEmailCtrl"
                     [matAutocomplete]="toauto"
                     [matChipInputFor]="toEmailChipList"
                     [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                     (matChipInputTokenEnd)="addTo($event)">
            </mat-chip-list>
            <mat-autocomplete #toauto="matAutocomplete" (optionSelected)="selectedTo($event)" [panelWidth]="500">
              <mat-option *ngFor="let autocomleteValue of toEmailFiltered | async" [value]="toEmail" style="border-bottom-style: groove; font-size: 13px; height: 30px;">
                <div class="font14-black name_txt">{{autocomleteValue.displayname}} </div> <div class="mail_txt">{{autocomleteValue.displayaddress}}</div>
              </mat-option>
              <div style="margin-left:15px; margin-top:20px; margin-bottom:10px;" *ngIf="isShowmoreButton">
                <button class="btn btn_primary btn_lg nav_btns_green width_full" (click)="isShowmore()">Show More</button>
              </div>
            </mat-autocomplete>
          </mat-form-field>
          <div *ngIf="isNoUserFound">No User Found</div>
        </div>
  </div>

      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 margintop-20">
        <div class="full_width">
          <label class="col-xs-2 col-sm-2 col-md-2 col-lg-2" style="padding-right:10px;">Cc : </label>
          <mat-form-field class="col-xs-10 col-sm-10 col-md-10 col-lg-10" style="padding-left:5px; padding-right: 0px">
            <mat-chip-list #chipList>
              <mat-chip *ngFor="let userValue of userValues" [selectable]="selectable" [removable]="removable" (removed)="remove(userValue)">
                {{userValue}}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
              </mat-chip>

              <input #ccEmailInput type="text" (input)="onCcInputChanged($event)" id="idCc"
                     [formControl]="ccEmailCtrl"
                     [matAutocomplete]="ccauto"
                     [matChipInputFor]="chipList"
                     [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                     (matChipInputTokenEnd)="add($event)">
            </mat-chip-list>
            <mat-autocomplete #ccauto="matAutocomplete" (optionSelected)="selected($event)" [panelWidth]="500">
              <mat-option *ngFor="let autocomleteValue of ccEmailFiltered | async" [value]="ccEmail" style="border-bottom-style: groove; font-size: 13px; height: 30px;">
                <div class="font14-black name_txt">{{autocomleteValue.displayname}} </div> <div class="mail_txt">{{autocomleteValue.displayaddress}}</div>
              </mat-option>
              <div style="margin-left:15px; margin-top:20px; margin-bottom:10px;" *ngIf="isShowmoreButton">
                <button class="btn btn_primary btn_lg nav_btns_green width_full" (click)="isShowmore()">Show More</button>
              </div>
            </mat-autocomplete>
          </mat-form-field>
          <div *ngIf="isNoUserFound">No User Found</div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 margintop-20">
        <div class="full_width">
          <label class="col-xs-2 col-sm-2 col-md-2 col-lg-2" style="padding-right:10px;">Subject <span style="color:black;">*</span>  : </label>
          <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10" style="padding-left:5px; padding-right: 0px">
            <input type="text" class="form-control" [(ngModel)]="subjectValue" />
          </div>
        </div>
      </div>



      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 margintop-20">

        <div>
          <label class="col-xs-2 col-sm-2 col-md-2 col-lg-2" style="width:185px; padding-right:10px;" for="usr">Body :</label>
          <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10 no-padding" style="padding-left:5px; padding-right:0px;">
            <ckeditor id="editor1" [config]="editorConfig" data="<p>Hello, world!</p>" (ready)="attachAutoComplete($event)" [(ngModel)]="bodyValue"></ckeditor>
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 margintop-20">

        <div style="float: right; margin-right: 10px;">
          <button class="btn btn_primary btn_lg nav_btns_green ng-star-inserted" [routerLink]="'/NotificationDashboard'">Cancel</button>

        </div>
        <div style="float: right; margin-right: 10px; ">
          <button class="btn btn_primary btn_lg nav_btns_green ng-star-inserted" (click)="saveEmailTemplate()" [disabled]="isSaveDisable">Save</button>

        </div>
        <div style="float: right; margin-right: 10px;">
          <button class="btn btn_primary btn_lg nav_btns_green ng-star-inserted" (click)="clearTemplate()">Reset</button>

        </div>
      </div>
    </div>

    </div>
</div>
