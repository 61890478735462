import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService, BroadcastService } from '@azure/msal-angular';
import { Subscription } from 'rxjs';
import { CommonService } from './shared/common-services/common.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy {

  constructor(private msalService: MsalService, private broadcastService: BroadcastService) {
      
  }
  ngOnInit() {   
 }
   ngOnDestroy() {
    this.broadcastService.getMSALSubject().next(1);
    this.msalService.logout();
    localStorage.clear();
  }

}
