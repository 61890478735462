import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { ApiService } from '../../shared/common-services/api.service';
import { ConfigService } from '../../shared/common-services/config.service';
import { notificationdata } from '../models/notification-data';
import { placeholderdata } from '../models/placeholder-data';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class EmailtemplateService extends ApiService {
  http: any;

  constructor(protected router: Router, protected httpClient: HttpClient, protected config: ConfigService,
    protected authService: MsalService) {
    super(router, httpClient, config, authService);
  }

  getByNotificationTemplateId(templateid: string): Observable<notificationdata> {
    let url = '/api/NotificationTemplate/' + templateid;
    return this.get<any>(url);
  }


  saveNotificationTemplate(notificationtemplate: notificationdata) {
    let url = '/api/NotificationTemplate';
    return this.post<any>(url, notificationtemplate);
  }

  getEmailTemplatePlaceHolder(): Observable<placeholderdata[]> {
    let url = '/api/NotificationTemplate/GetAllPlaceholder';
    return this.get<placeholderdata[]>(url);
  }

  getAllEmailTemplate(): Observable<notificationdata[]> {
    let url = '/api/NotificationTemplate';
    return this.get<notificationdata[]>(url);
  }

}
