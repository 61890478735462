<div class="popup_container">
  <div class="full_width paddingbottom-20px">
    <h1>{{headerText}}</h1>
    <div mat-button class="right close_btn" (click)="closeDialog()">X</div>
  </div>


  <div class="full_width paddingbottom-20px" style="padding-bottom: 30px;">
    <div class="form-group">
      <label for="name">Role Manager</label>
      <div class="full_width relative_p">
        <span class="dds-icon_sm dds-icon_search search_ico"></span>
        <div class="sectionUser user_box">

          <div>
            <input matInput
                   placeholder="Text the name of the new role manager"
                   autocomplete="on"
                   class="form-control textUserName paddinleft28">

            <div>No User Found</div>
          </div>

        </div>

      </div>
    </div>
  </div>


  <div class="full_width paddingbottom-10px">
    <label for="name">Can Access</label>
  </div>
  <div class="roles_list">
    <ul>
      <li>
        <div class="full-width">
          <label class="container-cr">
            <span class="check_txt"></span>
            <input type="checkbox" >
            <span class="checkmark"></span>
          </label>
          <span class="font14-black txt_check">View attestation form</span>
          
        </div>
      </li>
    </ul>
    </div>

  </div>
