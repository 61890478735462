import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CommonService } from './common.service';

@Injectable()
export class ApiResolver implements Resolve<any> {

  constructor(private http: HttpClient, private commonservice: CommonService) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    debugger;
    var loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    return this.commonservice.getAllSecurityAction(loggedInUser);
  }

  //resolve(): Observable<any> {
  //  debugger;
  //  var loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
  //  let url = "/api/UserManagement/User/" + loggedInUser.userName.toLowerCase();  

  //  return this.http.get(url).pipe(
  //    map((dataFromApi) => dataFromApi),
  //    catchError((err) => Observable.throw(err.json().error))
  //  )

}
