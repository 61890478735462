import { Injectable } from '@angular/core';
import { AttestationForm, AttestationFormResponse, MasterData } from '../models/question-base';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { FormControlName } from '@angular/forms';
import { MsalService } from '@azure/msal-angular';
import { ApiService } from '../../shared/common-services/api.service';
import { ConfigService } from '../../shared/common-services/config.service';
import { Questionnaire } from '../../manage-questionnaire/models/questionnaire';

@Injectable()
export class QuestionService extends ApiService {

  constructor(
    protected httpClient: HttpClient, protected config: ConfigService, protected router: Router, protected authService: MsalService
  ) {

    super(router, httpClient, config,authService);
  }


  getQuestionnaire(controlName: string, questionnaireId: string): Observable<Questionnaire> {
    let url = "/api/Attestation/get/questionnaire/" + controlName + "/" + questionnaireId;
    return this.get<Questionnaire>(url);
  }
  getPreviousAttestationForms(controlName: string): Observable<any> {
    let url = "/api/Attestation/get/previousAttestationforms/" + controlName;
    return this.get<any>(url);
  }
  getAttestationFormById(controlName: string, timestamp: string): Observable<AttestationForm[]> {
    let url = "/api/Attestation/get/attestationFormById/" + controlName + "/" + timestamp;
    return this.get<AttestationForm[]>(url);
  }
  saveAttestationForm(responses: AttestationFormResponse[], files: Array<File> ): Observable<any> {
    const formData = new FormData();
    if (files && files.length > 0) {
      files.forEach((file) => {
        formData.append('file', file);
      });
    }
    formData.append('attestationFormResponse', JSON.stringify(responses));
    let url = this.config.getSettings('apiBaseURL') + "/api/Attestation/save/attestationform"
    return this.httpClient.post(url, formData, { headers: new HttpHeaders({ 'Authorization': 'true' }) });
  }
  downLoadAttachment(question: AttestationForm) {
    let url =  "/api/Attestation/download/" + question.controlName + "/" + question.formCreatedStamp + "/" + question.questionId;
    return this.get<any>(url);
  }

  addQueueNotification(queue: any) {
    let url = "/api/Notification/QueueNotification";
    return this.post<any>(url, queue);
  }
  sentNotification(body: any) {
    return this.post("/api/Notification/QueueNotification", body);
  }

}
