import { PlaceholderPiece } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { filter } from 'rxjs/operators';
import { CommonService } from '../shared/common-services/common.service';
import { SpinnerService } from '../shared/spinner/spinner.service';
import { AutoTagConfig } from './models/autotag-config';
import { notificationdata } from './models/notification-data';
import { notificationtype, notificationtype2LabelMapping} from './models/notification-type';
import { placeholderdata } from './models/placeholder-data';
import { eventtemplate, eventtemplate2LabelMapping } from './models/event-templates';
import { scheduletemplate, scheduletemplate2LabelMapping } from './models/schedule-templates';
import {EmailtemplateService } from './services/emailtemplate.service';
import { RunPeriod } from '../dashboard/models/run-period';
import { element } from 'protractor';
import { parse } from 'url';
//import { CKEditor4 } from 'ckeditor4-angular/ckeditor';


import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ElementRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ConfigService } from '../shared/common-services/config.service';


declare var CKEDITOR: any;


@Component({
  selector: 'app-emailtemplate',
  templateUrl: './emailtemplate.component.html',
  styleUrls: ['./emailtemplate.component.scss']
})

export class EmailtemplateComponent implements OnInit {

  
  public toValue: string="";
  public subjectValue: string="";
  public bodyValue: string="";
  isMatInputDisabled: boolean = false;
  isUserSelected: boolean = true;
  autocompleteValues: Array<any> = [];
  tempUserList: Array<any> = [];
  userList: any;
  searchstring: any = '';
  isNoUserFound: boolean;
  paginatorLink: string;
  isShowmoreButton: boolean = true;
  pageNumber: number = 1;
  loadUser: any;
  placeholders: any;
  selectedUserDisplayName: string = '';
  selectedUserEmailAddress: string = '';
  selectedUseralias: string = '';
  prevEmailAddrCount: number = 0; 
  selectedCcUserDisplayName: string = '';
  selectedCcUserEmailAddress: string = '';
  selectedCcUseralias: string = '';
  isCcUserSelected: boolean = true;
  prevCcEmailAddrCount: number = 0;
  emailActive: boolean = true;
  templateIdValue: string = "";
  isTemplateIdShow: boolean = false;
  Placeholders: placeholderdata[];
  templateId: string = "";
  template2LabelMapping : {};
  eventTemplate = Object.values(eventtemplate).filter(value => typeof value === 'number');
  scheduleTemplate = Object.values(scheduletemplate).filter(value => typeof value === 'number');
  notificationTypeEnum = Object.values(notificationtype).filter(value => typeof value === 'number');
  fromEmailAddress: string = "";
  categoryTypes: any;
  runPeriods: RunPeriod[] = [];
  showUser: boolean = false;
  notificationType: string="";
  emailfrequency: string="0";
  emailtrigger: string="0";
  selectedFrequencyType: string="Days";
  notificationTemplateData: notificationdata = {
    notificationTemplateId: "",
    to: "",
    cc: "",
    subject: "",
    body: "",
    notificationType: 1,
    isActive: true,
    frequency: 0,
    frequencyType: "",
    frequencyBeforeOrAfter: "",
    columnValue:"",
  };
  selectFrequenctType = "0"; 
  selectTemplate = "";
  selectCategory = "0";
  selectColumn="0"
  isDisable: boolean = true;
  isSaveDisable: boolean = false;

  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  ccEmailCtrl = new FormControl();
  toEmailCtrl = new FormControl();
  ccEmailFiltered: Observable<string[]>;
  toEmailFiltered: Observable<string[]>;
  userValues: string[] = [];
  toUserValues: string[] = [];
  
  @ViewChild('ccEmailInput') ccEmailInput: ElementRef<HTMLInputElement>;
  @ViewChild('toEmailInput') toEmailInput: ElementRef<HTMLInputElement>;

  constructor(private render: Renderer2, private emailservice: EmailtemplateService, private spinnerService: SpinnerService,
    private commonService: CommonService, private router: Router, private toastr: ToastrService, private route: ActivatedRoute, protected config: ConfigService) {
    this.fromEmailAddress=this.config.getSettings('fromEmail');
    const routeParams = this.route.snapshot.paramMap
    this.templateId = routeParams.get("templateid");
    
    if (this.templateId != null && this.templateId != "") {
      this.emailservice.getByNotificationTemplateId(this.templateId).subscribe(template => {
        this.notificationTemplateData = template;
        if (this.notificationTemplateData.to != undefined && this.notificationTemplateData.to != null && this.notificationTemplateData.to != "") {
          //this.selectedUserEmailAddress = this.notificationTemplateData.to;
          this.selectedUserDisplayName = "";
          this.selectedUseralias = "";
          this.prevEmailAddrCount = this.notificationTemplateData.to.split(";").length;
          let ToEmailValues = this.notificationTemplateData.to.trim().split(";");
          ToEmailValues.forEach((Value) => {
            if (Value != "")
              this.toUserValues.push(Value);

          })
          
        }

        if (this.notificationTemplateData.cc != undefined && this.notificationTemplateData.cc != null && this.notificationTemplateData.cc != "") {
          //this.selectedCcUserEmailAddress = this.notificationTemplateData.cc;
          this.selectedUserDisplayName = "";
          this.selectedUseralias = "";
          this.prevCcEmailAddrCount = this.notificationTemplateData.cc.split(";").length;
          let CcEmailValues = this.notificationTemplateData.cc.trim().split(";");
          CcEmailValues.forEach((Value) => {
            if (Value != "")
              this.userValues.push(Value);

          })
        }

        this.emailActive = this.notificationTemplateData.isActive;
        this.subjectValue = this.notificationTemplateData.subject;
        this.bodyValue = this.notificationTemplateData.body.includes("<") ? JSON.parse(this.notificationTemplateData.body) : this.notificationTemplateData.body;
        this.isTemplateIdShow = true;
        this.templateIdValue = this.notificationTemplateData.notificationTemplateId;
        this.changeBeforeAfter(this.notificationTemplateData.frequencyBeforeOrAfter);
        this.selectFrequenctType = this.notificationTemplateData.frequencyType;
        this.emailfrequency = (this.notificationTemplateData.frequency).toString();
        if (this.notificationTemplateData.notificationType === 2) {
          this.selectTemplate = "eventtemplates"          
        } else if (this.notificationTemplateData.notificationType === 1) {
          this.selectTemplate = "scheduletemplates"
        }
        this.changeType(this.selectTemplate);
        this.selectCategory = this.notificationTemplateData.notificationTemplateId;
        this.changeColumnValue(this.notificationTemplateData.columnValue);
      });
    }
  }

  addTo(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our toEmail
    if (value) {
      this.autocompleteValues.push(value);
    }

    // Clear the input value
    //event.chipInput!.clear();

    this.toEmailCtrl.setValue(null);
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our ccEmail
    if (value) {
      this.autocompleteValues.push(value);
    }

    // Clear the input value
    //event.chipInput!.clear();

    this.ccEmailCtrl.setValue(null);
  }


  removeTo(toEmail: string): void {
    const index = this.toUserValues.indexOf(toEmail);

    if (index >= 0) {
      this.toUserValues.splice(index, 1);
    }
  }

  remove(ccEmail: string): void {
    const index = this.userValues.indexOf(ccEmail);

    if (index >= 0) {
      this.userValues.splice(index, 1);
    }
  }

  clearTemplate = () => {
    this.emailActive = true;
    this.subjectValue = "";
    this.bodyValue = "";
    this.changeBeforeAfter("0");    
    this.selectCategory = this.notificationTemplateData.notificationTemplateId;
    this.changeColumnValue("0");
    this.selectedUserEmailAddress = "";
    this.prevEmailAddrCount = 0;
    this.selectedCcUserEmailAddress = "";
    this.prevCcEmailAddrCount = 0;
    this.emailfrequency = "0";
    this.userValues = [];
    this.toUserValues = [];
  }
  
  ngOnInit(): void {
    this.render.addClass(document.body, 'mypage');
    
  }

  emailStatusChecked=()=> {
    this.emailActive = (this.emailActive) ? false : true;
  }

 
  

  isShowmore() {
    this.pageNumber = this.pageNumber + 1;
    this.isShowmoreButton = true;
    this.spinnerService.showSpinner();
    this.commonService.getUserByTerm(10, this.searchstring, this.paginatorLink).subscribe((result: any) => {
      if (result != undefined || result != null) {
        this.loadUser = result;
        if (this.loadUser != undefined && this.loadUser.value.length < 10) {
          this.isShowmoreButton = false;
        }
        this.loadUser.value.forEach((element: any) => {
          this.tempUserList.push({ userName: element.displayName, emailAddress: element.mail, alias: element.mail });
        });
      }
      this.paginatorLink = this.loadUser["@odata.nextLink"];
      this.autocompleteValues = this.tempUserList;
      this.spinnerService.hideSpinner();
      // result.loadUser = null;
    });
  }

  onInputChangedFrequency(event: any) {

    this.emailfrequency = event.target.value != "" ? event.target.value : "0";
  }

  onInputChanged(event: any) {
    
    var currentLengthOfTo = (event.target as HTMLInputElement).value.split(";").length;
    if (currentLengthOfTo > 0 && this.prevEmailAddrCount > 0 && (this.prevEmailAddrCount + 1) != currentLengthOfTo) {
      this.prevEmailAddrCount = currentLengthOfTo - 1;
      var tempEmailList = [];
      for (var i = 0; i < this.prevEmailAddrCount; i++) {
        tempEmailList.push(this.selectedUserEmailAddress.split(";")[i].trim());
      }
      if (tempEmailList.length > 0)
        this.selectedUserEmailAddress = "";
      tempEmailList.forEach((email) => {
        this.selectedUserEmailAddress += email + "; ";
      });
    } else if (currentLengthOfTo == 1) {
      this.prevEmailAddrCount = 0;
      this.selectedUserEmailAddress = "";
    }

    
      
    this.autocompleteValues = [];
    this.isUserSelected = false;
    let emailaddrvalue = this.prevEmailAddrCount > 0 ? (event.target as HTMLInputElement).value.split(";")[this.prevEmailAddrCount].trim() : (event.target as HTMLInputElement).value.split(";")[this.prevEmailAddrCount];
    var pattern = /\#{2}(|\#)*$/;
    var match = emailaddrvalue.match(pattern);
    if (match != null) {
      this.isShowmoreButton = false;
      this.tempUserList = [];
      this.showUser = false;
      this.emailservice.getEmailTemplatePlaceHolder().subscribe(result => {
        this.placeholders = result;
        this.placeholders.filter(item => item.notificationPlaceHolderName == 'Contributor' || item.notificationPlaceHolderName == 'Administrator' || item.notificationPlaceHolderName == 'User').forEach((element: any) => {
          this.tempUserList.push({ displayname: element.notificationPlaceHolderName, displayaddress: "", alias: null });
        });
        this.autocompleteValues = this.tempUserList;
        this.toEmailFiltered = this.toEmailCtrl.valueChanges.pipe(
          startWith(null),
          map((toEmail: string | null) => toEmail ? this._filterTo(toEmail) : this.autocompleteValues.slice()));
      });
      
    }
    else if (emailaddrvalue.length >= 4) {
      this.isShowmoreButton = true;
      this.showUser = true;
      this.autocompleteValues = [];      
      this.spinnerService.showSpinner();
      setTimeout(() => {
        this.commonService.getUserByTerm(10, emailaddrvalue, null).subscribe((result: any) => {
          if (result != undefined || result != null) {
            this.userList = result;
            if (emailaddrvalue != this.searchstring) {
              this.tempUserList = [];
              this.userList.value.forEach((element: any) => {
                if (element != null) {
                  if (!(element.officeLocation! = null)) {
                    if ((element.userPrincipalName.toLowerCase().indexOf('@deloitte.com') > -1) && (element.userPrincipalName.toLowerCase().indexOf('usa-') != 0)) {
                      this.tempUserList.push({ displayname: element.displayName, displayaddress: element.userPrincipalName != null ? element.userPrincipalName.toLowerCase() : null, alias: element.userPrincipalName != null ? element.userPrincipalName.toLowerCase() : null });
                    }
                  }
                }
              });
              this.isNoUserFound = false;
            }
            this.paginatorLink = this.userList["@odata.nextLink"];
          }
          if (this.userList != undefined && this.userList.value.length < 10) {
            this.isShowmoreButton = false;
          }
          if (this.userList != undefined && this.userList.value.length == 0) {
            this.isNoUserFound = true;
          }
          this.searchstring = (event.target as HTMLInputElement).value;
          this.autocompleteValues = this.tempUserList;
          this.toEmailFiltered = this.toEmailCtrl.valueChanges.pipe(
            startWith(null),
            map((toEmail: string | null) => toEmail ? this._filterTo(toEmail) : this.autocompleteValues.slice()));
          this.spinnerService.hideSpinner();

        });
      }, 300);
    }
    else if ((event.target as HTMLInputElement).value.length == 0) {
      this.autocompleteValues = [];
      this.spinnerService.hideSpinner();
    }
    else {
      this.autocompleteValues = [];
      this.spinnerService.hideSpinner();
    }
  }

  onCcInputChanged(event: any) {
    var currentLengthOfTo = (event.target as HTMLInputElement).value.split(";").length;
    if (currentLengthOfTo > 0 && this.prevCcEmailAddrCount > 0 && (this.prevCcEmailAddrCount + 1) != currentLengthOfTo) {
      this.prevCcEmailAddrCount = currentLengthOfTo - 1;
      var tempCCEmailList = [];
      for (var i = 0; i < this.prevCcEmailAddrCount; i++) {
        tempCCEmailList.push(this.selectedCcUserEmailAddress.split(";")[i].trim());
      }
      if (tempCCEmailList.length > 0)
        this.selectedCcUserEmailAddress = "";
      tempCCEmailList.forEach((email) => {
        this.selectedCcUserEmailAddress += email + "; ";
      });
    } else if (currentLengthOfTo == 1) {
      this.prevCcEmailAddrCount = 0;
      this.selectedCcUserEmailAddress = "";
    }
      
    this.autocompleteValues = [];
    this.isCcUserSelected = false;
    let emailaddrvalue = this.prevCcEmailAddrCount > 0 ? (event.target as HTMLInputElement).value.split(";")[this.prevCcEmailAddrCount].trim() : (event.target as HTMLInputElement).value.split(";")[this.prevCcEmailAddrCount];
    var pattern = /\#{2}(|\#)*$/;
    var match = emailaddrvalue.match(pattern);
    if (match != null) {
      this.isShowmoreButton = false;
      this.tempUserList = [];
      this.showUser = false;
      this.emailservice.getEmailTemplatePlaceHolder().subscribe(result => {
        this.placeholders = result;
        this.placeholders.filter(item => item.notificationPlaceHolderName == 'Contributor' || item.notificationPlaceHolderName == 'Administrator' || item.notificationPlaceHolderName == 'User').forEach((element: any) => {
          this.tempUserList.push({ displayname: element.notificationPlaceHolderName, displayaddress: "", alias: null });
        });
        this.autocompleteValues = this.tempUserList;
        this.ccEmailFiltered = this.ccEmailCtrl.valueChanges.pipe(
          startWith(null),
          map((ccEmail: string | null) => ccEmail ? this._filter(ccEmail) : this.autocompleteValues.slice()));
      });
      
    }
    else if (emailaddrvalue.length >= 4) {
      this.autocompleteValues = [];
      this.showUser = true;
      this.spinnerService.showSpinner();
      setTimeout(() => {
        this.commonService.getUserByTerm(10, emailaddrvalue, null).subscribe((result: any) => {
          if (result != undefined || result != null) {
            this.userList = result;
            if (emailaddrvalue != this.searchstring) {
              this.tempUserList = [];
              this.userList.value.forEach((element: any) => {
                if (element != null) {
                  if (!(element.officeLocation! = null)) {
                    if ((element.userPrincipalName.toLowerCase().indexOf('@deloitte.com') > -1) && (element.userPrincipalName.toLowerCase().indexOf('usa-') != 0)) {
                      this.tempUserList.push({ displayname: element.displayName, displayaddress: element.userPrincipalName != null ? element.userPrincipalName.toLowerCase() : null, alias: element.userPrincipalName != null ? element.userPrincipalName.toLowerCase() : null });
                    }
                  }
                }
              });
              this.isNoUserFound = false;
            }
            this.paginatorLink = this.userList["@odata.nextLink"];
          }
          if (this.userList != undefined && this.userList.value.length < 10) {
            this.isShowmoreButton = false;
          }
          if (this.userList != undefined && this.userList.value.length == 0) {
            this.isNoUserFound = true;
          }
          this.searchstring = (event.target as HTMLInputElement).value;
          this.autocompleteValues = this.tempUserList;
          this.ccEmailFiltered = this.ccEmailCtrl.valueChanges.pipe(
          startWith(null),
            map((ccEmail: string | null) => ccEmail ? this._filter(ccEmail) : this.autocompleteValues.slice()));
          this.spinnerService.hideSpinner();

        });
      }, 300);

    }
    else if ((event.target as HTMLInputElement).value.length == 0) {
      this.autocompleteValues = [];
      this.spinnerService.hideSpinner();
    }
    else {
      this.autocompleteValues = [];
      this.spinnerService.hideSpinner();
    }
  }

  private _filterTo(value: string): string[] {
    const toEmailFilterValue = value.toLowerCase();

    return this.toUserValues.filter(toEmail => toEmail.toLowerCase().includes(toEmailFilterValue));
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.userValues.filter(ccEmail => ccEmail.toLowerCase().includes(filterValue));
  }


  selectedTo(event: MatAutocompleteSelectedEvent): void {
    let selectedValues = event.option.viewValue.split(" ");
    let selectedEmail = selectedValues[selectedValues.length - 1];
    let selectedValue = "";
    if (selectedEmail.includes("@"))
      selectedValue = selectedEmail;
    else
      selectedValue = "##" + selectedEmail + "##";
    this.toUserValues.push(selectedValue);
    this.toEmailInput.nativeElement.value = '';
    this.toEmailCtrl.setValue(null);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    let selectedValues = event.option.viewValue.split(" ");
    let selectedEmail = selectedValues[selectedValues.length - 1];
    let selectedValue = "";
    if (selectedEmail.includes("@"))
      selectedValue = selectedEmail;
    else
      selectedValue = "##" + selectedEmail + "##";
    this.userValues.push(selectedValue);
    this.ccEmailInput.nativeElement.value = '';
    this.ccEmailCtrl.setValue(null);
  }

  //selectedUser(partner: any, index: any) {
  //  this.selectedUserDisplayName = this.autocompleteValues[index].displayname + ";";
  //  this.selectedUserEmailAddress += (this.showUser) ? this.autocompleteValues[index].displayaddress + "; " : "##" + this.autocompleteValues[index].displayname + "##; ";
  //  this.selectedUseralias += this.autocompleteValues[index].alias + ";";
  //  this.isUserSelected = true;
  //  this.prevEmailAddrCount++;    
  //  this.autocompleteValues = [];
  //}

  
  //selectedCcUser(partner: any, index: any) {
  //  this.selectedCcUserDisplayName = this.autocompleteValues[index].displayname + ";";
  //  this.selectedCcUserEmailAddress += (this.showUser) ? this.autocompleteValues[index].displayaddress + "; " : "##" + this.autocompleteValues[index].displayname + "##; ";
  //  this.selectedCcUseralias += this.autocompleteValues[index].alias + ";";
  //  this.isCcUserSelected = true;
  //  this.prevCcEmailAddrCount++;
  //  this.autocompleteValues = [];
  //}

  public editorConfig = {
    //enterMode : CKEDITOR.ENTER_BR,
    removePlugins: 'magicline,scayt',
   //extraPlugins: 'liststyle, autocomplete, textmatch',
    copyFormatting_allowedContexts: true,
    copyFormatting_allowRules: true,
    disableNativeSpellChecker: false,
    scayt_autoStartup: true,
    scayt_uiTabs: "0,0,0",
    fillEmptyBlocks: true,
    autoParagraph: false,

    toolbarGroups: [
      { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
      { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
      { name: 'insert', groups: ['insert'] },
      { name: 'styles', groups: ['styles'] },
      { name: 'colors', groups: ['colors'] },
      { name: 'links', groups: ['links'] },
      { name: 'forms', groups: ['forms'] },
      { name: 'document', groups: ['mode', 'document', 'doctools'] },
      { name: 'clipboard', groups: ['clipboard', 'undo'] },
      { name: 'editing', groups: ['find', 'selection', 'editing'] },
      { name: 'tools', groups: ['tools'] },
      { name: 'others', groups: ['others'] },
      { name: 'about', groups: ['about'] }
    ],

    removeButtons: 'Format,Source,Save,NewPage,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Find,Replace,SelectAll,Form,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Strike,Subscript,Superscript,RemoveFormat,Blockquote,CreateDiv,BidiLtr,BidiRtl,Language,Anchor,Image,Flash,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,Maximize,About,Checkbox,Scayt,ShowBlocks,Styles',
    pasteFilter: 'semantic-content',
    disallowedContent: 'img',
    baseFloatZIndex: 1000,
    font_names: "Arial/Arial, Helvetica, sans-serif;Comic Sans MS/Comic Sans MS, cursive;Courier New/Courier New, Courier, monospace;Georgia/Georgia, serif;Lucida Sans Unicode/Lucida Sans Unicode, Lucida Grande, sans-serif;Tahoma/Tahoma, Geneva, sans-serif;Times New Roman/Times New Roman, Times, serif;Trebuchet MS/Trebuchet MS, Helvetica, sans-serif;Verdana/Verdana, Geneva, sans-serif;Calibri/Calibri, sans-serif",
    fontSize_sizes: '8/8pt;9/9pt;10/10pt;11/11pt;12/12pt;14/14pt;16/16pt;18/18pt;20/20pt;22/22pt;24/24pt;26/26pt;28/28pt;36/36pt;48/48pt;72/72pt'
  }

  saveEmailTemplate = () => {
    
    this.userValues.forEach((val) => {
      this.selectedCcUserEmailAddress += val + "; ";
    })

    this.toUserValues.forEach((val) => {
      this.selectedUserEmailAddress += val + "; ";
      this.prevEmailAddrCount++;
    })

    this.notificationTemplateData = {
      notificationTemplateId: "",
      to: "",
      cc: "",
      subject: "",
      body: "",
      notificationType: 1,
      isActive: true,
      frequency: 0,
      frequencyType: "",
      frequencyBeforeOrAfter: "",
      columnValue:""
    };
    
    if (this.ValidateTemplateRequest()) {
      this.notificationTemplateData.body = JSON.stringify(this.bodyValue);
      this.notificationTemplateData.to = this.selectedUserEmailAddress;
      this.notificationTemplateData.cc = this.selectedCcUserEmailAddress;
      this.notificationTemplateData.notificationTemplateId = this.templateIdValue;
      this.notificationTemplateData.notificationType = parseInt(this.notificationType);
      this.notificationTemplateData.subject = this.subjectValue;
      this.notificationTemplateData.isActive = this.emailActive;
      this.notificationTemplateData.frequency = parseInt(this.emailfrequency);
      this.notificationTemplateData.frequencyBeforeOrAfter = this.emailtrigger;
      this.notificationTemplateData.frequencyType = this.selectedFrequencyType;
      this.notificationTemplateData.columnValue = this.selectColumn;
      this.emailservice.saveNotificationTemplate(this.notificationTemplateData).subscribe(result => {        
        //window.location.reload();
        this.isSaveDisable = true;
        this.toastr.success("Notification Email template has saved");        
      },
        (err: any) => {
          this.toastr.error(err);
          this.spinnerService.hideSpinner();
          
        });
          
    }

  }

  ValidateTemplateRequest() {
    var returnValue = true;
    if (this.templateIdValue != null && this.templateIdValue == "") {      
      returnValue = false;
    }
    if (this.toUserValues != null && this.toUserValues.length==0) {
      returnValue = false;
    }
    if (this.subjectValue != null && this.subjectValue == "") {      
      returnValue = false;
    }
    if (returnValue === false)
      this.toastr.error("Please Enter the mandatory fields");
    return returnValue;
  }

  loadAutoCompleteConfig(): AutoTagConfig {
    var config = new AutoTagConfig();
    var placeholderData = [];
    this.emailservice.getEmailTemplatePlaceHolder().subscribe(result => {
      placeholderData = result;
    })


    function textTestCallback(range) {
      // You do not want to autocomplete a non-empty selection.
      if (!range.collapsed) {
        return null;
      }

      // Use the text match plugin which does the tricky job of performing
      // a text search in the DOM. The "matchCallback" function should return
      // a matching fragment of the text.
      return CKEDITOR.plugins.textMatch.match(range, matchCallback);
    }


    // Returns the position of the matching text.
    // It matches a word starting from the '#' character
    // up to the caret position.
    function matchCallback(text, offset) {
      var pattern = /\#{2}([A-z]|\#)*$/,
        match = text.slice(0, offset)
          .match(pattern);

      if (!match) {
        return null;
      }
      return {
        start: match.index,
        end: offset
      };
    }

    config.textTestCallback = textTestCallback;

    
    function dataCallback(matchInfo, callback) {
      var data = [];
      data = placeholderData.map(function (placeholder) {
          var autocomplateData = { name: '', id: '', IsReplaceOnSelect: '', value: '' };
          autocomplateData.name = placeholder.notificationPlaceHolderName;
          autocomplateData.id = placeholder.notificationPlaceHolderId;
          autocomplateData.IsReplaceOnSelect = placeholder.notificationPlaceholderType;
          autocomplateData.value = placeholder.notificationPlaceHolderName;
          return autocomplateData;
        });        
        callback(data);
    }

    config.dataCallback = dataCallback;

    config.itemTemplate = '<li data-id="{id}">{name}</li>';
    config.outputTemplate = '<span data-id="{id}">##{name}##</span>';

    return config;
  }

  attachAutoComplete(event) {
    // Attach autocomplete to the editor.
    setTimeout(() => new CKEDITOR.plugins.autocomplete(CKEDITOR.instances[Object.keys(CKEDITOR.instances)[0]], this.loadAutoCompleteConfig()));
  }

  

  changeType = (value) => {
    if (value == "eventtemplates") {
      this.template2LabelMapping = eventtemplate2LabelMapping;
      this.categoryTypes = this.eventTemplate;
      this.notificationType = "2";
      this.isDisable = true;
    }
    else {
      this.template2LabelMapping = scheduletemplate2LabelMapping;
      this.categoryTypes = this.scheduleTemplate;
      this.notificationType = "1";
      this.isDisable = false;
    }
  }

  changeBeforeAfter = (val) => {
    this.emailtrigger = val;
  }

  SelectFrequencyType(e) {
    this.selectedFrequencyType = e.target.value;
  }

  SelectNotificationId(e) {
    this.templateIdValue = e.target.value;
  }

  changeColumnValue(val) {
    this.selectColumn = val;
  }
}
