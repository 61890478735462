import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { SpinnerService } from '../shared/spinner/spinner.service';
import { ActivatedRoute, Router } from '@angular/router';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import { ToastrService } from 'ngx-toastr';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from '../shared/common-services/common.service';
import { RunPeriod } from '../dashboard/models/run-period';
import { DashboardService } from '../dashboard/services/dashboard.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { fileSizeValidator, requiredFileType } from '../dynamic-form-components/services/question-control.service';
import { FileUploadService } from '../file-upload/file-upload-service';
import { RoleControls } from '../create-user/models/role-controls';

@Component({
  selector: 'app-reprocessing',
  templateUrl: './reprocess.component.html',
  styleUrls: ['./reprocess.component.scss'],
  providers: [CommonService, FileUploadService]
})

export class ReprocessComponent implements OnInit {  
  headerText: string = '';
  runPeriods: RunPeriod[] = [];
  selectedRunTimestamp: string;
  selectedSource: string;
  selectedSourceName: string;
  controlName: string;
  sourceNames: any = [];
  sourceName: string;
  displayedColumns: string[] = [];
  url: string;
  form?: FormGroup;
  controls: any;
  allControlMetaData: any;
  metaData: any;
  file: File;
  loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));   

  @ViewChild('fileUploader') fileUploader: ElementRef
  rolesNcontrols: RoleControls = new RoleControls();  
  constructor(private fb: FormBuilder,public dialogRef: MatDialogRef<ReprocessComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private dashboardService: DashboardService, private fileUploadService: FileUploadService, private spinnerService: SpinnerService, private route: ActivatedRoute, private router: Router, private toastr: ToastrService, private commonService: CommonService) {
   }

  ngOnInit(): void {
    this.headerText = this.data.control.ControlDisplayName;
    this.controlName = this.data.control.Control;    
    this.createForm();
    //this.getAllControlMetaData();
    this.getControlMetaData();
  }

  closeDialog() {
    this.dialogRef.close({ controlName: this.headerText, data: this.data.control });
  }
  
  onRunPeriodChanged(selectedRunTimestamp: string) {
    this.selectedRunTimestamp = selectedRunTimestamp;
  }

  getControlMetaData() {
    this.spinnerService.showSpinner();
    this.dashboardService.getControlMetaData(this.controlName).subscribe((result: any) => {
      this.metaData = result;
      if (this.metaData != undefined) {
        this.metaData.collectPhase.source.forEach(s => {
          this.form.addControl(s.sourceName, new FormControl(""));
        });
        if (this.metaData.collectPhase.source.length == 1) {
          this.form.controls['sourceName'].setValue(this.metaData.collectPhase.source[0].sourceName);                   
        }
        this.selectedSourceName = this.metaData.collectPhase.source[0].sourceName;
        this.refreshRunPeriod();
      }      
    });
    this.spinnerService.hideSpinner();
  }
  
  refreshRunPeriod() {
    this.spinnerService.showSpinner();
    this.dashboardService.getRunPeriods(this.controlName, this.selectedSourceName).subscribe(data => {
      this.runPeriods = data;
      this.spinnerService.hideSpinner();
    },
      (err: any) => {
        console.log("Error loading runperiod ", err);
        this.spinnerService.hideSpinner();
      }
    );
  }

  handleUpload(event, name) {
    this.file = event.target.files[0];
    this.form?.controls[name].setValidators([fileSizeValidator(this.file)]);
    this.form?.controls[name].setValue("");
    this.form?.controls["fileName"].setValue(this.file.name);
  }

  createForm() {
    this.form = this.fb.group({
      controlName: new FormControl("", [Validators.required]),
      sourceName: new FormControl("", [Validators.required]),
      fileName: new FormControl("", [Validators.required, requiredFileType(['xlsx', 'csv'])]),
    });
  }

  onSourceChange(SelectedSourceName: any) {
    this.selectedSourceName = SelectedSourceName;   
    this.refreshRunPeriod();
  }

  onSourceChangeEvent(selectedSource: string) {
    this.selectedSourceName = selectedSource;
  }
  
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

  isFieldValid(field: string) {
    return !this.form.get(field).valid && this.form.get(field).touched && this.form.get(field).enabled;
  }

  errorCount: number = 0;

  validateSelection(): number {
    this.errorCount = 0;
    if (this.selectedSourceName == "" || this.selectedSourceName == undefined) {
      this.toastr.error("Please select source");
      this.errorCount = 1;
    }
    else if (this.selectedRunTimestamp == "" || this.selectedRunTimestamp == undefined) {
      this.toastr.error("Please select runperiod");
      this.errorCount = 1;
    }
    return this.errorCount;
  }
  onSubmit() {
    var closeDialog = false;
    var isUploadSuccessful = false;
    var isReprocessSuccessful = false;
    let sourceName = this.form?.controls["sourceName"].value;
    let controlName = this.form?.controls["controlName"].value;
    let isValid = this.form?.controls["fileName"].valid;
    if (this.validateSelection() == 0 && (this.isUploadFileShow(this.controlName)? isValid:true)) {
      if (isValid) {
        this.spinnerService.showSpinner();
        let controlName = this.form?.controls["controlName"].value;
        //let file = this.form?.controls[sourceName].value;
        this.fileUploadService.uploadFiles(this.controlName, this.selectedSourceName, this.file).subscribe(res => {
          if (res.isValid == false) {
            if (res.errorMessage && res.errorMessage.length == 0) {
              this.toastr.error('An error occured while uploading file');
            }
            else {
              this.toastr.error(res.errorMessage);
            }
          }
          else {
            this.reset();
            isUploadSuccessful = true;
            this.toastr.success("Uploaded Successfully");
            /*this.isCloseDialog(isValid, isUploadSuccessful, isReprocessSuccessful);*/
            this.controlReprocess(isValid, isUploadSuccessful, isReprocessSuccessful);
          }
          this.spinnerService.hideSpinner();
        }),
          (e => {
            this.spinnerService.hideSpinner();
            this.toastr.error(e);
          });
      }
      else {
        this.validateAllFormFields(this.form);
        this.controlReprocess(isValid, isUploadSuccessful, isReprocessSuccessful);
      }
      
    }
    else {
      this.validateAllFormFields(this.form);
    }
  }

  controlReprocess(isValid: any, isUploadSuccessful: boolean, isReprocessSuccessful:boolean) {
    
    var reprocessAdditionalInfo = {
      sourceName: this.selectedSourceName,
      lastRunTimeStamp: this.selectedRunTimestamp,
      controlName: this.controlName,
      createdBy: this.loggedInUser.name,
      createdOn: new Date()
    }
    var index = this.data.controlList.findIndex(a => a.controlName == this.controlName);
    var selectedControl = this.data.controlList[index];
    if (index > -1) {
      selectedControl.shouldReprocess = true;
      selectedControl.reprocessAdditionalInfo = reprocessAdditionalInfo;
    }
    this.dashboardService.reprocess(reprocessAdditionalInfo).subscribe(res => {
      isReprocessSuccessful = true;
      this.toastr.success("Reprocessing has been requested successfully");
      this.data.control.ShouldReprocess = true;
      this.isCloseDialog(isValid, isUploadSuccessful, isReprocessSuccessful);
    });
  }


  isCloseDialog(isValid: boolean, isUploadSuccessful: boolean, isReprocessSuccessful: boolean) {
    if (((isValid && isUploadSuccessful) && isReprocessSuccessful) || (!isValid && isReprocessSuccessful)) {
      this.closeDialog();
    }
    
  }

  reset() {
    this.fileUploader.nativeElement.value = "";
    this.form?.controls["fileName"].setValue("");
  }

  hasAccess(groupName: string, securityAction: string): boolean {
    return true;
  }

  isUploadFileShow(controlName: string): boolean{
    return this.metaData != undefined ? this.metaData.collectPhase.source.filter(x => x.fileDatasource != null).length>0 : false;
  }
}


