export enum eventtemplate {
  formSubmission=3,
  reSubmission=4,
  userAccess = 5,
  userAccessModified = 6,
  filesUploaded = 7,
  readyReview = 8,
  userRemoved=9,
}

export const eventtemplate2LabelMapping = {
  [eventtemplate.formSubmission]: "Form Submission",
  [eventtemplate.reSubmission]: "Re-Submission",
  [eventtemplate.userAccess]: "New User Added",
  [eventtemplate.userAccessModified]: "Role Access Modified",
  [eventtemplate.filesUploaded]: "Integration File Upload",
  [eventtemplate.readyReview]: "Approved By Admin",
  [eventtemplate.userRemoved]: "User Removed",
}


