
import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ConfigService{
  //securityAction: any;
  //loggedInUser: any;
  private settings: any;
  private http: HttpClient;
  constructor(private readonly httpHandler: HttpBackend) {
    this.http = new HttpClient(httpHandler);
  }

   init(endpoint: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.http.get(endpoint).pipe(map(res => res))
        .subscribe(value => {
          this.settings = value;
          resolve(true);
        },
          (error) => {
            reject(error);
          });
    });
  }

  getSettings(key?: string | Array<string>): any {
    if (!key || (Array.isArray(key) && !key[0])) {
      return this.settings;
    }

    if (!Array.isArray(key)) {
      key = key.split('.');
    }

    let result = key.reduce((acc: any, current: string) => acc && acc[current], this.settings);

    return result;
  }

  //getLoggedInUser() {
  //  this.loggedInUser = this.authService.getAccount();
  //  console.log(this.loggedInUser)
  //  this.getAllSecurityAction(this.loggedInUser.userName.toLowerCase()).subscribe(
  //    (result: any) => {
  //      localStorage.setItem("SecurityAction", JSON.stringify(result));
  //    });
  //} 

  //getAllSecurityAction(alias: string) {
  //  return this.get("/api/UserManagement/User/" + alias);
  //};
}
