<div class="container main_container">
  <div class="header_second full_width">
    <div class="left">
      <h1>
        Trade Compliance and Technology Monitoring Dashboard
      </h1>
    </div>
    <div class="right">
      <button type="button" style="color:#000 !important" class="btn btn-primary btns_download" (click)="downloadDashboardControls()" *ngIf="hasAccess('general','dashboard/report/download')"><b>Download Report</b></button>
    </div>
  </div>

  <div class="full_width dashboard_grid">

    <div class="mat-elevation-z8 full_width margintop-20 grid_list">
      <mat-paginator [pageSizeOptions]="[4,6,8,16]" [pageSize]="8"></mat-paginator>
      <div class="table-responsive dashboard_list">
        <table class="responsive-table" mat-table [dataSource]="ControlsDataSource" matSort>

          <!-- ID Column -->
          <ng-container matColumnDef="ID">
            <th mat-header-cell *matHeaderCellDef class="d_col-1">
              <span>ID<span class="dds-icon_sm dds-icon_sort-down sort-down_ico" mat-sort-header></span></span>

              <input (keyup)="applyFilter($event)" placeholder="Search" class="form-control" (focus)="setupFilter('ID')" #userName />
            </th>
            <td mat-cell *matCellDef="let row">{{row.ID}} </td>
          </ng-container>

          <!-- Control Column -->
          <ng-container matColumnDef="ControlDisplayName">
            <th mat-header-cell *matHeaderCellDef>
              <span>Control <span class="dds-icon_sm dds-icon_sort-down sort-down_ico" mat-sort-header></span></span>
              <input (keyup)="applyFilter($event)" placeholder="Search" class="form-control" (focus)="setupFilter('ControlDisplayName')" />
            </th>

            <td mat-cell *matCellDef="let row"><a (click)="onControlClick(row)" [routerLink]="row.link">{{row.ControlDisplayName}} </a></td>
          </ng-container>

          <!-- Frecuency Column -->
          <ng-container matColumnDef="Frequency">
            <th mat-header-cell *matHeaderCellDef>
              <span>Frequency <span class="dds-icon_sm dds-icon_sort-down sort-down_ico" mat-sort-header></span></span>
              <input (keyup)="applyFilter($event)" placeholder="Search" class="form-control" (focus)="setupFilter('Frequency')" />
            </th>
            <td mat-cell *matCellDef="let row"> {{row.Frequency}} </td>
          </ng-container>

          <!-- Last scan Column -->
          <ng-container matColumnDef="LastUpdate">
            <th mat-header-cell *matHeaderCellDef class="d_col-4">
              <span>Last Update <span class="dds-icon_sm dds-icon_sort-down sort-down_ico" mat-sort-header></span></span>
              <input (keyup)="applyFilter($event)" placeholder="Search" class="form-control" (focus)="setupFilter('LastUpdate')" />
            </th>
            <td mat-cell *matCellDef="let row"> {{row.LastUpdate| date:'MM-dd-yyyy'}} </td>
          </ng-container>

          <!-- Next scan Column -->
          <ng-container matColumnDef="NextUpdate">
            <th mat-header-cell *matHeaderCellDef class="d_col-4">
              <span>Next Update <span class="dds-icon_sm dds-icon_sort-down sort-down_ico" mat-sort-header></span></span>
              <input (keyup)="applyFilter($event)" placeholder="Search" class="form-control" (focus)="setupFilter('NextUpdate')" />
            </th>
            <td mat-cell *matCellDef="let row"> {{row.NextUpdate| date:'MM-dd-yyyy'}} </td>
          </ng-container>

          <!-- Compliance status Column -->
          <ng-container matColumnDef="ComplianceStatus">
            <th mat-header-cell *matHeaderCellDef class="d_col-5">
              <span>Compliance Status <span class="dds-icon_sm dds-icon_sort-down sort-down_ico" mat-sort-header></span></span>
              <input (keyup)="applyFilter($event)" placeholder="Search" class="form-control" (focus)="setupFilter('ComplianceStatus')" />
            </th>
            <td mat-cell *matCellDef="let row"> {{row.ComplianceStatus}} </td>
          </ng-container>
          <!-- Attestation status Column -->
          <ng-container matColumnDef="AttestationStatus">
            <th mat-header-cell *matHeaderCellDef class="d_col-5">
              <span>Attestation Status <span class="dds-icon_sm dds-icon_sort-down sort-down_ico" mat-sort-header></span></span>
              <input (keyup)="applyFilter($event)" placeholder="Search" class="form-control" (focus)="setupFilter('AttestationStatus')" />
            </th>
            <td mat-cell *matCellDef="let row">
              <div class="left">
                {{row.AttestationStatus}}
              </div>
              <div class="right"><span class="dds-icon_sm" [ngClass]="attestationStatusIcons(row.AttestationStatus)"></span></div>
            </td>
          </ng-container>


          <ng-container matColumnDef="ShouldReprocess">
            <th mat-header-cell *matHeaderCellDef class="d_col-4" align="center">
              <span>Reprocess<span class="dds-icon_sm dds-icon_sort-down sort-down_ico" mat-sort-header></span></span>
              <input (keyup)="applyFilter($event)" placeholder="Search" class="form-control" (focus)="setupFilter('ShouldReprocess')" />
            </th>
            <td mat-cell *matCellDef="let row" align="center">
              <span style="padding:5px;" class="dds-icon_sm dds-icon_arrow-round-right" [ngClass]="isReprocessDisable(row.ControlDisplayName)" (click)="openDialog(row)" *ngIf="isIntegrationControl(row.ControlType)"></span>
              <span *ngIf="!isIntegrationControl(row.ControlType)">N/A</span>
            </td>
          </ng-container>


          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="7">No data matching the filter</td>
          </tr>
        </table>
      </div>
    </div>
  </div>

</div>

<script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" type="text/javascript" charset="UTF-8"
        data-domain-script="7b5e5099-751a-4f1a-b1e7-d354d1edc3eb-test"></script>

<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>

<script>
  function OptanonWrapper() {
    setTimeout(() => {
    }, "1000");
  }
</script>


