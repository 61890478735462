import { formatDate } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Toast, ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { last, map, startWith } from 'rxjs/operators';
import { ControlData } from '../dashboard/models/control-data';
import { DashboardService } from '../dashboard/services/dashboard.service';
import { CommonService } from '../shared/common-services/common.service';
import { SpinnerService } from '../shared/spinner/spinner.service';
import { UserManagementService } from '../user-management/services/user-management.service';
import { controlmetadata } from './models/control-metadata';
import { ManagecontrolmatadataService } from './services/managecontrolmatadata.service';
import { WarningcomponentComponent } from '../warningcomponent/warningcomponent.component';
import { FormControl } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';



declare var _satellite: any;
declare var analyticsDataLayer: any;

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'MM-DD-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-manage-controlmatadata',
  templateUrl: './manage-controlmatadata.component.html',
  styleUrls: ['./manage-controlmatadata.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class ManageControlmatadataComponent implements OnInit {
  originalData: any;  
  controlList: ControlData[] = [];
  displayedColumns: string[] = ['ControlDisplayName', 'FrequencyChangeFrom', 'FrequencyChangeTo','NextUpdateddate', 'RevisedNextUpdatedDate', 'POC', 'UpdatedOn', 'UpdatedBy'];
  dataSource: MatTableDataSource<ControlData>;
  ControlsDataSource: MatTableDataSource<ControlData>;
  updateControldata: controlmetadata={
    controlId: "",
    orderId: 0,
    controlDisplayName: "",
    controlName: "",
    controlDescription: "",
    frequency: "",
    frequencyDisplayName: "",
    lastUpdate: "",
    nextUpdate: "",
    complianceStatus: "",
    attestationStatus: "",
    controlType: "",
    lastRunAdditionalInfo: [],
    shouldProcess: true,
    shouldReprocess: true,
    status: "",
    reprocessAdditionalInfo: {
      controlName: "",
      sourceName: "",
      lastRunTimeStamp: "",
      createdOn: new Date,
      createdBy: "",
      status: "",
    },
    previousFrequency: "",
    previousNextUpdate: "",
    pointofContact: "",
    updatedBy: "",
    updatedOn: "",
  }
  loggedInUser: any;
  selectControl: "0";
  date: Date;
  pointOfContact: string = "";
  selectedFrequecy: string = "0";
  selectedNextUpdatedDate: string = "";
  usersList: any;
  contributorList: any = [];
  selectedFrequencyDisplayName: string = "";
  selectedControlDetail: any;
  warningStatus: boolean = true;
  stateChange: string = "refresh";


  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  toEmailCtrl = new FormControl();
  toEmailFiltered: Observable<string[]>;
  toUserValues: string[] = [];
  autocompleteValues: Array<any> = [];
  pageNumber: number = 1;
  isShowmoreButton: boolean = true;
  searchstring: any = '';
  paginatorLink: string;
  loadUser: any;
  tempUserList: Array<any> = [];
  userList: any;
  isNoUserFound: boolean;
  
 

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('toEmailInput') toEmailInput: ElementRef<HTMLInputElement>;

  constructor(public dialog: MatDialog, private dashboardService: DashboardService,  private spinnerService: SpinnerService, private commonService: CommonService, private toastr: ToastrService, private managecontrolmatadata: ManagecontrolmatadataService, private usermanagemetservice: UserManagementService) {
    
  }

  ngOnInit(): void {
    this.getSecurityActions();
    this.loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    this.selectControl = "0";
    this.usermanagemetservice.getAllUsers().subscribe(users => {
      this.usersList = users;
      this.usersList.forEach((userlst: any) => {
        userlst.roles.forEach((role: any) => {
          //if (role.roleName == "Contributor") {
          //  this.contributorList.push(userlst.userAttributes.emailAddress);
          //  this.pointOfContact += userlst.userAttributes.emailAddress + "; ";
          //  this.toUserValues.push(userlst.userAttributes.emailAddress);            
          //}
        });
      });
      console.log(this.autocompleteValues);
      console.log(this.toEmailFiltered);
    });
    
    
  }


  addTo(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our toEmail
    if (value) {
      this.autocompleteValues.push(value);
    }

    // Clear the input value
    //event.chipInput!.clear();

    this.toEmailCtrl.setValue(null);
  }

  removeTo(toEmail: string): void {
    const index = this.toUserValues.indexOf(toEmail);

    if (index >= 0) {
      this.toUserValues.splice(index, 1);
    }
  }

  isShowmore() {
    this.pageNumber = this.pageNumber + 1;
    this.isShowmoreButton = true;
    this.spinnerService.showSpinner();
    this.commonService.getUserByTerm(10, this.searchstring, this.paginatorLink).subscribe((result: any) => {
      if (result != undefined || result != null) {
        this.loadUser = result;
        if (this.loadUser != undefined && this.loadUser.value.length < 10) {
          this.isShowmoreButton = false;
        }
        this.loadUser.value.forEach((element: any) => {
          this.tempUserList.push({ userName: element.displayName, emailAddress: element.mail, alias: element.mail });
        });
      }
      this.paginatorLink = this.loadUser["@odata.nextLink"];
      this.autocompleteValues = this.tempUserList;
      this.spinnerService.hideSpinner();
      // result.loadUser = null;
    });
  }

  onInputChanged(event: any) {

    this.autocompleteValues = [];
    
    let emailaddrvalue = (event.target as HTMLInputElement).value;
    if (emailaddrvalue.length >= 4) {
      this.isShowmoreButton = true;
      this.autocompleteValues = [];
      this.spinnerService.showSpinner();
      setTimeout(() => {
        this.commonService.getUserByTerm(10, emailaddrvalue, null).subscribe((result: any) => {
          if (result != undefined || result != null) {
            this.userList = result;
            if (emailaddrvalue != this.searchstring) {
              this.tempUserList = [];
              this.userList.value.forEach((element: any) => {
                if (element != null) {
                  if (!(element.officeLocation! = null)) {
                    if ((element.userPrincipalName.toLowerCase().indexOf('@deloitte.com') > -1) && (element.userPrincipalName.toLowerCase().indexOf('usa-') != 0)) {
                      this.tempUserList.push({ displayname: element.displayName, displayaddress: element.userPrincipalName != null ? element.userPrincipalName.toLowerCase() : null, alias: element.userPrincipalName != null ? element.userPrincipalName.toLowerCase() : null });
                    }
                  }
                }
              });
              this.isNoUserFound = false;
            }
            this.paginatorLink = this.userList["@odata.nextLink"];
          }
          if (this.userList != undefined && this.userList.value.length < 10) {
            this.isShowmoreButton = false;
          }
          if (this.userList != undefined && this.userList.value.length == 0) {
            this.isNoUserFound = true;
          }
          this.searchstring = (event.target as HTMLInputElement).value;
          this.autocompleteValues = this.tempUserList;
          this.toEmailFiltered = this.toEmailCtrl.valueChanges.pipe(
            startWith(null),
            map((toEmail: string | null) => toEmail ? this._filterTo(toEmail) : this.autocompleteValues.slice()));
          this.spinnerService.hideSpinner();

        });
      }, 300);
    }
    else if ((event.target as HTMLInputElement).value.length == 0) {
      this.autocompleteValues = [];
      this.spinnerService.hideSpinner();
    }
    else {
      this.autocompleteValues = [];
      this.spinnerService.hideSpinner();
    }
  }

  private _filterTo(value: string): string[] {
    const toEmailFilterValue = value.toLowerCase();

    return this.toUserValues.filter(toEmail => toEmail.toLowerCase().includes(toEmailFilterValue));
  }

  selectedTo(event: MatAutocompleteSelectedEvent): void {
    let selectedValues = event.option.viewValue.split(" ");
    let selectedEmail = selectedValues[selectedValues.length - 1];
    let selectedValue = "";
    if (selectedEmail.includes("@"))
      selectedValue = selectedEmail;
    else
      selectedValue = "##" + selectedEmail + "##";
    this.toUserValues.push(selectedValue);
    this.toEmailInput.nativeElement.value = '';
    this.toEmailCtrl.setValue(null);
  }

  

  groupNames = [];
  getSecurityActions() {
    this.spinnerService.showSpinner();
    this.controlList = [];
    this.ControlsDataSource = new MatTableDataSource(this.controlList);
    setTimeout(() => this.ControlsDataSource.paginator = this.paginator);
    setTimeout(() => this.ControlsDataSource.sort = this.sort);

    var securityAction = JSON.parse(localStorage.getItem("SecurityAction"));
    if (securityAction != null || securityAction != undefined) {
      this.getGroupNames(securityAction);
      this.getAllDahboardControl(this.groupNames);
    }
    else {
      var loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
      this.commonService.getAllSecurityAction(loggedInUser.userName.toLowerCase()).subscribe((result: any) => {
        localStorage.setItem("SecurityAction", JSON.stringify(result));
        this.getGroupNames(result);
        this.getAllDahboardControl(this.groupNames);
      });
    }

  }

  getGroupNames(securityActions: any): any {
    securityActions.roles.forEach((element: any) => {
      element.allowedSecurityGroups.forEach((security: any) => {
        if (security.groupName != null && security.groupName != 'general') {
          if (!(this.groupNames.indexOf(security.groupName) > -1))
            this.groupNames.push(security.groupName);
        }
      });
    });
  }

  getAllDahboardControl(groupNames: any) {
    this.dashboardService.getAllDashboardControls(groupNames).subscribe((result: any) => {
      if (result != null) {
        this.originalData = result;
        of((result.sort((a, b) => a.orderId - b.orderId))).subscribe(sortedData => {
          result = sortedData;
        });
        for (var i = 0; i < result.length; i++) {
          let attestationStatus = "";
          let complianceStatus = "";
          if (result[i].controlType == "manual") {            
            if (!result[i].attestationStatus || result[i].attestationStatus.length == 0) {
              let lastUpdatedDate = new Date(result[i].lastUpdate);
              let overDueDays = Math.ceil((new Date().getTime() - lastUpdatedDate.getTime()) / (1000 * 60 * 60 * 24));
              if (overDueDays > 7) {
                attestationStatus = "Overdue";
              }
            }
            else {
              attestationStatus = this.commonService.getAttestationStatus(parseInt(result[i].attestationStatus), result[i].controlName)
            }
            complianceStatus = "N/A";
          }
          else {
            attestationStatus = "N/A";
            complianceStatus = result[i].complianceStatus;
          }
          this.controlList.push({
            ID: result[i].controlId,
            ControlDisplayName: result[i].controlDisplayName,
            Control: result[i].controlName,
            Frequency: result[i].frequencyDisplayName,
            LastUpdate: formatDate(result[i].lastUpdate, "MM-dd-yyyy", 'en-US'),
            NextUpdate: formatDate(result[i].nextUpdate, "MM-dd-yyyy", 'en-US'),
            ComplianceStatus: complianceStatus,
            AttestationStatus: attestationStatus,
            link: this.getLink(result[i]),
            ControlType: result[i].controlType,
            LastRunTimestamps: result[i].lastRunTimestamps,
            ShouldReprocess: result[i].shouldReprocess,
            PreviousFrequency: result[i].previousFrequency,
            PreviousNextUpdate: result[i].previousNextUpdate,
            PointofContact: result[i].pointofContact,
            UpdatedBy: result[i].updatedBy,
            UpdatedOn: result[i].updatedOn
          });

          this.commonService.setData(result[i].controlName, result[i].lastRunAdditionalInfo);
          this.commonService.setData(result[i].controlName + "displayName", result[i].controlDisplayName);

          this.ControlsDataSource = new MatTableDataSource(this.controlList);
          setTimeout(() => this.ControlsDataSource.paginator = this.paginator);
          setTimeout(() => this.ControlsDataSource.sort = this.sort);
        }
      }
      this.spinnerService.hideSpinner();
    });
  }

  getLink(control: any) {
    var url = "";
    if (control.lastRunAdditionalInfo == null || control.lastRunAdditionalInfo.length == 0) {
      return url;
    }
    var runTimestamp = control.lastRunAdditionalInfo[0].lastRunTimeStamp;
    var sourceName = control.lastRunAdditionalInfo[0].sourceName;
    if (control.controlType.toLowerCase() == "integration") {
      url = '/dashboarddetails/' + control.controlName + '/' + runTimestamp + '/' + sourceName
    }
    else {
      url = '/attestation/' + control.controlName + '/' + runTimestamp
    }

    return url;
  }

  
  SaveDeshboardControl() {
    this.pointOfContact = "";
    this.toUserValues.forEach((val) => {
      this.pointOfContact += val + "; ";      
    })
    if (this.ValidateControl()) {
      this.updateControldata = this.originalData.filter(x => x.controlId == this.selectControl)[0];
      this.updateControldata.updatedBy = this.loggedInUser.name;
      this.updateControldata.updatedOn = formatDate(new Date(), "MM-dd-yyyy", 'en-US');
      this.updateControldata.previousNextUpdate = this.updateControldata.nextUpdate;
      this.updateControldata.nextUpdate = formatDate(this.date, "MM-dd-yyyy", 'en-US');
      this.updateControldata.previousFrequency = this.updateControldata.frequency;
      this.updateControldata.frequency = this.selectedFrequencyDisplayName;
      this.updateControldata.frequencyDisplayName = this.selectedFrequencyDisplayName;
      this.updateControldata.pointofContact = this.pointOfContact;

      this.managecontrolmatadata.UpdateDeshboardControl(this.updateControldata).subscribe(data => {
        this.toastr.success("Changes have been made successfully.");
        setInterval(() => {
          window.location.reload();
        }, 5000);
      })
    }    
  }

  ValidateControl() {
    let validateResult = true;
    if (this.selectControl == "0") {
      validateResult = false;
    }
    if (this.selectedFrequecy == "0") {
      validateResult = false;
    }
    if (validateResult == false) {
      this.toastr.error("Please enter the mandatory fields");
    }
    return validateResult;
  }

  SelectControlName(data) {
    this.toUserValues = [];
    var For_split_email: Array<string>;
    this.selectControl = data;
    this.selectedControlDetail = this.controlList.filter(x => x.ID == data)[0];
    this.selectedNextUpdatedDate = formatDate(this.selectedControlDetail.NextUpdate, "MM-dd-yyyy", 'en-US');
    this.selectedFrequecy = this.SelectFrequencyByDispalayName(this.selectedControlDetail.Frequency);
    this.SelectFrequencyByCode(this.selectedFrequecy);    
     For_split_email = this.selectedControlDetail.PointofContact.split(';');
    for (var i = 0; i < For_split_email.length-1; i++) {
      this.toUserValues.push(For_split_email[i]);
    }
    
    this.date = this.SetupRevisedNextUpdatedDate(this.selectedFrequecy, this.selectedControlDetail.LastUpdate);
    //this.ValidateDate();
  }

  ValidateDate() {
    let warningmsg = "Revised Next Update Date is lesser than the Next update Date. The new Frequency changes will reflect after the current Time Frame ends.Are you sure you want to Continue?";
    let Headermsg = "Date Warning";
    if (this.date < new Date(this.selectedControlDetail.NextUpdate))
      this.openDialog(Headermsg, warningmsg);
    

  }

  SetupRevisedNextUpdatedDate(f, lastupdatedate) {
    let numberToBeAdded = 0;
    let categoryToBeAdded = "";
    let returnDate: Date;
    let CD = new Date(lastupdatedate);
    if (f == "D") {      
      returnDate = new Date(CD.setDate(CD.getDate()+1));
    } else if (f == "W") {
      returnDate = new Date(CD.setDate(CD.getDate()+7));
    } else if (f == "M") {
      returnDate = new Date(CD.setMonth(CD.getMonth()+1));
    } else if (f == "Q") {
      returnDate = new Date(CD.setMonth(CD.getMonth() + 3));
    } else if (f == "S") {
      returnDate = new Date(CD.setMonth(CD.getMonth() + 6));
    } else if (f == "A") {
      returnDate = new Date(CD.setFullYear(CD.getFullYear() + 1));
    } else {
      returnDate = new Date(lastupdatedate);
    }
    return returnDate;
  }

  SelectFrquency(Freq) {
    this.date = this.SetupRevisedNextUpdatedDate(Freq, this.selectedControlDetail.LastUpdate);
    this.ValidateDate();
    if (this.selectControl == "0") {
      this.toastr.error("Please select the Control Name");
      this.selectedFrequecy = "0";
    }      
    else {
      this.selectedFrequecy = Freq;
      this.SelectFrequencyByCode(Freq);
    }
  }

  SelectFrequencyByCode(code) {    
    switch (code) {
      case "D": {
        this.selectedFrequencyDisplayName = "Daily";
        break;
      }
      case "W": {
        this.selectedFrequencyDisplayName = "Weekly";
        break;
      }
      case "M": {
        this.selectedFrequencyDisplayName = "Monthly";
        break;
      }
      case "Q": {
        this.selectedFrequencyDisplayName = "Quarterly";
        break;
      }
      case "S": {
        this.selectedFrequencyDisplayName = "Semi-Annual";
        break;
      }
      case "A": {
        this.selectedFrequencyDisplayName = "Annual";
        break;
      }
      default: {
        this.selectedFrequencyDisplayName = "Select";
        break;
      }
    }

  }

  SelectFrequencyByDispalayName(freqDisplay) {
    switch (freqDisplay.toLowerCase()) {
      case "daily": {
        this.selectedFrequecy = "D";
        break;
      }
      case "weekly": {
        this.selectedFrequecy = "W";
        break;
      }
      case "monthly": {
        this.selectedFrequecy = "M";
        break;
      }
      case "quarterly": {
        this.selectedFrequecy = "Q";
        break;
      }
      case "semi-annual": {
        this.selectedFrequecy = "S";
        break;
      }
      case "annual": {
        this.selectedFrequecy = "A";
        break;
      }
      case "yearly": {
        this.selectedFrequecy = "A";
        break;
      }
      case "semiannually": {
        this.selectedFrequecy = "S";
        break;
      }
      default: {
        this.selectedFrequecy = "0";
        break;
      }
    }
    return this.selectedFrequecy;
  }

  updateCalcs() {
    this.ValidateDate();
  }

  openDialog(text,displaymsg): void {
    const dialogRef = this.dialog.open(WarningcomponentComponent, {
      data: { headerText: text, msg: displaymsg}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null || result != undefined) {
        this.warningStatus = result;
      }
      if (this.warningStatus == false) {        
        this.date = this.SetupRevisedNextUpdatedDate("D", this.selectedControlDetail.NextUpdate);
      }
    });
    
  }

 
  
}
