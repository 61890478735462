import { InjectionToken, NgModule, APP_INITIALIZER } from '@angular/core';
import {
    MSAL_CONFIG,
    MSAL_CONFIG_ANGULAR,
    MsalAngularConfiguration
    , MsalService, MsalModule, MsalInterceptor
  } from '@azure/msal-angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ConfigService } from './shared/common-services/config.service';
import { Configuration } from 'msal';

const AUTH_CONFIG_URL_TOKEN = new InjectionToken<string>('AUTH_CONFIG_URL');
const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;
export function initializerFactory(env: ConfigService, configUrl: string): any {
    // APP_INITIALIZER, except a function return which will return a promise
    // APP_INITIALIZER, angular doesnt starts application untill it completes
    const promise = env.init(configUrl).then((value) => {
    });
    return () => promise;
}

export function msalConfigFactory(config: ConfigService): Configuration {  
    const auth = {
      auth: {
        clientId: config.getSettings('clientId'),
        authority: config.getSettings('authority'),
        redirectUri: config.getSettings('redirectUri')
        },
        cache: {
            cacheLocation: 'localStorage',
            storeAuthStateInCookie: false,
        },
        system:{
            loadFrameTimeout: 15000
        },
        framework: {
            unprotectedResources: ["https://www.microsoft.com/en-us/"],
            protectedResourceMap: new Map(config.getSettings('protectedResourceMap'))
        }
    };
    return (auth as Configuration);
}

export function msalAngularConfigFactory(config: ConfigService): MsalAngularConfiguration {
  const auth = {
    popUp: false,
                consentScopes: config.getSettings('apiconsent'),
                extraQueryParameters: {}
    };
    return (auth as MsalAngularConfiguration);
}

@NgModule({
    providers: [
    ],
    imports: [MsalModule]
})
export class MsalApplicationModule {

    static forRoot(configFile: string) {
        return {
            ngModule: MsalApplicationModule,
            providers: [
                ConfigService,
                { provide: AUTH_CONFIG_URL_TOKEN, useValue: configFile },
                { provide: APP_INITIALIZER, useFactory: initializerFactory,
                     deps: [ConfigService, AUTH_CONFIG_URL_TOKEN], multi: true },
                {
                    provide: MSAL_CONFIG,
                    useFactory: msalConfigFactory,
                    deps: [ConfigService]
                },
                {
                    provide: MSAL_CONFIG_ANGULAR,
                    useFactory: msalAngularConfigFactory,
                    deps: [ConfigService]
                },
                MsalService,
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: MsalInterceptor,
                    multi: true
                }
            ]
        };
    }
}
