import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { MasterData, AttestationStatus, AttestationStatusText } from '../../dynamic-form-components/models/question-base';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { ConfigService } from './config.service';

@Injectable()
export class CommonService extends ApiService {
  userList: any;
  profile: any;
  securityAction: any;
  loggedInUser: any;
  requestObj: any = {
    scopes: ["User.ReadBasic.All"]
  }
  bearerToken: any = '';
  finalQuery: string;
  controls: Array<any> = [];
  roles: Array<any> = [];
  controlsToDisplay: Array<any> = [];
  constructor(
    protected httpClient: HttpClient, protected config: ConfigService, protected router: Router, protected authService: MsalService, private configService: ConfigService
  ) {

    super(router, httpClient, config, authService);   

  }
  ngOnInit() {
  }

  //Setting the user list
  setData(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  //Getting the user list
  getData(key: string) {
    var result = localStorage.getItem(key);
    if (result != null && result != "") {
      return JSON.parse(result);
    }
    return null;
  }

  getRoles() {
    return this.get("/api/UserManagement/GetRoleSecurityAction").subscribe((result: any) => {
      if (result != null) {
        result.forEach((element: {
          roleName: any;
        }) => {
          this.roles.push(element.roleName);
        });
      }
      var roles = JSON.parse(sessionStorage.getItem("Roles"));
      if (roles == null || roles == undefined) {
        sessionStorage.setItem("Roles", JSON.stringify(this.roles));
      }
    });
  }

  getControls() {
    return this.get("/api/Dashboard").subscribe((result: any) => {
      if (result != null) {
        result.forEach((element: any) => {
          this.controls.push(element.controlName);
          this.controlsToDisplay.push({ controlName: element.controlName, controlDisplayName: element.controlDisplayName });
        });
      }
      var controls = JSON.parse(sessionStorage.getItem("Controls"));
      if (controls == null || controls == undefined) {
        sessionStorage.setItem("Controls", JSON.stringify(this.controls));
      }
      var controlsToDisplay = JSON.parse(sessionStorage.getItem("controlsToDisplay"));
      if (controlsToDisplay == null || controlsToDisplay == undefined) {
        sessionStorage.setItem("controlsToDisplay", JSON.stringify(this.controlsToDisplay));
      }
      
   
    });
  }
  getMasterData(type: string[]): Observable<any> {
    let url = "/api/MasterData/GetMasterDataByTypes";
    return this.post<any>(url, type);
  }

  saveMasterData(masterData: MasterData[]): Observable<any> {
    let url = "/api/MasterData/AddMasterData";
    return this.post<any>(url, masterData);
  }
  getAttestationStatus(status: number, controlName: string): string {
    if (status == AttestationStatus.SentForReview && this.hasAccess(controlName, 'review'))
      return "Pending Review";
    else if (status == AttestationStatus.SentForRevision && this.getRoleNames(controlName).indexOf("Contributor") != -1) {
      return "Revision Pending";
    }
    else
      return AttestationStatusText.get(status);
  }
  getLoggedInUser() {
    this.loggedInUser = this.authService.getAccount();
    console.log(this.loggedInUser)

  }

  getAllSecurityAction(alias: string): Observable<any> {
    return this.get("/api/UserManagement/User/" + alias);
  };


  getProfile(GRAPH_ENDPOINT: string, bearerToken: string): any {
    this.httpClient.get(GRAPH_ENDPOINT, { headers: { 'Authorization': bearerToken } })
      .subscribe({
        next: (profile) => {
          this.profile = profile;
        }
        //,
        //error: (err: AuthError) => {
        //  this.authService.acquireTokenPopup({
        //    scopes: this.authService.getScopesForEndpoint(GRAPH_ENDPOINT)
        //  })
        //    .then(() => {
        //      this.httpClient.get(GRAPH_ENDPOINT, { headers: { 'Authorization': bearerToken } })
        //        .toPromise()
        //        .then(profile => {
        //          this.profile = profile;
        //        });
        //    });
        //}
      });
    return this.profile;
  }

  hasAccess(groupName: string, securityAction: string): boolean {
    var hasAccess: boolean = false;
    var securityActions = JSON.parse(localStorage.getItem("SecurityAction"));   
    if ( securityActions != null || securityActions != undefined) {
      securityActions.roles.forEach((element: any) => {
        element.allowedSecurityGroups.filter(item => item.groupName == groupName).forEach((security: any) => {
          if (hasAccess == false)
            hasAccess = security.securityActions.filter(x => x.actionName == securityAction).length > 0;
        });
        if (hasAccess == true) {
          return;
        }
      });
    }
    else {
     
      var securityActions = JSON.parse(localStorage.getItem("SecurityAction"));
      if (securityActions != null || securityActions != undefined) {
        var loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        this.getAllSecurityAction(loggedInUser.userName.toLowerCase()).subscribe((result: any) => {
          console.log("asdf");
          localStorage.removeItem("SecurityAction");
          localStorage.setItem("SecurityAction", JSON.stringify(result));
          result.roles.forEach((element: any) => {
            element.allowedSecurityGroups.filter(item => item.groupName == groupName).forEach((security: any) => {
              if (hasAccess == false)
                hasAccess = security.securityActions.filter(x => x.actionName == securityAction).length > 0;
            });
            if (hasAccess == true) {
              return;
            }
          });
        });
      }
    }
    return hasAccess;
  }

  getRoleNames(groupName: string): string[] {
    var roleNames: string[] = new Array<string>();
    this.securityAction = JSON.parse(localStorage.getItem("SecurityAction"));
    if (this.securityAction) {
      this.securityAction.roles.forEach((element: any) => {
        if (element.allowedSecurityGroups.filter(item => item.groupName == groupName).length > 0) {
          roleNames.push(element.roleName);
        }
      });
    }
    if (roleNames.length == 0) {
      roleNames.push("View Only");
    }
    return roleNames;
  }

  getUserByTerm(pageSize: number, searchTerm: string, paginatorLink: string): Observable<any> {
    var graphUri = "https://graph.microsoft.com/v1.0/users?"
    var pageSizeQuery = "$top=" + pageSize;
    var searchEmailQuery = "$filter=startswith(mail,'" + searchTerm + "')";
    var searchSurnameQuery = "startswith(surname, '" + searchTerm + "')";
    var searchFirstNameQuery = "startswith(givenName,'" + searchTerm + "')";
    if (paginatorLink == null || paginatorLink == undefined) {
      this.finalQuery = graphUri + '' + pageSizeQuery + '&' + searchEmailQuery + ' or ' + searchFirstNameQuery + ' or ' + searchSurnameQuery;
    }
    else {
      this.finalQuery = paginatorLink;
    }

    return this.getGraph(this.finalQuery);

    //from(this.authService.acquireTokenSilent(this.requestObj).then((tokenResponse) => {
    //  this.bearerToken = 'Bearer ' + tokenResponse.accessToken;
    //  return this.httpClient.get(this.finalQuery, { headers: { 'Authorization': this.bearerToken } })
    //    .toPromise()
    //    .then(profile => {
    //      return this.profile = profile;
    //    });
    //}))
  }
  convertBase64ToBlobData(base64Data: string, contentType: string, sliceSize = 512) {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
}
