import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DynamicFormComponent } from './dynamic-form-components/dynamic-form.component';
import { CreateUserComponent } from './create-user/create-user.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';
import { ManageAttestationComponent } from './manage-attestation/manage-attestation.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { MsalGuard } from '@azure/msal-angular'
import { DashboardDetailsComponent } from './dashboard-details/dashboard-details.component';
import { PersmissionRouteGuard } from './guard/persmission-route-guard.service';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { ApiResolver } from './shared/common-services/resolver.service';
import { LoginComponent } from './login/login.component';
import { UserRoleManagementComponent } from './user-role-management/user-role-management.component';
import { ManageEntityComponent } from './manage-entity/manage-entity.component';
import { ManageQuestionnaireComponent } from './manage-questionnaire/manage-questionnaire.component';
import { EmailtemplateComponent } from './emailtemplate/emailtemplate.component';
import { NotificationDashboardComponent } from './notification-dashboard/notification-dashboard.component';
import { ManageControlmatadataComponent } from './manage-controlmatadata/manage-controlmatadata.component';

const routes: Routes = [
  {
    path: '', children: [    
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      { path: 'home', component: HomeComponent, canActivate: [MsalGuard, PersmissionRouteGuard] },
      { path: 'dashboard', canActivate: [MsalGuard, PersmissionRouteGuard], component: DashboardComponent },
      //{ path: 'manageattestation',  canActivate: [MsalGuard, PersmissionRouteGuard], component: ManageAttestationComponent },
      { path: 'attestation/:controlName/:timeStamp',canActivate: [MsalGuard, PersmissionRouteGuard], component: DynamicFormComponent },
      { path: 'UserManagement' ,component: UserManagementComponent, canActivate: [MsalGuard, PersmissionRouteGuard] },
      { path: 'common/fileupload', component: FileUploadComponent, canActivate: [MsalGuard, PersmissionRouteGuard] },
      { path: 'UserRoleManagement', component: UserRoleManagementComponent, canActivate: [MsalGuard, PersmissionRouteGuard]},
      { path: 'ManageQuestionnaire', component: ManageQuestionnaireComponent, canActivate: [MsalGuard, PersmissionRouteGuard] },
      { path: 'ManageBannedEntity', component: ManageEntityComponent, canActivate: [MsalGuard, PersmissionRouteGuard]},
      { path: 'emailtemplate', component: EmailtemplateComponent },
      { path: 'emailtemplate/:templateid', component: EmailtemplateComponent},
      { path: 'NotificationDashboard', component: NotificationDashboardComponent, canActivate: [MsalGuard, PersmissionRouteGuard] },
      { path: 'managecontrolmetadata', component: ManageControlmatadataComponent },
      { path: "dashboarddetails/:controlName/:lastRunTimestamp/:sourceName",  component: DashboardDetailsComponent, canActivate: [MsalGuard, PersmissionRouteGuard] },  
      { path: 'unauthorized', component: UnauthorizedComponent },
      { path: 'login', component: LoginComponent }
    ]
  }];
@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule],
  providers: [PersmissionRouteGuard, ApiResolver]
})
export class AppRoutingModule {
}
