import { Injectable } from '@angular/core';
import { HttpClient,  HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from '../shared/common-services/api.service';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { ConfigService } from '../shared/common-services/config.service';

@Injectable()
export class FileUploadService extends ApiService {

  constructor(
    protected httpClient: HttpClient, protected config: ConfigService, protected router: Router, protected authService: MsalService
  ) {

    super(router, httpClient, config,authService);
  }

  uploadFiles(controlName: string, sourceName: string, file: File = null): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('sourceName', sourceName);
    formData.append('controlName', controlName);
    let url = this.config.getSettings('apiBaseURL') + "/api/FileUpload/uploadFiles"
    return this.httpClient.post(url, formData, { headers: new HttpHeaders({ 'Authorization': 'true' }) });

  }

  sentNotification(body: any) {
    return this.post("/api/Notification/QueueNotification", body);
  }


}
