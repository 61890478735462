<div class="container main_container">
  <div class="header_second">
    <div class="full_width">
      <div class="breadscrum_col">
        <ul>
          <li><a [routerLink]="'/home'"> My Dashboard</a></li>
          <li><span class="glyphicon glyphicon-menu-right cool_gray2"></span></li>
          <li>Control Metadata</li>
        </ul>
      </div>
    </div>
    <div class="full_width">
      <hr />
    </div>
    <div class="full_width margintop-10 mangecontrol_row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <h1> Manage Control Metadata </h1>
      </div>


      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 margintop-10 padding_left-0">
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 no-padding">
          <label class="col-xs-4 col-sm-4 col-md-4 col-lg-4 no-padding" style="width: 195px; padding-left: 0px;">Control Name <span style="color:black;">*</span> : </label>
          <select class="form-control form_txt" formcontrolname="controlName" (change)="SelectControlName($event.target.value)" [(ngModel)]="selectControl">
            <option value="0">Select</option>
            <option *ngFor="let control of controlList" [value]="control.ID">{{control.ControlDisplayName}}</option>
          </select>
        </div>


        <div class="  col-xs-6 col-sm-6 col-md-6 col-lg-6 padding_right-0">
          <label class="col-xs-2 col-sm-2 col-md-2 col-lg-2 no-padding" style="width: 195px; padding-left: 0px;">Next Updated Date : </label>
          <input type="text" class="form-control" style="width: 335px; float: left; margin-right: 7px;" formcontrolname="nextupdateddate" [(ngModel)]="selectedNextUpdatedDate" disabled />
        </div>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 margintop-10 padding_left-0">

        <div class=" col-xs-6 col-sm-6 col-md-6 col-lg-6 no-padding ">
          <label class="col-xs-4 col-sm-4 col-md-4 col-lg-4 no-padding" style="width: 195px; padding-left: 0px;">Frequency <span style="color:black;">*</span> : </label>
          <select class="form-control form_txt" formcontrolname="frequencyControl" (change)="SelectFrquency($event.target.value)" [(ngModel)]="selectedFrequecy">
            <option value="0">Select</option>
            <option value="D">Daily</option>
            <option value="W">Weekly</option>
            <option value="M">Monthly</option>
            <option value="Q">Quarterly</option>
            <option value="S">Semi Annual</option>
            <option value="A">Annual</option>
          </select>
        </div>

        <div class="  col-xs-6 col-sm-6 col-md-6 col-lg-6 padding_right-0">
          <label class="col-xs-2 col-sm-2 col-md-2 col-lg-2 no-padding" style="width: 195px; padding-left: 0px; ">Revised Next Updated Date : </label>
          <mat-form-field style="width: 335px; position:relative;">
            <input type="text" matInput [matDatepicker]="datepicker" style="text-align:left;" #resultPickerModel="ngModel" [(ngModel)]="date" (ngModelChange)="updateCalcs()" />
            <span class="dds-icon_sm dds-icon_calendar" style="position: absolute; right: -30px; font-size: 25px; top: 1px;"></span>
            <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
            <mat-datepicker #datepicker></mat-datepicker>
          </mat-form-field>

        </div>
      </div>


      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 margin top-10 padding_left-0">
        <div class="full_width">
          <label class="col-xs-2 col-sm-2 col-md-2 col-lg-2" style="padding-right:10px;">Point of Contact <span style="color:black;">*</span> : </label>
          <mat-form-field class="col-xs-10 col-sm-10 col-md-10 col-lg-10">
            <mat-chip-list #toEmailChipList>
              <mat-chip *ngFor="let toUserValue of toUserValues" [selectable]="selectable" [removable]="removable" (removed)="removeTo(toUserValue)">
                {{toUserValue}}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
              </mat-chip>
              <input #toEmailInput type="text" (input)="onInputChanged($event)" id="idTo"
                     [formControl]="toEmailCtrl"
                     [matAutocomplete]="toauto"
                     [matChipInputFor]="toEmailChipList"
                     [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                     (matChipInputTokenEnd)="addTo($event)">
            </mat-chip-list>
            <mat-autocomplete #toauto="matAutocomplete" (optionSelected)="selectedTo($event)" [panelWidth]="500">
              <mat-option *ngFor="let autocomleteValue of toEmailFiltered | async" [value]="toEmail" style="border-bottom-style: groove; font-size: 13px; height: 30px;">
                <div class="font14-black name_txt">{{autocomleteValue.displayname}} </div> <div class="mail_txt">{{autocomleteValue.displayaddress}}</div>
              </mat-option>
              <div style="margin-left:15px; margin-top:20px; margin-bottom:10px;" *ngIf="isShowmoreButton">
                <button class="btn btn_primary btn_lg nav_btns_green width_full" (click)="isShowmore()">Show More</button>
              </div>
            </mat-autocomplete>
          </mat-form-field>
          <div *ngIf="isNoUserFound">No User Found</div>
        </div>
      </div>



      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 margin top-10 padding_left-0">
        <!--<div class=" col-xs-6 col-sm-6 col-md-6 col-lg-6 no-padding ">
      <label class="col-xs-4 col-sm-4 col-md-4 col-lg-4 no-padding" style="width: 195px; padding-left: 0px; ">Point of Contact : </label>
      <input type="text" class="form-control" style="width: 283px; float: left; margin-right: 7px;" [(ngModel)]="pointOfContact" />
    </div>-->

        <div class="  col-xs-6 col-sm-6 col-md-6 col-lg-6 padding_right-0" style="margin-left: 558px;">
          <div style="float: right; margin-right: 10px;">
            <button class="btn btn_primary btn_lg nav_btns_green ng-star-inserted" [routerLink]="'/home'">Cancel</button>

          </div>
          <div style="float: right; margin-right: 10px; ">
            <button class="btn btn_primary btn_lg nav_btns_green ng-star-inserted" (click)="SaveDeshboardControl()">Save</button>

          </div>
        </div>
      </div>

      <div class="full_width dashboard_grid" style="padding-left:10px;">

        <div class="mat-elevation-z8 full_width margintop-20 grid_list">
          <mat-paginator [pageSizeOptions]="[4,6,8,16]" [pageSize]="8"></mat-paginator>
          <div class="table-responsive dashboard_list">
            <table class="responsive-table manage_controls" mat-table [dataSource]="ControlsDataSource" matSort>
              <!--Control Column-->
              <ng-container matColumnDef="ControlDisplayName">
                <th mat-header-cell *matHeaderCellDef>
                  <span>Control <span class="dds-icon_sm dds-icon_sort-down sort-down_ico" mat-sort-header></span></span>
                  <input (keyup)="applyFilter($event)" placeholder="Search" class="form-control" (focus)="setupFilter('ControlDisplayName')" />
                </th>
                <td mat-cell *matCellDef="let row">{{row.ControlDisplayName}}</td>
              </ng-container>

              <!--Frecuency Changes From Column-->
              <ng-container matColumnDef="FrequencyChangeFrom">
                <th mat-header-cell *matHeaderCellDef>
                  <span>Frequency Change From <span class="dds-icon_sm dds-icon_sort-down sort-down_ico" mat-sort-header></span></span>
                  <input (keyup)="applyFilter($event)" placeholder="Search" class="form-control" (focus)="setupFilter('FrequencyChangeFrom')" />
                </th>
                <td mat-cell *matCellDef="let row"> {{row.PreviousFrequency}} </td>
              </ng-container>

              <!--Frecuency Change To Column-->
              <ng-container matColumnDef="FrequencyChangeTo">
                <th mat-header-cell *matHeaderCellDef>
                  <span>Frequency Change To <span class="dds-icon_sm dds-icon_sort-down sort-down_ico" mat-sort-header></span></span>
                  <input (keyup)="applyFilter($event)" placeholder="Search" class="form-control" (focus)="setupFilter('FrequencyChangeTo')" />
                </th>
                <td mat-cell *matCellDef="let row"> {{row.Frequency}} </td>
              </ng-container>

              <!--NextUpdateddate Column-->
              <ng-container matColumnDef="NextUpdateddate">
                <th mat-header-cell *matHeaderCellDef>
                  <span>Next Updated date <span class="dds-icon_sm dds-icon_sort-down sort-down_ico" mat-sort-header></span></span>
                  <input (keyup)="applyFilter($event)" placeholder="Search" class="form-control" (focus)="setupFilter('NextUpdateddate')" />
                </th>
                <td mat-cell *matCellDef="let row"> {{row.PreviousNextUpdate| date:'MM-dd-yyyy'}} </td>
              </ng-container>

              <!--RevisedNextUpdatedDate Column-->
              <ng-container matColumnDef="RevisedNextUpdatedDate">
                <th mat-header-cell *matHeaderCellDef>
                  <span>Revised Next Updated Date <span class="dds-icon_sm dds-icon_sort-down sort-down_ico" mat-sort-header></span></span>
                  <input (keyup)="applyFilter($event)" placeholder="Search" class="form-control" (focus)="setupFilter('RevisedNextUpdatedDate')" />
                </th>
                <td mat-cell *matCellDef="let row"> {{row.NextUpdate| date:'MM-dd-yyyy'}} </td>
              </ng-container>

              <!--POC Column-->
              <ng-container matColumnDef="POC">
                <th mat-header-cell *matHeaderCellDef>
                  <span>Point Of Contact <span class="dds-icon_sm dds-icon_sort-down sort-down_ico" mat-sort-header></span></span>
                  <input (keyup)="applyFilter($event)" placeholder="Search" class="form-control" (focus)="setupFilter('POC')" />
                </th>
                <td mat-cell *matCellDef="let row"><div class="mail_txt2">{{row.PointofContact}}</div>  </td>
              </ng-container>

              <!--UpdatedOn Column-->
              <ng-container matColumnDef="UpdatedOn">
                <th mat-header-cell *matHeaderCellDef>
                  <span>Updated On <span class="dds-icon_sm dds-icon_sort-down sort-down_ico" mat-sort-header></span></span>
                  <input (keyup)="applyFilter($event)" placeholder="Search" class="form-control" (focus)="setupFilter('UpdatedOn')" />
                </th>
                <td mat-cell *matCellDef="let row"> {{row.UpdatedOn}} </td>
              </ng-container>

              <!--Updated By Column-->
              <ng-container matColumnDef="UpdatedBy">
                <th mat-header-cell *matHeaderCellDef>
                  <span>Updated By <span class="dds-icon_sm dds-icon_sort-down sort-down_ico" mat-sort-header></span></span>
                  <input (keyup)="applyFilter($event)" placeholder="Search" class="form-control" (focus)="setupFilter('UpdatedBy')" />
                </th>
                <td mat-cell *matCellDef="let row"> {{row.UpdatedBy}}  </td>
              </ng-container>



              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

              <!--Row shown when there is no matching data.-->
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="7">No data matching the filter</td>
              </tr>
            </table>
          </div>
        </div>
      </div>


    </div>
  </div>
</div>

