import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { of } from 'rxjs';
import { DashboardService } from './services/dashboard.service';
import { SpinnerService } from '../shared/spinner/spinner.service';
import { ControlData } from './models/control-data';
import { CommonService } from '../shared/common-services/common.service';
import { ToastrService } from 'ngx-toastr';
import { formatDate } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ReprocessComponent } from '../reprocess/reprocess.component';
import { ConfigService } from '../shared/common-services/config.service';
import { CookieService } from 'ngx-cookie-service';

declare var _satellite: any;
declare var analyticsDataLayer: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  originalData: any;
  controlList: ControlData[] = [];
  displayedColumns: string[] = ['ID', 'ControlDisplayName', 'Frequency', 'LastUpdate', 'NextUpdate', 'ComplianceStatus', 'AttestationStatus', 'ShouldReprocess'];
  dataSource: MatTableDataSource<ControlData>;
  ControlsDataSource: MatTableDataSource<ControlData>;
  script: any;
  loggedInUser1: string;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(public dialog: MatDialog, private dashboardService: DashboardService, private spinnerService: SpinnerService, private commonService: CommonService, private toastr: ToastrService
    , protected config: ConfigService, private _renderer: Renderer2, private cookieService: CookieService) {

   
  }

  ngOnInit(): void {
    this.getSecurityActions();
    this.trackOmniture('home');
    this.loggedInUser1 = '';
   
    if (!localStorage.getItem('foo')) {
      localStorage.setItem('foo', 'no reload');
      this.cookieService.deleteAll();
      location.reload();     
    } else {
     
    }
    
  }

  createOneTrustScriptTag() {
    this.script = this._renderer.createElement('script');
    this.script.src = this.config.getSettings('OneTrustSrc');
    this._renderer.setAttribute(this.script, "data-domain-script", this.config.getSettings('AdminOneTrustDataDomainScript'));
    
    var footer: any;
    footer = document.getElementById("footer");    
    if (footer != null || footer != undefined) {
      footer.appendChild(this.script);
      this.script.classList.add("coockiePopup");
    }
  }

  trackOmniture(pageName: string) {
    let loggedInUser;
    if (!loggedInUser)
      loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

    if (loggedInUser && loggedInUser.userName) {
      analyticsDataLayer.pageInfo.pageName = pageName;
      analyticsDataLayer.pageInfo.userID = loggedInUser.userName.split('@')[0];
      _satellite.track("pageview");
    }
  }

  openDialog(control: any): void {
    const dialogRef = this.dialog.open(ReprocessComponent, {
      data: { control: control, controlList: this.originalData}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null || result != undefined) {
        this.controlList.filter(x => x.ControlDisplayName == result.controlName)[0] = result.data;
        this.isReprocessDisable(result);
      }

    });
  }
  ngAfterViewInit() {
    setTimeout(() => this.ControlsDataSource.paginator = this.paginator);
    this.ControlsDataSource.sort = this.sort;   
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.ControlsDataSource.filter = filterValue.trim().toLowerCase();

    if (this.ControlsDataSource.paginator) {
      this.ControlsDataSource.paginator.firstPage();
    }
  }

  // On input focus: setup filterPredicate to only filter by input column
  setupFilter(column: string) {
    this.ControlsDataSource.filterPredicate = (d: any, filter: string) => {
      const textToSearch = d[column] && d[column].toLowerCase() || '';
      return textToSearch.indexOf(filter) !== -1;
    };
  }

  getFormattedDate(date: Date) {
    var dd = date.getDate();
    var mm = date.getMonth()+1;
    var yyyy = date.getFullYear();
    return mm + "/" + dd + "/" + yyyy;
  }
  groupNames = [];
  getSecurityActions() {
    this.spinnerService.showSpinner();
    this.controlList = [];
    this.ControlsDataSource = new MatTableDataSource(this.controlList);
    setTimeout(() => this.ControlsDataSource.paginator = this.paginator);
    setTimeout(() => this.ControlsDataSource.sort = this.sort);

    var securityAction = JSON.parse(localStorage.getItem("SecurityAction"));
    if (securityAction != null || securityAction != undefined) {
      this.getGroupNames(securityAction);
      this.getAllDahboardControl(this.groupNames);
    }
    else {
      var loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
      this.commonService.getAllSecurityAction(loggedInUser.userName.toLowerCase()).subscribe((result: any) => {
        localStorage.setItem("SecurityAction", JSON.stringify(result));
        this.getGroupNames(result);
        this.getAllDahboardControl(this.groupNames);
      });
    }

  }

  getGroupNames(securityActions:any):any {
    securityActions.roles.forEach((element: any) => {
      element.allowedSecurityGroups.forEach((security: any) => {
        if (security.groupName != null && security.groupName != 'general') {
          if (!(this.groupNames.indexOf(security.groupName) > -1))
            this.groupNames.push(security.groupName);
        }
      });
    });
  }

  getAllDahboardControl(groupNames:any) {
    this.dashboardService.getAllDashboardControls(groupNames).subscribe((result: any) => {
      
      //if (this.loggedInUser1 == '')
      //  this.loggedInUser1 = JSON.parse(localStorage.getItem("loggedInUser")).userName;
      //if (this.loggedInUser1 && this.loggedInUser1 !='') {
      //   this.createOneTrustScriptTag();
      //}     
      if (result != null) {
        this.originalData = result;
        of((result.sort((a, b) => a.orderId - b.orderId))).subscribe(sortedData => {
          result = sortedData;
        });
        for (var i = 0; i < result.length; i++) {
          let attestationStatus = "";
          let complianceStatus = "";
          if (result[i].controlType == "manual") {
            if (!result[i].attestationStatus || result[i].attestationStatus.length == 0) {
              let lastUpdatedDate = new Date(result[i].lastUpdate);
              let overDueDays = Math.ceil((new Date().getTime() - lastUpdatedDate.getTime()) / (1000 * 60 * 60 * 24));
              if (overDueDays > 7) {
                attestationStatus = "Overdue";
              }
            }
            else {
              attestationStatus = this.commonService.getAttestationStatus(parseInt(result[i].attestationStatus), result[i].controlName)
            }
            complianceStatus = "N/A";
          }
          else {
            attestationStatus = "N/A";
            complianceStatus = result[i].complianceStatus;
          }
          this.controlList.push({
            ID: result[i].controlId,
            ControlDisplayName: result[i].controlDisplayName,
            Control: result[i].controlName,
            Frequency: result[i].frequencyDisplayName,
            LastUpdate: formatDate(result[i].lastUpdate, "MM-dd-yyyy", 'en-US'),
            NextUpdate: formatDate(result[i].nextUpdate, "MM-dd-yyyy", 'en-US'),
            ComplianceStatus: complianceStatus,
            AttestationStatus: attestationStatus,
            link: this.getLink(result[i]),
            ControlType: result[i].controlType,
            LastRunTimestamps: result[i].lastRunTimestamps,
            ShouldReprocess: result[i].shouldReprocess,
            PreviousFrequency: result[i].PreviousFrequency,
            PreviousNextUpdate: result[i].PreviousNextUpdate,
            PointofContact: result[i].PointofContact,
            UpdatedBy: result[i].UpdatedBy,
            UpdatedOn: result[i].UpdatedOn
          });

          this.commonService.setData(result[i].controlName, result[i].lastRunAdditionalInfo);
          this.commonService.setData(result[i].controlName + "displayName", result[i].controlDisplayName);

          this.ControlsDataSource = new MatTableDataSource(this.controlList);
          setTimeout(() => this.ControlsDataSource.paginator = this.paginator);
          setTimeout(() => this.ControlsDataSource.sort = this.sort);
        }
      }
      this.spinnerService.hideSpinner();
    });
  }
  
  getLink(control: any) {
    var url = "";
    if (control.lastRunAdditionalInfo == null || control.lastRunAdditionalInfo.length == 0) {
      return url;
    }
    var runTimestamp = control.lastRunAdditionalInfo[0].lastRunTimeStamp;
    var sourceName = control.lastRunAdditionalInfo[0].sourceName;
    if (control.controlType.toLowerCase() == "integration") {
      url = '/dashboarddetails/' + control.controlName + '/' + runTimestamp + '/' + sourceName
    }
    else {
      url = '/attestation/' + control.controlName + '/' + runTimestamp
    }

    return url;
  }

  onControlClick(row) {
    if (row.AttestationStatus && row.AttestationStatus == "Overdue") {
      sessionStorage.setItem("overDueStatus", "Overdue");
    }
  }


  attestationStatusIcons(status: string): string {
    var className = '';
    if (status == 'Approved') {
      className = 'dds-icon_check';
    } else if (status == 'Sent for Revision') {
      className = 'dds-icon_timer';
    } else if (status == 'Pending Review') {
      className = 'dds-icon_cancel';
    } else if (status == 'Revision Pending') {
      className = 'dds-icon_timer';
    } else if (status == 'Overdue') {
      className = 'dds-icon_hourglass';
    } else if (status == 'Sent for Review') {
      className = 'dds-icon_cancel';
    }
    return className;
  }

  downloadDashboardControls() {
    this.spinnerService.showSpinner();
    this.dashboardService.downloadDashboardControls(this.controlList).subscribe((data: any) => {
      var blob = this.commonService.convertBase64ToBlobData(data, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"); //new Blob([byteArray], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
      var url = window.URL.createObjectURL(blob);

      var link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", "Dashboard.xlsx");
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link)
      this.spinnerService.hideSpinner();
    },
      (err: any) => {
        this.toastr.error(err);
        this.spinnerService.hideSpinner();
      }
    );
  }
  hasAccess(groupName: string, securityAction: string): boolean {
    return this.commonService.hasAccess(groupName, securityAction);
  }
  isIntegrationControl(controlType: string): boolean {
    if (controlType.toLowerCase() == "integration")
      return true;
    else
      return false;
  }

  isReprocessDisable(controlName: string): string {
    var className = '';
    if (this.controlList.filter(x => x.ControlDisplayName == controlName)[0].ControlType == 'integration' && this.controlList.filter(x => x.ControlDisplayName == controlName)[0].ShouldReprocess) {
      className = 'disable_reprocess_icon';
    } else {
      className = '';
    }
    return className;
  }


}
