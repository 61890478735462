

<div class="popup_container" >
  <form (ngSubmit)="onSubmit()" [formGroup]="form">
    <div class="full_width paddingbottom-20px">
      <h1>{{headerText}}</h1>
      <div mat-button class="right close_btn" (click)="closeDialog()">X</div>
    </div>
    <div>
      <div class="full_width paddingbottom-20px" style="padding-bottom:15px;">
        <div class="form-group">
          <label for="name">Source</label>
          <div class="full_width">
            <select #ddlSource (change)="onSourceChange(ddlSource.value)" class="form-control">
              <option *ngFor="let src of (metaData!=undefined ?metaData.collectPhase.source:[])" [value]="src.sourceName" [selected]="src.sourceName == SelectedSourceName">{{src.sourceName}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="full_width" style="padding-bottom: 15px;">
        <div class="form-group">
          <label>Previous Runs </label>
          <div class="full_width">
            <select class="form-control" #ddlRunPeriod (change)='onRunPeriodChanged(ddlRunPeriod.value)'>
              <option value="">--Select--</option>
              <option *ngFor="let runPeriod of runPeriods" [value]="runPeriod.runTimestamp" [selected]="runPeriod.runTimestamp == selectedRunTimestamp">{{runPeriod.name}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="uploads_r full_width paddingbottom-20px" *ngIf="isUploadFileShow(headerText)">
        <div class="full_width">
          <div class="full_width" *ngFor="let source of (metaData!=undefined ?metaData.collectPhase.source:[])">
            <div *ngIf="selectedSourceName==source.sourceName">
              <div class="full_width">
                <div class="form-group" style="margin-bottom: 0px;">
                  <label class="align_r">{{source.sourceName}} Details</label>
                </div>
              </div>
              <div class="full_width">
                <div class="form-group">
                  <label style="font-size:11px;">Please upload the Data feed file</label>
                  <div class="full_width">
                    <div class="custom-file mb-3">
                      <input type="file" [formControlName]="source.sourceName" #fileUploader class="custom-file-input" (change)="handleUpload($event, source.sourceName)" />
                      <label class="custom-file-label" for="customFile">Choose file to upload</label>
                    </div>
                    <div>{{this.form.controls['fileName'].value}}</div>
                    <span *ngIf="this.form.controls[source.sourceName].errors?.fileSizeValidator" class="validation-msg">
                      Maximum allowed size is 5MB
                    </span>
                    <app-field-error-display [displayError]="isFieldValid('fileName')" errorMsg="Please upload excel">
                    </app-field-error-display>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="left popup_bottom_btn margintop-20">
      <button type="submit" class="btn btn_primary btn_lg nav_btns_green" style="margin-right:20px;">Reprocess</button>
      <div mat-button class="btn btn_primary btn_lg nav_btns_green" (click)="closeDialog()">Cancel</div>
    </div>
  </form>
</div>





