<div class="full_width dashboard_grid">
  <div class="mat-elevation-z8 full_width grid_list" [formGroup]="form">
    <table class="responsive-table" mat-table [dataSource]="ControlsDataSource" [formArrayName]="question.questionId" matSort [hidden]="this.vendors && this.vendors.length==0">

      <!-- Vendor Column -->
      <ng-container matColumnDef="vendorName">
        <th mat-header-cell *matHeaderCellDef>
          <span >Vendor<span class="dds-icon_sm dds-icon_sort-down sort-down_ico" mat-sort-header></span></span>
          <input (keyup)="applyFilter($event,'vendorName')" placeholder="Search" class="form-control" (focus)="setupFilter('vendorName')" />
        </th>
        <td mat-cell *matCellDef="let row;let i = index" [formGroupName]="i">
          <!--<input *ngIf="isNew(row)" [type]="text" class="form-control" formControlName="vendorName" placeholder="Vendor Name">-->
          <input *ngIf="isNew(row)" matInput (input)="getVendors($event)"
                 [matAutocomplete]="vendorsAutoComplete" placeholder="Vendor Name" [type]="text"
                 formControlName="vendorName" autocomplete="on"
                 class="form-control textVendorName">
          <mat-autocomplete #vendorsAutoComplete="matAutocomplete">
            <mat-option style="border-bottom-style: groove; font-size: 13px; height: 30px;"
                        *ngFor="let vendor of vendorList ; let index=index"  [value]="vendor.name">
              <div class="font14-black name_txt">{{vendor.name}} </div> 
            </mat-option>
          </mat-autocomplete>

          <span *ngIf="!isNew(row)">{{getControlName(row, 'vendorName')}}</span>

        </td>
      </ng-container>

      <!-- DeviceType Column -->
      <ng-container matColumnDef="deviceType">
        <th mat-header-cell *matHeaderCellDef>
          <span >Device Type<span class="dds-icon_sm dds-icon_sort-down sort-down_ico" mat-sort-header></span></span>
          <input (keyup)="applyFilter($event)" placeholder="Search" class="form-control" (focus)="setupFilter('deviceType')" />
        </th>

        <td mat-cell *matCellDef="let row;let i = index" [formGroupName]="i">
          <!--<input *ngIf="isNew(row)" [type]="text" class="form-control" formControlName="deviceType" placeholder="Device Type">-->
          <input *ngIf="isNew(row)" matInput (input)="getDeviceTypes($event)"
                 [matAutocomplete]="deviceTypeAutoComplete" placeholder="Device Type" [type]="text"
                 formControlName="deviceType" autocomplete="on"
                 class="form-control textDeviceType">
          <mat-autocomplete #deviceTypeAutoComplete="matAutocomplete">
            <mat-option style="border-bottom-style: groove; font-size: 13px; height: 30px;"
                        *ngFor="let deviceType of deviceTypes ; let index=index"  [value]="deviceType.name">
              <div class="font14-black name_txt">{{deviceType.name}} </div>
            </mat-option>
          </mat-autocomplete>

          <span *ngIf="!isNew(row)">{{getControlName(row, 'deviceType')}}</span>
        </td>
      </ng-container>

      <!-- Deloitte Contact Column -->
      <ng-container matColumnDef="deloitteContactName">
        <th mat-header-cell *matHeaderCellDef>
          <span >Deloitte Contact <span class="dds-icon_sm dds-icon_sort-down sort-down_ico" mat-sort-header></span></span>
          <input (keyup)="applyFilter($event)" placeholder="Search" class="form-control" (focus)="setupFilter('deloitteContactName')" />
        </th>
        <td mat-cell *matCellDef="let row;let i = index" [formGroupName]="i">
          <!--<input *ngIf="isNew(row) || isEdit(row)" [type]="text" class="form-control" formControlName="deloitteContactName">-->
          <input *ngIf="isNew(row) || isEdit(row)" matInput (input)="onInputChanged($event)"
                 [matAutocomplete]="userOptions" placeholder="Contact Name" [type]="text"
                 formControlName="deloitteContactName" autocomplete="on"
                 class="form-control textUserName">
          <mat-autocomplete #userOptions="matAutocomplete">
            <mat-option style="border-bottom-style: groove; font-size: 13px; height: 30px;"
                        *ngFor="let user of users ; let index=index" (onSelectionChange)="onSelectContact($event,index, row)" [value]="user.userName">
              <div class="font14-black name_txt">{{user.userName}} </div> <div class="mail_txt">{{user.emailAddress}}</div>
            </mat-option>
            <div style="margin-left:15px; margin-top:20px; margin-bottom:10px;" *ngIf="isShowmoreButton">
              <button class="btn btn_primary btn_lg nav_btns_green width_full" (click)="isShowmore()">Show More</button>
            </div>
          </mat-autocomplete>
          <span *ngIf="!isEdit(row)">{{getControlName(row, 'deloitteContactName')}}</span>
        </td>
      </ng-container>

      <!-- Deloitte ID Column -->
      <ng-container matColumnDef="deloitteContactId">
        <th mat-header-cell *matHeaderCellDef>
          <span >Deloitte Email<span class="dds-icon_sm dds-icon_sort-down sort-down_ico" mat-sort-header></span></span>
          <input (keyup)="applyFilter($event)" placeholder="Search" class="form-control" (focus)="setupFilter('deloitteContactId')" />
        </th>
        <td mat-cell *matCellDef="let row;let i = index" [formGroupName]="i">
          <input *ngIf="isNew(row)" [type]="text" class="form-control" formControlName="deloitteContactId">
          <span *ngIf="!isNew(row)">{{getControlName(row, 'deloitteContactId')}}</span>
        </td>
      </ng-container>

      <!-- additional info -->
      <ng-container matColumnDef="additionalInfo">
        <th mat-header-cell *matHeaderCellDef>
          <span >Additional Info <span class="dds-icon_sm dds-icon_sort-down sort-down_ico" mat-sort-header></span></span>
          <input (keyup)="applyFilter($event)" placeholder="Search" class="form-control" (focus)="setupFilter('additionalInfo')" />
        </th>
        <td mat-cell *matCellDef="let row;let i = index" [formGroupName]="i">
          <input *ngIf="isNew(row) || isEdit(row)" [type]="text" class="form-control" formControlName="additionalInfo" placeholder="Add info">
          <span *ngIf="!isEdit(row)">{{getControlName(row, 'additionalInfo')}}</span>
        </td>
      </ng-container>
      <!-- Actions -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>
          <div class="cool_gray11">Actions</div>
        </th>
        <td mat-cell *matCellDef="let row;let i = index" [formGroupName]="i" class="actions">
          <!--<span *ngIf="!isNew(row)" style="cursor:pointer;color:blue;text-decoration:underline" (click)="editVendor(row)">Edit</span>&nbsp;&nbsp;
        <span style="cursor:pointer;color:blue;text-decoration:underline" (click)="deleteVendor(row)">Delete</span>-->

          <div class="dropdown">
            <button class="btn btn-default dropdown-toggle btn-none" type="button" id="menu1" data-toggle="dropdown">
              <span class="dds-icon_sm dds-icon_dots-v paddingall10 cpointer"></span>

            </button>
            <ul class="dropdown-menu actions_drop" role="menu" aria-labelledby="menu1">
              <li *ngIf="!isNew(row)" role="presentation"><a role="menuitem" tabindex="-1" (click)="editVendor(row)"> <span class="dds-icon_sm dds-icon_edit-24 marginright15"></span> Edit</a></li>
              <li role="presentation"><a role="menuitem" tabindex="-1" (click)="deleteVendor(row)"> <span class="dds-icon_sm dds-icon_delete marginright15"></span>Delete</a></li>
            </ul>
          </div>


        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;">{{row}}</tr>
    </table>
    <app-field-error-display [displayError]="isFieldValid(question.questionId)" errorMsg="{{errorMessage}}">
    </app-field-error-display>
    <div class="full_width">
      <div class="item_addbtn">
        <button [disabled]="!isEnabled" (click)="addNewVendor()" type="button" class="btn btn_primary btn_lg additem"> <span class="dds-icon_sm dds-icon_plus" style="margin-right:5px;"></span> Add new item </button>
      </div>
    </div>

    <!--<input type="button" class="btn btn-success" value="+ Add new item" (click)="addNewVendor()" />-->
  </div>
</div>

