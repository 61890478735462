import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {  AttestationForm} from './models/question-base';
import { FormGroup, FormArray, AbstractControl, FormControl, Validators } from '@angular/forms';
import { SpinnerService } from '../shared/spinner/spinner.service';
import { QuestionService } from './services/question.service';
import { CommonService } from '../shared/common-services/common.service';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html'
})
export class GridComponent implements OnInit {
  pageNumber: number = 1;
  users: Array<any> = [];
  vendorList: Array<any> = [];
  deviceTypes: Array<any> = [];
  searchstring: any = '';
  vendorSearchstring: any = '';
  deviceTypeSearchstring: any = '';
  tempUserList: Array<any> = [];
  isShowmoreButton: any = true;

  @Input() question: AttestationForm;
  @Input() form?: FormGroup;
  @Input() masterData: any;
  displayedColumns: string[] = ['vendorName', 'deviceType', 'deloitteContactName', 'deloitteContactId', 'additionalInfo', 'actions'];
  ControlsDataSource: MatTableDataSource<AbstractControl>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Output() addQuestion = new EventEmitter<any>();
  errorMessage = "Vendor, Device Type and Deloitte Contact fields are Mandatory";
  constructor(private spinnerService: SpinnerService, private commonService: CommonService, private qs: QuestionService) { }
  isNoUserFound: boolean;
  ngOnInit(): void {
    this.ControlsDataSource = new MatTableDataSource(this.vendors.controls);
    if (this.question.vendorDetails && this.question.vendorDetails.length == 0)
      this.addNewVendor();
    //this.vendorList = this.masterData.tempVendorList;
    //this.deviceTypes = this.masterData.tempDeviceTypeList;
  }



  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }
  isFieldValid(field: string) {
    return !this.form.get(field).valid && this.form.get(field).touched && this.form.get(field).enabled;
  }

  get vendors(): FormArray {
    return this.form.get(this.question.questionId) as FormArray;
  }
  get isEnabled(): boolean {
    return this.form.get(this.question.questionId).enabled;
  }
  getControlName(row: FormGroup, controlName: string) {
    return row.get(controlName).value;
  }

  isNew(row: FormGroup) {
    return row.get("isNew").value
  }
  isEdit(row: FormGroup) {
    return row.get("isEdit").value
  }

  applyFilter(event: Event, column: string) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.ControlsDataSource.filter = filterValue.trim().toLowerCase();
  }

  // On input focus: setup filterPredicate to only filter by input column
  setupFilter(column: string) {
    this.ControlsDataSource.filterPredicate = (d: any, filter: string) => {
      const textToSearch = d.controls[column].value && d.controls[column].value.toLowerCase() || '';
      return textToSearch.indexOf(filter) !== -1;
    };
  }

  addNewVendor() {
    if (this.isEnabled == false)
      return
    let vendorId = 1;
    if (this.question.vendorDetails.length > 0) {
      vendorId = this.question.vendorDetails[this.question.vendorDetails.length - 1].vendorId + 1;
    }

    let newId = this.createGuid();
    const gridFormGroup = new FormGroup({
      vendorName: new FormControl("", [Validators.required]),
      deviceType: new FormControl("", [Validators.required]),
      deloitteContactName: new FormControl("", [Validators.required]),
      deloitteContactId: new FormControl({ value: "", disabled: true }),
      additionalInfo: new FormControl(""),
      isNew: new FormControl(true),
      isEdit: new FormControl(true),
      diligenceQuestionId: new FormControl(newId),
      isVisible: new FormControl(true)
    });
    this.vendors.push(gridFormGroup);
    var data = {
      id: newId,
      action: "insert",
      vendorsCount: this.vendors.controls.length
    }
    this.addQuestion.emit(data);
    this.ControlsDataSource._updateChangeSubscription();
  }

  createGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  editVendor(row: FormGroup) {
    if (this.isEnabled == false)
      return
    row.get("isNew").setValue(false);
    row.get("isEdit").setValue(true);
  }
  deleteVendor(row: FormGroup) {
    if (this.isEnabled == false)
      return
    this.vendors.removeAt(this.vendors.controls.indexOf(row))
    this.ControlsDataSource._updateChangeSubscription();
    var data = {
      id: row.get("diligenceQuestionId").value,
      action: "delete"
    }
    this.addQuestion.emit(data);
  }
  loadUser: any;
  paginatorLink: string;
  isShowmore() {
    this.pageNumber = this.pageNumber + 1;
    this.isShowmoreButton = true;
    this.spinnerService.showSpinner();
    this.commonService.getUserByTerm(10, this.searchstring, this.paginatorLink).subscribe((result: any) => {
      if (result != undefined || result != null) {
        this.loadUser = result;
        if (this.loadUser != undefined && this.loadUser.value.length < 10) {
          this.isShowmoreButton = false;
        }
        this.loadUser.value.forEach((element: any) => {
          this.tempUserList.push({ userName: element.displayName, emailAddress: element.mail, alias: element.mail });
        });
      }
      this.paginatorLink = this.loadUser["@odata.nextLink"];
      this.users = this.tempUserList;
      this.spinnerService.hideSpinner();
      // result.loadUser = null;
    });
  }
  userList: any;
  //For PeoplePicker
  onInputChanged(event: any) {
    this.isShowmoreButton = true;
    this.users = [];
    // this.isPPDUserSelected = false;
    if ((event.target as HTMLInputElement).value.length >= 4) {
      this.users = [];
      this.spinnerService.showSpinner();
      this.commonService.getUserByTerm(10, (event.target as HTMLInputElement).value, null).subscribe((result: any) => {

        if (result != undefined || result != null) {
          this.userList = result;
          if ((event.target as HTMLInputElement).value != this.searchstring) {
            this.tempUserList = [];
            this.userList.value.forEach((element: any) => {
              this.tempUserList.push({ userName: element.displayName, emailAddress: element.mail.toLowerCase(), alias: element.mail.toLowerCase() });
            });
            this.isNoUserFound = false;
          }
          this.paginatorLink = this.userList["@odata.nextLink"];
        }
        if (this.userList != undefined && this.userList.value.length < 10) {
          this.isShowmoreButton = false;
        }
        if (this.userList != undefined && this.userList.value.length == 0) {
          this.isNoUserFound = true;
        }
        this.searchstring = (event.target as HTMLInputElement).value;
        this.users = this.tempUserList;
        this.spinnerService.hideSpinner();

      });
    }
    else if ((event.target as HTMLInputElement).value.length == 0) {
      this.users = [];
      this.spinnerService.hideSpinner();
    }
    else {
      this.users = [];
      this.spinnerService.hideSpinner();
    }
  }
  getVendors(event: any) {
    this.vendorList = [];
    let value = (event.target as HTMLInputElement).value.toLowerCase();
    if (value.length == 0)
      this.vendorList = [];
    else
      this.vendorList = this.masterData.tempVendorList.filter(v => v.name && v.name.toLowerCase().indexOf(value) != -1);
  }

  getDeviceTypes(event: any) {
    this.deviceTypes = [];
    let value = (event.target as HTMLInputElement).value.toLowerCase();
    if (value.length == 0)
      this.deviceTypes = [];
    else
      this.deviceTypes = this.masterData.tempDeviceTypeList.filter(v => v.name && v.name.toLowerCase().indexOf(value) != -1);
  }
  onSelectContact(partner: any, index: any, row: FormGroup) {
    row.get("deloitteContactName").setValue(this.users[index].userName);
    row.get("deloitteContactId").setValue(this.users[index].emailAddress);
  }
}
