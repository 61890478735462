import { Question } from '../../manage-questionnaire/models/questionnaire';

export class AttestationForm extends Question {
  formCreatedStamp: string = "";
  frequency: string = "";
  answers: string = "";
  attachment: string = "";
  additionalComments: string = "";
  status: number;
  vendorDetails: VendorDetails[];
  attestationDate: string;
  attestationSubmittedDate: string;
  contentType: string;
  attestedBy: string;
  submittedBy: string;
}

export enum AttestationStatus {
  SentForReview=1,
  Approved,
  Rejected,
  SentForRevision,
  Overdue
}

export const AttestationStatusText = new Map<number, string>([
  [AttestationStatus.SentForReview, 'Sent for Review'],
  [AttestationStatus.Approved, 'Approved'],
  [AttestationStatus.Rejected, 'Rejected'],
  [AttestationStatus.SentForRevision, 'Sent for Revision'],
  [AttestationStatus.Overdue, 'Overdue'],
]);


export class AttestationFormResponse {
  questionnaireId: string = "";
  questionId: string = "";
  controlName: string = "";
  answers: string;
  timestamp: string;
  status: number;
  fileName: string;
  vendorDetails: VendorDetails[];
  questionOrder: number;
  attestedBy: string;
  submittedBy: string;
}

export class VendorDetails {
  vendorId: number;
  vendorName: string = "";
  deviceType: string = "";
  deloitteContactName: string = "";
  deloitteContactId: string = "";
  additionalInfo: string = "";
  isNewRow: boolean = false;
  isEdit: boolean = false;
  diligenceQuestionId: string = "";
  isVisible: boolean = false;
}
export class MasterData {
  type: string;
  name: string;
  id: string;
  tags: any;
  parentTypeId: string;
  updatedBy: string;
  updatedOn: string;
  isActive: boolean
}
