import { Component, Input, OnInit, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, FormControl, Validators } from '@angular/forms';
import {AttestationForm } from './models/question-base';
import { QuestionService } from './services/question.service';
import { SpinnerService } from '../shared/spinner/spinner.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../shared/common-services/common.service';
import { fileSizeValidator, requiredFileType } from './services/question-control.service';
import { QuestionType, TriggerType, Question } from '../manage-questionnaire/models/questionnaire';

@Component({
  selector: 'app-question',
  templateUrl: './dynamic-form-question.component.html'
})
export class DynamicFormQuestionComponent implements OnInit {
  @Input() question?: AttestationForm;
  @Input() masterData: any;
  @Input() questions: AttestationForm[];
  @Input() form?: FormGroup;
  questionType = QuestionType;
  @Output() addGuidanceQuestion = new EventEmitter<any>();
  @ViewChild('fileUploader') fileUploader: ElementRef
  errorMessage = "This field is Mandatory";
  ngOnInit() {
    this.triggerQuestion();
    if (this.question.questionType == QuestionType.file)
      this.errorMessage = "Please upload screen shot(only png/jpeg/jpg/docx/pptx/pdf formats are allowed)(max allowed size is 3MB)";

  };

  constructor(private qs: QuestionService, private spinnerService: SpinnerService, private toastr: ToastrService, private commonService: CommonService) {
  }

  get isValid() { return this.form?.controls[this.question.questionId].valid; }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

  isFieldValid(field: string) {
    return !this.form.get(field).valid && this.form.get(field).touched && this.form.get(field).enabled;
  }

  handleUpload(event) {
    const file = event.target.files[0];
    this.form?.controls[this.question.questionId].setValidators([fileSizeValidator(file, 3), requiredFileType(['png', 'jpeg', 'jpg', 'docx', 'pptx', 'pdf'])]);
    this.question.fileName = file.name;
    this.question.attachment = "";
    this.form?.controls[this.question.questionId].setValue(file);
  }

  deleteFile(id) {
    this.form?.controls[this.question.questionId].setValue("");
    this.question.fileName = "";
    this.question.attachment = "";
  }

  downloadAttachment(question: AttestationForm) {
    this.spinnerService.showSpinner();
    this.qs.downLoadAttachment(question).subscribe((data: any) => {
      //var blob = new Blob([data], { type: question.contentType });
      var blob = this.commonService.convertBase64ToBlobData(data, question.contentType);
      var url = window.URL.createObjectURL(blob);

      var link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", question.attachment);
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link)
      this.spinnerService.hideSpinner();
    },
      (err: any) => {
        this.toastr.error(err);
        this.spinnerService.hideSpinner();
      }
    );
  }

  triggerQuestion() {
    if (this.question.triggerQuestionID && this.form?.controls[this.question.questionId]) {
      let triggerQuestions = this.question.triggerQuestionID.split(';');
      let referenceQuestions = this.questions.filter(q => q.isReferenceQuestion == true);
      if (referenceQuestions && referenceQuestions.length > 0) {
        referenceQuestions.forEach((referenceQuestion) => {
          triggerQuestions.push(referenceQuestion.questionId);
        });
      }
      if (this.form?.controls[this.question.questionId].value == this.question.triggerOption) {
        if (this.question.triggerType == TriggerType.disable) {
          triggerQuestions.forEach((triggerQuestionID) => {
            this.form?.controls[triggerQuestionID].disable();
            let questionType = this.questions.filter(q => q.questionId == triggerQuestionID)[0].questionType;
            if (questionType == this.questionType.text || questionType == this.questionType.textarea)
              this.form?.controls[triggerQuestionID].setValue("");
          });


        }

      }
      else {
        if (this.question.triggerType == TriggerType.disable) {
          triggerQuestions.forEach((triggerQuestionID) => {
            this.form?.controls[triggerQuestionID].enable();
          });
        }

      }
    }

  }

  addQuestion(questionId: string) {
    this.addGuidanceQuestion.emit(questionId);
  }

  isDisClaimerQuestion(question: Question) {
    if (question && question.options && question.options.length == 1 && question.options[0].name.indexOf("I submit") != -1) {
      return true;
    }
    else {
      return false;
    }
  }

}
