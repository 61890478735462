import { Router } from "@angular/router";
import { HttpHeaders, HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { ConfigService } from './config.service';

const httpOptions = {
  params: {},
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  })
};
export class ApiService {
  apiBaseURL: string;
  requestObject: any;
  constructor(protected router: Router, protected httpClient: HttpClient, protected config: ConfigService, protected authService: MsalService) {
    if (config) {
      this.apiBaseURL = config.getSettings('apiBaseURL');      
    }
  }
  get getapiBaseURl() {
    //if (this.apiBaseURL) {
    //  this.apiBaseURL! = this.config.getSettings('apiBaseURL');
    //}
    //else {
    //  this.config.init();
    //}
    return this.apiBaseURL;
  }
  
  //private addHeader(params?: HttpParams, headers?: HttpHeaders) {
  //  if (params !== undefined && params !== null) {
  //    httpOptions.params = params;
  //  }
    //this.authService.acquireTokenSilent(this.requestObject).then((tokenResponse) => {
    //  console.log(this.requestObject, tokenResponse);
    //  headers = new HttpHeaders({
    //    'Content-Type': 'application/json',
    //    'Accept': 'application/json',
    //    'Authorization': 'Bearer ' + tokenResponse.accessToken
    //  });
     
  //  //})
  //  if (headers !== undefined && headers !== null) {
  //    httpOptions.headers = headers;
  //  }
  //}

  get<T>(path: string): Observable<T> {
    //if (path == '/api/Dashboard')
    //  this.apiBaseURL = this.config.getSettings('apiBaseURL')
    //if (path.indexOf('User') != -1)
    //  this.apiBaseURL = this.config.getSettings('apiBaseURL1')
    //if (path.indexOf('MasterData') != -1)
    //  this.apiBaseURL = this.config.getSettings('apiBaseURL2')
    //if (path.indexOf('FileUpload') != -1)
    //  this.apiBaseURL = this.config.getSettings('apiBaseURL3')
    //if (path.indexOf('ControlMetadata') != -1)
    //  this.apiBaseURL = this.config.getSettings('apiBaseURL3')
    //if (path.indexOf('Question') != -1)
    //  this.apiBaseURL = this.config.getSettings('apiBaseURL4')
    //if (path.indexOf('Attestation') != -1)
    //  this.apiBaseURL = this.config.getSettings('apiBaseURL4')
    //if (path.indexOf('EmailTemplate') != -1)
    //  this.apiBaseURL = this.config.getSettings('apiBaseURL5')
    //if (path.indexOf('Notification') != -1)
    //  this.apiBaseURL = this.config.getSettings('apiBaseURL5')
    path = this.apiBaseURL + path;
    //this.addHeader(params, headers);

    return this.httpClient.get<T>(path, httpOptions);
  }


  getGraph<T>(path: string): Observable<T> {    
    return this.httpClient.get<T>(path);
  }
  post<T>(path: string, body: any = {}, params?: HttpParams, headers?: HttpHeaders): Observable<T> {
    //if (path.indexOf('User') != -1)
    //  this.apiBaseURL = this.config.getSettings('apiBaseURL1')
    //if (path.indexOf('MasterData') != -1)
    //  this.apiBaseURL = this.config.getSettings('apiBaseURL2')
    //if (path.indexOf('FileUpload') != -1)
    //  this.apiBaseURL = this.config.getSettings('apiBaseURL3')
    //if (path.indexOf('Dashboard') != -1)
    //  this.apiBaseURL = this.config.getSettings('apiBaseURL3')
    //if (path.indexOf('Question') != -1)
    //  this.apiBaseURL = this.config.getSettings('apiBaseURL4')
    //if (path.indexOf('Attestation') != -1)
    //  this.apiBaseURL = this.config.getSettings('apiBaseURL4')
    //if (path.indexOf('EmailTemplate') != -1)
    //  this.apiBaseURL = this.config.getSettings('apiBaseURL5')
    //if (path.indexOf('Notification') != -1)
    //  this.apiBaseURL = this.config.getSettings('apiBaseURL5')
    path = this.apiBaseURL + path;
    // this.addHeader(params, headers);
    return this.httpClient.post<T>(path, body, httpOptions);
  }


  patch<T>(path: string, body: any = {}, params?: HttpParams, headers?: HttpHeaders): Observable<T> {
    path = this.getapiBaseURl + path;
    //this.addHeader(params, headers);
    return this.httpClient.patch<T>(path, body, httpOptions);
  }

  put<T>(path: string, body: any = {}, params?: HttpParams, headers?: HttpHeaders): Observable<T> {
    path = this.getapiBaseURl + path;
    //this.addHeader(params, headers);
    return this.httpClient.put<T>(path, body, httpOptions);
  }

  delete<T>(path: string, params?: HttpParams, headers?: HttpHeaders): Observable<T> {
    path = this.getapiBaseURl + path;
   // this.addHeader(params, headers);
    return this.httpClient.delete<T>(path, httpOptions);
  }
}
