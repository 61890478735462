export class AutoTagConfig {
  constructor(

  ) {
  }
  textTestCallback: any;
  dataCallback: any;
  itemTemplate: any;
  outputTemplate: any;
}
