import { Component } from '@angular/core';
import { SpinnerService } from './spinner.service';

@Component({
  selector: 'tctmd-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})

export class SpinnerComponent {
  public active: any;

  public constructor(spinner: SpinnerService) {
    spinner.status.subscribe((status: boolean) => {
      this.active = status;
    });
  }
}
