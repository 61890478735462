import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { ApiService } from '../../shared/common-services/api.service';
import { ConfigService } from '../../shared/common-services/config.service';
import { Questionnaire } from '../models/questionnaire';

@Injectable()
export class QuestionnaireService extends ApiService {

  constructor(
    protected httpClient: HttpClient, protected config: ConfigService, protected router: Router, protected authService: MsalService
  ) {

    super(router, httpClient, config, authService);
  }
  saveQuestionnaire(questionnaire: Questionnaire): Observable<any> {
    let url = "/api/Questionnaire/create/questionnaire";
    return this.post<any>(url, questionnaire);
  }

  getQuestionnaire(controlName: string, questionnaireId: string): Observable<Questionnaire> {
    let url = "/api/Questionnaire/get/questionnaire/" + controlName + "/" + questionnaireId;
    return this.get<Questionnaire>(url);
  }
  getQuestionnaireByControl(controlName: string): Observable<Questionnaire> {
    let url = "/api/Questionnaire/GetQuestionnaireByControl/" + controlName ;
    return this.get<Questionnaire>(url);
  }
  getQuestionnaires(): Observable<Array<Questionnaire>> {
    let url = "/api/Questionnaire/get/questionnaires/";
    return this.get<Array<Questionnaire>>(url);
  }
}
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "sort",
  pure: false
})
export class ArraySortPipe implements PipeTransform {
  transform(array: any, field: string): any[] {
    array.sort((a: any, b: any) => {
      if (a[field] < b[field]) {
        return -1;
      } else if (a[field] > b[field]) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }
}
