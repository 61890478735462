
<div class="popup_container">
  <div class="full_width paddingbottom-20px">
    <h1>{{headerText}}</h1>
    <div mat-button  class="right close_btn" (click)="closeDialog()">X</div>
  </div>
  <div class="full_width paddingbottom-20px" style="padding-bottom: 30px;">
    <div class="form-group">
      <label for="name">User Name</label>
      <div class="full_width relative_p">
        <span class="dds-icon_sm dds-icon_search search_ico"></span>
        <div class="sectionUser user_box">
          <div>
            <div>              
                <div>
                  <input matInput (input)="onInputChanged($event)" [value]="selectedUserDisplayName" [attr.disabled]="isMatInputDisabled?'true':null"
                         [matAutocomplete]="userOptions" placeholder="Search by User Name/User Email Alias"
                         autocomplete="on"
                         class="form-control textUserName paddinleft28">
                  <mat-autocomplete #userOptions="matAutocomplete">
                    <mat-option style="border-bottom-style: groove; font-size: 13px; height: 30px;"
                                *ngFor="let user of users ; let index=index" [value]="selectedUserEmailAddress"
                                (onSelectionChange)="selectedUser($event,index)">
                      <div class="font14-black name_txt">{{user.userName}} </div> <div class="mail_txt">{{user.emailAddress}}</div>

                    </mat-option>
                    <div style="margin-left:15px; margin-top:20px; margin-bottom:10px;" *ngIf="isShowmoreButton">
                      <button class="btn btn_primary btn_lg nav_btns_green width_full" (click)="isShowmore()">Show More</button>
                    </div>
                  </mat-autocomplete>
                  <div *ngIf="isNoUserFound">No User Found</div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="full_width paddingbottom-10px">
    <label for="name">User Role</label>
  </div>
  <div class="roles_list">
    <ul>
      <li *ngFor="let role of rolesNcontrols.roles; let i = index">
        <div class="full-width">
          <label class="container-cr">
            <span class="check_txt"></span>
            <input type="checkbox" (change)="selectedRole(role, $event)" [checked]="selectedRoleValue.indexOf(role)>-1">
            <span class="checkmark"></span>
          </label>
          <span class="font14-black txt_check">{{role}}</span>
          <span class="dds-icon_sm dds-icon_arrow-down right" *ngIf="isAccordianClick(role)" (click)="isAccordianOpenClick(role)"></span>
          <span class="dds-icon_sm dds-icon_arrow-up" *ngIf="!isAccordianClick(role)&&(selectedRoleValue.indexOf(role)>-1)" (click)="isAccordianCloseClick(role)"></span>
        </div>
        <div class="sub_list" *ngIf="isAccordianClick(role)">
          <label class="full_width paddingbottom-20px" for="name">Controls for {{role}}</label>
          <div class="controles_list" *ngFor="let control of rolesNcontrols.controls">
            <label class="container-cr">
              <span class="check_txt"> </span>
              <input type="checkbox" (change)="selectedControls(role,control.controlName,$event)" [disabled]="isAdmin(role)" [checked]="isControlChecked(role,control.controlName)">
              <span class="checkmark"> </span>
            </label>
            <span class="font14-black txt_check">{{control.controlDisplayName}}</span>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div class="left popup_bottom_btn">
    <button class="btn btn_primary btn_lg nav_btns_green" style="margin-right:20px" (click)="addUser()" mat-button>{{buttonText}}</button>
    <button class="btn btn_primary btn_lg nav_btns_green" mat-button (click)="closeDialog()">Cancel</button>
  </div>
</div>





