
<div class="container main_container">
  <div class="header_second">
    <div class="full_width">
      <div class="breadscrum_col">
        <ul>
          <li><a [routerLink]="'/home'"> My Dashboard</a></li>
          <li><span class="glyphicon glyphicon-menu-right cool_gray2"></span></li>
          <li>Manage Notification Templates</li>
        </ul>
      </div>
    </div>
    <!--<div class="full_width margintop-20">
      <button class="btn btn_primary btn_lg nav_btns_green" mat-button (click)="createNotificationTemplate()">Create Notification Template</button>
    </div>-->
    <div class="full_width">
      <hr />
    </div>
    <div class="mat-elevation-z8 full_width margintop-20 grid_list">
      <mat-paginator [pageSizeOptions]="[4,6,8,16]" [pageSize]="8"></mat-paginator>
      <div class="table-responsive dashboard_list">
        <table class="responsive-table" mat-table [dataSource]="TemplateDataSource" matSort>

          <ng-container matColumnDef="notificationTemplateId">
            <th style="width:210px;" mat-header-cell *matHeaderCellDef>
              <span>Notification Template Id<span class="dds-icon_sm dds-icon_sort-down sort-down_ico" mat-sort-header></span></span>
              <input (keyup)="applyFilter($event)" placeholder="Search" class="form-control" (focus)="setupFilter('notificationTemplateId')" #notificationTemplateId />
            </th>
            <td mat-cell *matCellDef="let row">{{row.notificationTemplateId}}  </td>
          </ng-container>

          <ng-container matColumnDef="to">
            <th mat-header-cell *matHeaderCellDef>
              <span>To Address<span class="dds-icon_sm dds-icon_sort-down sort-down_ico" mat-sort-header></span></span>
              <input (keyup)="applyFilter($event)" placeholder="Search" class="form-control" (focus)="setupFilter('to')" />
            </th>
            <td mat-cell *matCellDef="let row"> {{row.to}} </td>
          </ng-container>

          <ng-container matColumnDef="cc">
            <th mat-header-cell *matHeaderCellDef>
              <span>CC Address<span class="dds-icon_sm dds-icon_sort-down sort-down_ico" mat-sort-header></span></span>
              <input (keyup)="applyFilter($event)" placeholder="Search" class="form-control" (focus)="setupFilter('cc')" />
            </th>
            <td mat-cell *matCellDef="let row"> {{row.cc}} </td>
          </ng-container>

          <ng-container matColumnDef="subject">
            <th mat-header-cell *matHeaderCellDef>
              <span>Subject<span class="dds-icon_sm dds-icon_sort-down sort-down_ico" mat-sort-header></span></span>
              <input (keyup)="applyFilter($event)" placeholder="Search" class="form-control" (focus)="setupFilter('subject')" />
            </th>
            <td mat-cell *matCellDef="let row"> {{row.subject}} </td>
          </ng-container>

          <!--<ng-container matColumnDef="notificationType">
            <th mat-header-cell *matHeaderCellDef>
              <span>Notification Type<span class="dds-icon_sm dds-icon_sort-down sort-down_ico" mat-sort-header></span></span>
              <input (keyup)="applyFilter($event)" placeholder="Search" class="form-control" (focus)="setupFilter('notificationType')" />
            </th>
            <td mat-cell *matCellDef="let row"> {{row.notificationType}} </td>
          </ng-container>-->

          <ng-container matColumnDef="Edit">
            <th style="width: 15%; text-align: center" mat-header-cell *matHeaderCellDef>Edit</th>
            <td class="align_center" mat-cell *matCellDef="let row">
              <span class="dds-icon_sm dds-icon_edit-24" (click)="editTemplate(row.notificationTemplateId)"></span>
              <!--<a  (click)="editTemplate(row.notificationTemplateId)" style="text-align:center">Edit</a>-->
              <!-- <span class="dds-icon_sm dds-icon_delete" (click)="editTemplate(row.notificationTemplateId)"></span>-->
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="7">No data matching the filter</td>
          </tr>
        </table>

      </div>
    </div>
  </div>
</div>
