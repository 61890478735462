


  <div class="fixed-header">
    <div class="container">
      
      <div class="header_second full_width">
        <div class="left">
          <h1>{{controlDisplayName}}</h1>
        </div>
        <div class="right">
          <button type="button" style="color:#000 !important" class="btn btn-primary btns_download" (click)="downloadDashboardDetailsControls()" *ngIf="hasAccess('general','cf/report/download')"><b>Download Report</b></button>
        </div>
        <div class="right" style="margin-right:30px" *ngIf="this.showCheckbox"  >
          <input type="checkbox" (change)="selectionChanged($event)" [checked]="this.isChecked" [disabled]="isDisable()" />
          <label style="margin-left:10px">No new records found</label>
          <span class="dds-icon_sm dds-icon_check check_icon" (click)="save()" ></span>
        </div>        
      </div>

        <div class="full_width">
          <div class="right">

            <div class="left">

              <h2 style="margin-right:15px;">Previous Runs </h2>
            </div>

            <div class="right">
              <select #ddlRunPeriod (change)='onRunPeriodChanged(ddlRunPeriod.value)'>
                <option *ngFor="let runPeriod of runPeriods" [value]="runPeriod.runTimestamp" [selected]="runPeriod.runTimestamp == selectedRunTimestamp">{{runPeriod.name}}</option>
              </select>
            </div>
          </div>
        </div>


        <div class="full_width pro_grid" *ngIf="lastRunAdditionalData.length>1">
          <ul class="nav nav-tabs details_tab">
            <li *ngFor="let runData of lastRunAdditionalData" [ngClass]="(runData.sourceName == sourceName)?'active':'rrr'"><a data-toggle="tab" (click)="onSourceClicked(runData.sourceName)"> {{runData.sourceDisplayName}}</a></li>
          </ul>
        </div>



    </div>
  </div>
  <div class="container main_container" style="padding-top:48px;">

    <div class="full_width margintop-20 dashboard_grid" style="margin-top:135px;">

      <div class="full_width pro_grid"   *ngIf="this.errorMsg==''">
        <!--<div *ngIf="dataSource.length>0">-->
        <article>
          <div class="mat_fixed_header" style="position:relative;z-index:1;">
            <table mat-table [dataSource]="dataSource" matSort>
              <ng-container matColumnDef="{{col}}" *ngFor="let col of displayedColumns">
                <th mat-header-cell *matHeaderCellDef>
                  <span >{{col}}<span class="dds-icon_sm dds-icon_sort-down sort-down_ico" mat-sort-header></span></span>


                  <input (keyup)="applyFilter($event)" placeholder="Search" class="form-control" (focus)="setupFilter(col)" #userName />
                </th>
                <td mat-cell *matCellDef="let element">
                  {{element[col]}}
                </td>
              </ng-container>

              <!--<ng-container matColumnDef="ID">
    <th mat-header-cell *matHeaderCellDef class="d_col-1">
      <span mat-sort-header>ID<span class="dds-icon_sm dds-icon_sort-down sort-down_ico"></span></span>

      <input (keyup)="applyFilter($event)" placeholder="Search" class="form-control" (focus)="setupFilter('ID')" #userName />
    </th>
    <td mat-cell *matCellDef="let row">{{row.ID}} </td>
  </ng-container>-->

              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

              <!-- Row shown when there is no matching data. -->
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="7">No data matching the filter</td>
              </tr>

            </table>
          </div>
        </article>
      </div>
    </div>
  </div>
   
    <h2 *ngIf="this.errorMsg!=''" class="center-message" >{{this.errorMsg}}</h2>


