
<div class="container main_container">
  <form (ngSubmit)="onSubmit()" [formGroup]="form">
    <div class="uploads_f">
      <div class="row margintop-20">
        <div class="col-lg-4">
          <label class="align_r"> Control Name</label>
        </div>
        <div class="col-lg-8">
          <select class="form-control" (change)="onControlChange($event)" formControlName="controlName">
            <option value="" disabled>--Select--</option>
            <option *ngFor="let control of allControlMetaData" value="{{control.controlName}}">{{control.controlDisplayName}}</option>
          </select>
          <app-field-error-display [displayError]="isFieldValid('controlName')" errorMsg="Control Name is Mandatory">
          </app-field-error-display>
        </div>
      </div>
      <div class="row margintop-20 full-width" *ngIf="metaData && metaData.collectPhase.source.length>1">
        <div class="col-lg-4"> <label class="align_r">Source</label> </div>
        <div class="col-lg-8">
          <div *ngFor="let source of metaData.collectPhase.source" style="margin-right:10px">
            <label class="container-rad">
              <span class="check_txt"></span>
              <input type="radio" name="sourceName"
                     formControlName="sourceName"
                     [value]="source.sourceName"
                     (click)="onSourceChange(source.sourceName)">
              <span class="checkmark"></span>
            </label>
            <span class="font14-black txt_check">{{source.sourceName}}</span>
          </div>
          <app-field-error-display [displayError]="isFieldValid('sourceName')" errorMsg="Please select source">
          </app-field-error-display>
        </div>

      </div>
      <div *ngIf="metaData && metaData.collectPhase.source.length>0 && this.form.controls['sourceName'].value">
        <div *ngFor="let source of metaData.collectPhase.source">
          <div class="row margintop-20" *ngIf="this.form.controls['sourceName'].value==source.sourceName">
            <div class="col-lg-4">
              <!--<label class="align_r">{{source.sourceName}} Details</label>-->
            </div>
            <div class="col-lg-8">
              <label>Please upload the Data feed file</label>
              <div class="custom-file mb-3">
                <input type="file" [formControlName]="source.sourceName" #fileUploader class="custom-file-input" (change)="handleUpload($event, source.sourceName)" />
                <label class="custom-file-label" for="customFile">Choose file to upload</label>
              </div>
              <div>{{this.form.controls['fileName'].value}}</div>
              <span *ngIf="this.form.controls[source.sourceName].errors?.fileSizeValidator" class="validation-msg">
                Maximum allowed size is 5MB
              </span>
              <app-field-error-display [displayError]="isFieldValid('fileName')" errorMsg="Please upload excel">
              </app-field-error-display>
            </div>
          </div>
        </div>
      </div>
      <div class="row margintop-20">
        <div class="col-lg-4">
        </div>
        <div class="col-lg-2">
          <button type="submit" class="btn btn-success">Submit</button>
        </div>
        <div class="col-lg-2">
          <button type="submit" class="btn btn-default" [routerLink]="'/home'">Cancel</button>
        </div>
      </div>
    </div>

  </form>
</div>

