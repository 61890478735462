import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators, FormArray, FormBuilder, ValidatorFn } from '@angular/forms';

import { AttestationForm,  VendorDetails } from '../models/question-base'
import { QuestionType, Question } from '../../manage-questionnaire/models/questionnaire';

@Injectable()
export class QuestionControlService {
  constructor(private fb: FormBuilder) { }
  toFormGroup(questions: AttestationForm[]) {
    const group: any = {};
    questions.forEach(question => {
      const disabled = false; // question.status != 1 ? true :
      if (question.questionType == QuestionType.checkbox) {
        const checkboxGroup: any = {}
        question.options.forEach(option => {
          checkboxGroup[question.questionId + "-" + option.id] = new FormControl({ value: question.answers && question.answers.indexOf(option.id) != -1 ? true : false, disabled: disabled });
        });
        group[question.questionId] = new FormGroup(checkboxGroup, requireCheckboxesToBeCheckedValidator())
      }
      else if (question.questionType == QuestionType.grid) {

        if (question.vendorDetails) {
          const gridArray = new FormArray([]);
          question.vendorDetails.forEach(vendor => {
            let isNew = false;
            if (!vendor.vendorName || !vendor.deviceType || !vendor.deloitteContactName)
              isNew = true;
            const gridFormGroup = new FormGroup({
              vendorName: new FormControl({ value: vendor.vendorName || "", disabled: disabled }, [Validators.required]),
              deviceType: new FormControl({ value: vendor.deviceType || "", disabled: disabled }, [Validators.required]),
              deloitteContactName: new FormControl({ value: vendor.deloitteContactName || "", disabled: disabled }, [Validators.required]),
              deloitteContactId: new FormControl({ value: vendor.deloitteContactId || "", disabled: true }),
              additionalInfo: new FormControl({ value: vendor.additionalInfo || "", disabled: disabled }),
              isNew: new FormControl(isNew),
              isEdit: new FormControl(isNew),
              diligenceQuestionId: new FormControl(vendor.diligenceQuestionId),
              isVisible: new FormControl(vendor.isVisible)
            });
            gridArray.push(gridFormGroup);
          });
          group[question.questionId] = gridArray;
        }
      }
      else if (question.questionType == QuestionType.file) {
        group[question.questionId] = question.isMandatory ? new FormControl({ value: question.attachment || "", disabled: disabled },
          [Validators.required, requiredFileType(['png', 'jpeg', 'jpg', 'docx', 'pptx', 'pdf'])])
          : new FormControl({ value: question.answers || "", disabled: disabled },
            [requiredFileType(['png', 'jpeg', 'jpg', 'docx', 'pptx', 'pdf'])]);
      }
      else {
        group[question.questionId] = question.isMandatory ? new FormControl({ value: question.answers || "", disabled: disabled }, [Validators.required])
          : new FormControl({ value: question.answers || '', disabled: disabled });
      }
    });
    return new FormGroup(group);
  }
}

export function requireCheckboxesToBeCheckedValidator(minRequired = 1): ValidatorFn {
  return function validate(formGroup: FormGroup) {
    let checked = 0;
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.controls[key];

      if (control.value == true) {
        checked++;
      }
    });

    if (checked < minRequired) {
      return {
        requireOneCheckboxToBeChecked: true,
      };
    }

    return null;
  };
}

export function requiredFileType(type: Array<string>) {
  return function (control: FormControl) {
    let file = control.value;
    if (file.name)
      file = file.name;
    if (file) {
      if (file.indexOf('.') != -1) {
        var arr = file.split('.');
        const extension = arr[arr.length - 1].toLowerCase();
        if (type.indexOf(extension.toLowerCase()) == -1) {
          return {
            requiredFileType: true
          };
        }
      }
      else {
        return {
          requiredFileType: true
        };
      }
      return null;
    }
    return null;
  };
}
export function fileSizeValidator(file: File, size: number = 5) {
  return function (control: FormControl) {
    if (file) {
      const fileSize = file.size;
      const fileSizeinMB = Math.round(fileSize / 1024 / 1024);
      if (fileSizeinMB > size) {
        return {
          fileSizeValidator: true
        };
      } else {
        return null;
      }
    }
    return null;
  };
}
export function questionOptionsValidator(question: Question) {
  return function (control: FormControl) {
    if (question) {
      if (question.options ==null || question.options.length==0) {
        return {
          questionOptionsValidator: true
        };
      } else {
        return null;
      }
    }
    return null;
  };
}

