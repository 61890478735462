import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { QuestionnaireService } from './services/questionnaire.service';
import { Questionnaire, Question, QuestionType, QuestionOptions } from './models/questionnaire';
import { CommonService } from '../shared/common-services/common.service';
import { of } from 'rxjs';
import { SpinnerService } from '../shared/spinner/spinner.service';
import { ToastrService } from 'ngx-toastr';
import { questionOptionsValidator } from '../dynamic-form-components/services/question-control.service';
import { MasterData } from '../dynamic-form-components/models/question-base';
import { DashboardService } from '../dashboard/services/dashboard.service';

declare var _satellite: any;
declare var analyticsDataLayer: any;

@Component({
  selector: 'app-manage-questionnaire',
  templateUrl: './manage-questionnaire.component.html',
  styleUrls: ['./manage-questionnaire.component.scss'],
  providers: [QuestionnaireService]
})
export class ManageQuestionnaireComponent implements OnInit {
  form?: FormGroup;
  questionnaires: Questionnaire[];
  controls: MasterData[];
  questionnaire: Questionnaire;
  questionType = QuestionType;
  questionTypes =
    [
      {
        "Text": "Single Choice",
        "Value": QuestionType.radio
      },
      {
        "Text": "Multi Choice",
        "Value": QuestionType.checkbox
      },
      {
        "Text": "Dropdown",
        "Value": QuestionType.dropdown
      },
      {
        "Text": "File Upload",
        "Value": QuestionType.file
      },
      {
        "Text": "Short Answer",
        "Value": QuestionType.text
      },
      {
        "Text": "Paragraph",
        "Value": QuestionType.textarea
      }
    ];
  errorMessage = "This field is Mandatory";
  successMessage: any;
  isNewForm: boolean = false;
  loggedInUser: any;
  controlNames = [];
  constructor(private qs: QuestionnaireService, private fb: FormBuilder, private commonService: CommonService, private spinnerService: SpinnerService, private toastr: ToastrService, private dashboardService: DashboardService) { }

  ngOnInit(): void {
    this.loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    //this.getQuestionnaires();
    this.getControlNames();
    this.form = this.fb.group({
      controlName: new FormControl("", [Validators.required]),
      displayName: new FormControl("", [Validators.required])
    });
    this.trackOmniture('ManageQuestionnaire');
  }

  trackOmniture(pageName: string) {
    let loggedInUser;
    if (!loggedInUser)
      loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

    if (loggedInUser && loggedInUser.userName) {
      analyticsDataLayer.pageInfo.pageName = pageName;
      analyticsDataLayer.pageInfo.userID = loggedInUser.userName.split('@')[0];
      _satellite.track("pageview");
    }
  }


  getQuestionnaires() {
    this.spinnerService.showSpinner();
    this.qs.getQuestionnaires().subscribe(data => {
      this.questionnaires = data;
      this.spinnerService.hideSpinner();
    },
      (err: any) => {
        this.toastr.error(err);
        this.spinnerService.hideSpinner();
      }
    );
  }
  getControlNames() {
    this.spinnerService.showSpinner();
    this.commonService.getMasterData(["Control"]).subscribe((result: any) => {
      if (result && result["Control"].length > 0) {
        this.controls = result["Control"];
      }
      this.spinnerService.hideSpinner();
    },
      (err: any) => {
        this.toastr.error(err);
        this.spinnerService.hideSpinner();
      }
    );
  }
  getQuestionnaire() {
    this.spinnerService.showSpinner();
    let controlName = this.form?.controls["controlName"].value;
    this.qs.getQuestionnaireByControl(controlName).subscribe(data => {
      this.questionnaire = data;
      if (!this.questionnaire.questionnaireId)
        this.createNewQuestionnaire();
      else
        this.createForm();
      this.spinnerService.hideSpinner();
    },
      (err: any) => {
        this.toastr.error(err);
        this.spinnerService.hideSpinner();
      }
    );
  }
  createNewQuestionnaire(isNew = true) {
    this.isNewForm = true;
    if (isNew) {
      this.questionnaire = new Questionnaire();
      this.questionnaire.questionnaireId = this.createGuid();
    }
    this.questionnaire.createdBy = this.loggedInUser.name;
    this.questionnaire.questions = new Array<Question>();
    this.form.controls["displayName"].setValue("");
  }
  sortQuestions() {
    of((this.questionnaire.questions.sort((a, b) => a.questionOrder - b.questionOrder))).subscribe(sortedQuestions => {
      this.questionnaire.questions = sortedQuestions;
    });
  }
  createForm() {
    if (this.questionnaire.questions.length > 0) {
      this.form.controls["displayName"].setValue(this.questionnaire.questions[0].controlDisplayName);
      this.questionnaire.questions.forEach((question) => {
        //no validation requried for question type grid and diclaimer question
        if (question.questionType == QuestionType.grid || (question.questionType == QuestionType.checkbox && question.options && question.options.length == 1)) {
          this.form.addControl(question.questionId, new FormControl(question.questionText));
        }
        else {
          if (question.questionType == QuestionType.radio || question.questionType == QuestionType.checkbox || question.questionType == QuestionType.dropdown) {
            this.form.addControl(question.questionId, new FormControl(question.questionText, [Validators.required, questionOptionsValidator(question)]));
          } else {
            this.form.addControl(question.questionId, new FormControl(question.questionText, [Validators.required]));
          }
        }
        this.form.addControl(question.questionId + "_required", new FormControl(question.isMandatory));
        if (question.options && question.options.length > 0) {
          question.options.forEach((option) => {
            this.form.addControl(question.questionId + "_" + option.id, new FormControl(option.name, [Validators.required]));
          });
        }
      });
    }
  }
  onControlChange(event: any) {
    this.getQuestionnaire();
  }
  addOption(question: Question) {
    let id = 1;
    if (question.options.length > 0) {
      id = question.options.length + 1;
    }
    let option = new QuestionOptions();
    option.id = id.toString();
    question.options.push(option);
    this.form.addControl(question.questionId + "_" + option.id, new FormControl(option.name, [Validators.required]));
  }
  addQuestion() {
    let id = 1;
    let maxOrder = 0;
    let question = new Question();
    if (this.questionnaire.questions.length > 0) {
      question = Object.assign({}, this.questionnaire.questions[0]) as Question; // Object.create(this.questionnaire.questions[0]) as Question;
      maxOrder = Math.max.apply(Math, this.questionnaire.questions.map((q) => q.questionOrder))
    }
    question.isNewQuestion = true;
    question.questionId = this.createGuid();
    question.questionText = "";
    question.questionOrder = maxOrder + 100;
    question.options = [];
    question.questionType = 0;
    question.questionNumber = this.questionnaire.questions.length + 1;
    this.questionnaire.questions.push(question);
    this.form.addControl(question.questionId, new FormControl(question.questionText, [Validators.required]));
    this.form.addControl(question.questionId + "_type", new FormControl("", [Validators.required]));
    this.form.addControl(question.questionId + "_required", new FormControl(""));
  }
  removeQuestion(question: Question) {
    this.questionnaire.questions.splice(this.questionnaire.questions.indexOf(question), 1);
    this.form.removeControl(question.questionId);
    this.form.removeControl(question.questionId + "_type");
    this.form.removeControl(question.questionId + "_required");
    if (question.options && question.options.length > 0) {
      question.options.forEach((option) => {
        this.form.removeControl(question.questionId + "_" + option.id);
      });
    }
  }
  removeOption(question: Question, option: QuestionOptions) {
    question.options.splice(question.options.indexOf(option), 1);
    this.form.removeControl(question.questionId + "_" + option.id);
  }
  sortQuestion(index: number, question: Question, isUp: boolean) {
    let tempSortOrder = question.questionOrder;
    let tempQuestionNumber = question.questionNumber;
    if ((index == 0 && isUp) || (index == this.questionnaire.questions.length - 1 && !isUp)) {
      return;
    }
    if (isUp) {
      let previousQuestion = this.questionnaire.questions[index - 1];
      question.questionOrder = previousQuestion.questionOrder;
      previousQuestion.questionOrder = tempSortOrder;

      question.questionNumber = previousQuestion.questionNumber;
      previousQuestion.questionNumber = tempQuestionNumber;
    }
    else {
      let nextQuestion = this.questionnaire.questions[index + 1];
      question.questionOrder = nextQuestion.questionOrder;
      nextQuestion.questionOrder = tempSortOrder;

      question.questionNumber = nextQuestion.questionNumber;
      nextQuestion.questionNumber = tempQuestionNumber;;
    }
  }
  onQuestionTypeChange(question: Question) {
    question.options = [];
    question.questionType = parseInt(this.form.controls[question.questionId + "_type"].value);
    if (question.questionType == QuestionType.radio || question.questionType == QuestionType.checkbox || question.questionType == QuestionType.dropdown) {
      this.addOption(question);
      this.form?.controls[question.questionId].setValidators([questionOptionsValidator(question)]);
    }
  }

  createGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

  isFieldValid(field: string) {
    return !this.form.get(field).valid && this.form.get(field).touched && this.form.get(field).enabled && !this.form.get(field).value;
  }
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }
  saveAsDrat() {
    this.successMessage = "Saved Successfully";
    this.questionnaire.isActive = false;
    this.questionnaire.isDraft = true;
    if (this.form.valid) {
      this.saveQuestionnaire();
    }
    else
      this.validateAllFormFields(this.form);
  }
  onSubmit() {
    this.successMessage = "Published Successfully";
    this.questionnaire.isActive = true;
    this.questionnaire.isDraft = false;
    if (this.form.valid) {
      this.saveQuestionnaire();

    }
    else
      this.validateAllFormFields(this.form);
  }
  saveQuestionnaire() {
    this.sortQuestions();
    this.questionnaire.lastupdatedBy = this.loggedInUser.name;
    this.questionnaire.controlName = this.form.controls["controlName"].value;
    this.questionnaire.questions.forEach((question: Question) => {
      question.questionnaireId = this.questionnaire.questionnaireId;
      question.controlName = this.form.controls["controlName"].value;
      question.controlDisplayName = this.form.controls["displayName"].value;
      question.questionText = this.form.controls[question.questionId].value;
      question.isMandatory = this.form.controls[question.questionId + "_required"].value == "" ? false : this.form.controls[question.questionId + "_required"].value
      if (question.options && question.options.length > 0) {
        question.options.forEach((option: QuestionOptions) => {
          option.name = this.form.controls[question.questionId + "_" + option.id].value;
        });
      }
      if (question.isNewQuestion) {
        question.questionType = parseInt(this.form.controls[question.questionId + "_type"].value);
      }
    });
    this.spinnerService.showSpinner();
    this.qs.saveQuestionnaire(this.questionnaire).subscribe(data => {
      var timestamp = data;      
      this.spinnerService.hideSpinner();
      this.controlNames.push(this.questionnaire.controlName)
      this.dashboardService.getAllDashboardControls(this.controlNames).subscribe((result: any) => {
        if (timestamp != null) {
          result[0].lastRunAdditionalInfo[0].lastRunTimeStamp = timestamp;
        }
        var dashboard = result[0];
        this.dashboardService.addDashboard(dashboard).subscribe((res: any) => {
          this.toastr.success(this.successMessage);
        });
      });
    },
      (err: any) => {
        this.toastr.error(err);
        this.spinnerService.hideSpinner();
      }
    );
  }

}

