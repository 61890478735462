<div class="container main_container">
  <div class="header_second">
    <div class="full_width">
      <div class="breadscrum_col">
        <ul>
          <li>My Dashboard</li>

          <li><span class="glyphicon glyphicon-menu-right cool_gray2"></span></li>

          <li>User management</li>

        </ul>

      </div>
    </div>
    <div class="full_width margintop-20">
      <h1>
        Attestation Form
      </h1>
      <p class="red">Overdue</p>
    </div>

    <div class="full_width margintop-20 attestation-form marginbottom-20">
      <ul class="nav nav-tabs">
        <li class="active"><a data-toggle="tab" href="#home">Attestation overview</a></li>
        <li><a data-toggle="tab" href="#menu1">Previous attestation</a></li>
      </ul>


      <div class="attestation_container">
        <div class="tab-content margintop-10">

          <div id="home" class="tab-pane fade in active">

            <div class="container800">
      
            <div class="question_row">
              <div class="full_width">
                <div class="question_msg">Mandatory</div>
                <h2>
                  Please confirm if any New Technology is being used for Network Assets in the past 4 months
                </h2>
              </div>
              <div class="full_width margintop-20">
                <div>
                  <label class="container-rad">
                    <span class="check_txt"></span>
                    <input type="radio" checked="checked" name="radio">
                    <span class="checkmark"></span>
                  </label>
                  <span class="font14-black txt_check">Yes</span>
                </div>

                <div>
                  <label class="container-rad">
                    <span class="check_txt"></span>
                    <input type="radio" name="radio">
                    <span class="checkmark"></span>
                  </label>
                  <span class="font14-black txt_check">No</span>
                </div>

              </div>
            </div>

            <div class="question_row">
              <div class="full_width">
                <h2>
                  If yes, please specify the technology used
                </h2>
                <div class="margintop-20">
                  <button _ngcontent-dnw-c88="" class="btn btn_primary btn_lg addnew_btn">
                    Add User
                    <span class="dds-icon_sm dds-icon_plus"></span>
                  </button>

                </div>
              </div>
            </div>


            <div class="question_row">
              <div class="full_width">
                <h2>
                  If a new vendor is being utilized, what type of due diligence was conducted to validate the Network Asset is not manufactured by or incorporates from any of the Prohibited technology? Please describe the due diligence process
                </h2>
                <p class="margintop-20 marginbottom-0">
                  Description
                </p>
              </div>
              <div class="full_width">
                <textarea id="" class="txt_area form-control" name="" rows="4" cols="100%">
  At w3schools.com you will learn how to make a website. They offer free tutorials in all web development technologies.
  </textarea>
              </div>
            </div>

            <div class="question_row">
              <div class="full_width">
                <div class="question_msg">Mandatory</div>
                <h2>
                  If, yes, please identify the vendor below
                </h2>
              </div>


              <div class="full_width margintop-20">
                <div class="form-group">
                  <label for="usr">Vendor:</label>
                  <select class="form-control form_txt" id="">
                    <option>My vendor Name</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                  </select>
                </div>
              </div>

            </div>

            <div class="question_row">
              <div class="full_width">

                <h2>
                  Risus orci convallis neque, sit ut mauris egestas
                </h2>
              </div>


              <div class="full_width margintop-20">
                <div class="form-group">
                  <label for="usr">Placeholder:</label>
                  <input type="text" class="form-control form_txt">
                </div>
              </div>

            </div>

            <div class="question_row">
              <div class="full_width">
                <label class="container-cr">
                  <span class="check_txt"></span>
                  <input type="checkbox">
                  <span class="checkmark"></span>
                </label>
                <span class="font14-black">
                  I, hereby declare that the above form has been attested and verified for further investigations
                </span>
              </div>
            </div>


            <div class="full_width margintop-30">
              <div class="right">
                <button type="button" class="btn disable_btn">Submit</button>
              </div>
              </div>

              </div>
            </div>
            <div id="menu1" class="tab-pane fade">
              <div class="full_width margintop-20">
                <div class="table-responsive">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th class="font12-regular">
                          <div class="cool_gray11">
                            Attestation date
                          </div>
                        </th>
                        <th class="font12-regular">
                          <div class="cool_gray11">Attested by</div>
                        </th>
                        <th class="font12-regular">
                          <div class="cool_gray11">Attestation status</div>
                        </th>
                        <th class="font12-regular">
                          <div class="cool_gray11 align_center">Actions</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="black">23/08/2020</td>
                        <td class="black">kamile.rubio@deloitte.com</td>
                        <td class="black">Approved</td>
                        <td class="align_center">
                          <span class="dds-icon_sm dds-icon_eyeopen"></span>
                        </td>
                      </tr>
                      <tr>
                        <td class="black">23/08/2020</td>
                        <td class="black">kamile.rubio@deloitte.com</td>
                        <td class="black">Approved</td>
                        <td class="align_center">
                          <span class="dds-icon_sm dds-icon_eyeopen"></span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                </div>
              </div>
          </div>
        </div>
      </div>


      <!--<div class="full_width margintop-20">
   <div class="attestation_container">
     testst
   </div>
  </div>-->
    </div>
</div>
