
<div class="container main_container">
  <div class="header_second">
    <div class="full_width">
      <div class="breadscrum_col">
        <ul>
          <li><a [routerLink]="'/home'"> My Dashboard</a></li>
          <li><span class="glyphicon glyphicon-menu-right cool_gray2"></span></li>
          <li>User Management</li>
        </ul>
      </div>
    </div>
    <div class="full_width margintop-20">
      <button class="btn btn_primary btn_lg nav_btns_green" mat-button (click)="openDialog('')" *ngIf="hasAccess('general','access_adduser')" [disabled]="!hasAccess('general','access_adduser')">Create User</button>

    </div>
    <div class="full_width">
      <hr />
    </div>

    <div class="mat-elevation-z8 full_width margintop-10 grid_list">
      <mat-paginator [pageSizeOptions]="[2, 4, 6, 8]" [pageSize]="8"></mat-paginator>
      <div class="table-responsive">
        <table mat-table [dataSource]="UsersDataSource" matSort>

          <!-- Name Column -->
          <ng-container matColumnDef="userName">
            <th mat-header-cell *matHeaderCellDef>
              <span>User Name<span class="dds-icon_sm dds-icon_sort-down sort-down_ico" mat-sort-header></span></span>
              <input (keyup)="applyFilter($event)" placeholder="Search" class="form-control" (focus)="setupFilter('userName')" #userName />
            </th>
            <td mat-cell *matCellDef="let row"><a (click)="openDialog(row.alias)">{{row.userName}}</a>  </td>
          </ng-container>

          <!-- Role Column -->
          <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef>
              <span>User Role<span class="dds-icon_sm dds-icon_sort-down sort-down_ico" mat-sort-header></span></span>
              <input (keyup)="applyFilter($event)" placeholder="Search" class="form-control" (focus)="setupFilter('role')" />
            </th>

            <td mat-cell *matCellDef="let row"> {{row.role}} </td>
          </ng-container>

          <!-- updatedBy Column -->
          <ng-container matColumnDef="updatedBy">
            <th mat-header-cell *matHeaderCellDef>
              <span>Last Updated By<span class="dds-icon_sm dds-icon_sort-down sort-down_ico" mat-sort-header></span></span>
              <input (keyup)="applyFilter($event)" placeholder="Search" class="form-control" (focus)="setupFilter('updatedBy')" />
            </th>
            <td mat-cell *matCellDef="let row"> {{row.updatedBy}} </td>
          </ng-container>

          <!-- updatedOn Column -->
          <ng-container matColumnDef="updatedOn">
            <th mat-header-cell *matHeaderCellDef>
              <span>Last Updated On<span class="dds-icon_sm dds-icon_sort-down sort-down_ico" mat-sort-header></span></span>
              <input (keyup)="applyFilter($event)" placeholder="Search" class="form-control" (focus)="setupFilter('updatedOn')" />
            </th>
            <td mat-cell *matCellDef="let row"> {{row.updatedOn | date:'MM-dd-yyyy'}} </td>
          </ng-container>
          <ng-container matColumnDef="Action">
            <th style="width:15%;" mat-header-cell *matHeaderCellDef>Delete</th>
            <td class="align_center" mat-cell *matCellDef="let row">
              <span class="dds-icon_sm dds-icon_delete" (click)="deleteUserRole(row.alias,row.role)"></span>
            </td>
          </ng-container>
          <
          <!--ng-container>
            <th>
              <span>Action</span>
            </th>
            <td>
              <span class="dds-icon_sm dds-icon_delete" (click)="DeleteUserRole(user.alias,user.role)"></span>
            </td>
          </ng-container>-->

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No User Found</td>
          </tr>
        </table>
      </div>

    </div>

    <div class="full_width footer_page">

    </div>
  </div>

</div>











