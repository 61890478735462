import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { CommonService } from '../../shared/common-services/common.service';
import { ApiService } from '../../shared/common-services/api.service';
import { Table } from '../models/table';
import { RunPeriod } from '../models/run-period';
import { ControlData } from '../models/control-data';
import { ConfigService } from '../../shared/common-services/config.service';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class DashboardService extends ApiService {
 

  constructor(protected router: Router, protected httpClient: HttpClient, protected config: ConfigService, protected authService: MsalService, protected commonService: CommonService) {
    super(router, httpClient, config, authService);
  }
  dashboardData: any;
  getAllDashboardControls(groupNames:any) {
      return this.post(environment.apiUrl + "/api/Dashboard/GetAllDashboardControls", groupNames);
  }

  addDashboard(dashboard: any) {
    return this.post(environment.apiUrl + "/api/Dashboard", dashboard);
  }

  reprocess(reprocessAdditionalInfo: any) {
    return this.post(environment.apiUrl + "/api/Dashboard/Reprocess", reprocessAdditionalInfo);
  }

  getControlMetaData(controlName: string): Observable<any> {
    let url = "/api/ControlMetadata/" + controlName;
    return this.get<any>(url)
  }

  getAllControlMetaData(): Observable<any> {
    let url = "/api/ControlMetadata/GetAll";
    return this.get<any>(url);
  }

  updateAttestationStatus(control: string, status: string): Observable<any> {
    let url = "/api/Dashboard/UpdateAttestationStatus?controlName=" + control + "&status=" + status;
    return this.post<any>(url);
  }

  getDashboardDetails(controlName: string, runTimestamp: string, sourceName: string): Observable<Table> {
    let url = "/api/DashboardDetails/" + controlName + "/" + runTimestamp + "/" + sourceName;
    return this.get<Table>(url);
  }

  getRunPeriods(controlName: string, sourceName: string): Observable<RunPeriod[]> {
    let url = "/api/DashboardDetails/runperiod/" + controlName + "/" + sourceName + "/true";
    return this.get<RunPeriod[]>(url);
  }
  downloadDashboardControls(controlList: ControlData[]) {
    let url = "/api/Dashboard/DownloadDashboardControls";
    return this.post<any>(url, controlList);
  }

  downloadDashboardDetailsControls(controlName: string, runTimestamp: string, sourceName: string) {
    let url =  "/api/DashboardDetails/DownloadDashboardDetailsControls/" + controlName + "/" + runTimestamp + "/" + sourceName;
    return this.get<any>(url);
  }

  getMasterData(type: string): Observable<any> {
    let url = '/api/MasterData/' + type
    return this.get<any>(url);
  }

  addRunperiod(runperiod: any) {
    let url = "/api/DashboardDetails/runperiod";
    return this.post<any>(url, runperiod);
   // return this.post(environment.apiUrl + "/api/DashboardDetails/runperiod", runperiod);
  }

  deleteDashboardDetailsData(controlName: string, runTimestamp: string, sourceName: string) {
    return this.delete('/api/DashboardDetails/delete/CompleteDetails/' + controlName + '/'  + runTimestamp + '/' + sourceName);
  }
}


