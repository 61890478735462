<nav class="header navbar-header">
  <div class="container">
    <div class="full_width">
      <div class="navbar-header">
        <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#myNavbar">
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <div class="deloite-logo">

          <img src="/assets/images/deloitte.svg" class="logo-deloitte" width="112" height="17" alt="Deloitte India">
          <span> |</span>
        </div>

        <div class="application-logo">
          <a id="MainCya" class="header__brand header_link">TCTMD</a>
          <!--<span class="header__brand"> CYA</span>-->
        </div>
      </div>
      <div class="top_nav">
        <div class="collapse navbar-collapse" id="myNavbar">
          <ul class="nav navbar-nav">
            <li [routerLinkActive]="['active']"><a routerLink="home" (click)="hideNav()">My Dashboard</a></li>
            <!--<li><a routerLink="dashboard">My dashboard</a></li>-->
            <li class="dropdown drop_1">
              <a class="dropdown-toggle" data-toggle="dropdown" href="#" *ngIf="hasAccess('Procurement','submit')||hasAccess('VendorCertification','submit')||hasAccess('general','um/user/readall')||hasAccess('general','role/action/readall')||hasAccess('general','banned/entity/edit')||hasAccess('general','ma/Attestation/read')" >Administration Controls<span class="caret"></span></a>
              <ul class="dropdown-menu">
                <!--  <li><a routerLink="manageattestation" (click)="hideNav()" *ngIf="hasAccess('general','ma/Attestation/read')">Manage Attestation Forms</a></li>-->
                <li><a routerLink="UserManagement" (click)="hideNav()" *ngIf="hasAccess('general','um/user/readall')">User Management</a></li>
                <li><a routerLink="common/fileupload" (click)="hideNav()" *ngIf="hasAccess('Procurement','submit')||hasAccess('VendorCertification','submit')">Common File Upload</a></li>
                <li><a routerLink="UserRoleManagement" *ngIf="hasAccess('general','role/action/readall')">User Role Permission</a></li>
                <li><a routerLink="ManageBannedEntity" *ngIf="hasAccess('general','banned/entity/edit')">Manage Banned Entity</a></li>
                <li><a routerLink="ManageQuestionnaire" *ngIf="hasAccess('general','ma/Attestation/read')">Manage Attestation Form</a></li>
                <!--<li><a href="#">Role Management</a></li>-->
                <!--*ngIf="hasAccess('Procurement','submit')||hasAccess('VendorCertification','submit')"-->
                <!--<li><a routerLink="emailtemplate">Email Template</a></li>-->
                <li><a routerLink="NotificationDashboard" *ngIf="hasAccess('general','mg/notification/template')">Manage Notification Templates</a></li>
                <li><a routerLink="managecontrolmetadata" *ngIf="hasAccess('general','mg/controlmetadata')">Manage Control Metadata</a></li>
              </ul>
            </li>
            <!--<li><a routerLink="procurement">Procurement</a></li>-->
            <!--<li [routerLinkActive]="['active']"><a routerLink="users">User management</a></li>-->


          </ul>

          <!--<ul class="nav navbar-nav navbar-right">
      <li>
        <div class="Userpick">
          <div class="Userpick_img">
            <span class="glyphicon glyphicon-user"></span>
          </div>
        </div>
      </li>
    </ul>-->



        </div>
        <!--<div class="profile_col">
          <div class="Userpick">
            <div class="Userpick_img">
              <span class="dds-icon_sm dds-icon_user"></span>
            </div>
          </div>
        </div>-->
      </div>

     

   



      </div>
    </div>
  </nav>
