import { Component, OnInit } from '@angular/core';
import { CommonService } from '../common-services/common.service';

@Component({
  selector: 'app-siteheader',
  templateUrl: './site-header.component.html',
  styleUrls: ['./site-header.component.scss']
})
export class SiteheaderComponent implements OnInit {

  constructor(private commonService: CommonService) { }

  hideNav() {
    var element = document.getElementById("myNavbar");
    element.classList.remove("in");
  }

  ngOnInit(): void {
  }

  hasAccess(groupName: string, securityAction: string): boolean {
    return this.commonService.hasAccess(groupName,securityAction);
  }
}
