import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { CommonService } from '../../shared/common-services/common.service';
import { ApiService } from '../../shared/common-services/api.service';
import { ConfigService } from '../../shared/common-services/config.service';
import { ControlData } from '../../dashboard/models/control-data';
import { controlmetadata } from '../models/control-metadata';

@Injectable({
  providedIn: 'root'
})
export class ManagecontrolmatadataService extends ApiService{

  constructor(protected router: Router, protected httpClient: HttpClient, protected config: ConfigService, protected authService: MsalService, protected commonService: CommonService) {
    super(router, httpClient, config, authService);
  }

  UpdateDeshboardControl(controlList: controlmetadata) {
    let url = "/api/Dashboard";
    return this.post<any>(url, controlList);
  }
}
