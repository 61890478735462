<div>

  <div class="container main_container">
    <div class="header_second">
      <div class="full_width">
        <div class="breadscrum_col">
          <ul>
            <li><a [routerLink]="'/home'"> My Dashboard</a></li>

            <li><span class="glyphicon glyphicon-menu-right cool_gray2"></span></li>

            <li>{{controlDesc}}</li>

          </ul>

        </div>
        <div class="pull-right">
          <div *ngIf="this.attestationStatus==0 && lastActivityBy && lastActivityBy.length>0">
            <label>Last Updated: {{lastActivityBy}}; {{lastActivityDate |  date: 'MM/dd/yyyy h:mm a'}}</label>
          </div>
          <div *ngIf="this.attestationStatus==1">
            <label>Last Submitted: {{lastActivityBy}}; {{lastActivityDate |  date: 'MM/dd/yyyy h:mm a'}}</label>
          </div>
          <div *ngIf="this.attestationStatusID==2">
            <label>Last Approved: {{lastActivityBy}}; {{lastActivityDate |  date: 'MM/dd/yyyy h:mm a'}}</label>
          </div>
          <div *ngIf="this.attestationStatus==4">
            <label>Last Reviewed: {{lastActivityBy}}; {{lastActivityDate |  date: 'MM/dd/yyyy h:mm a'}}</label>
          </div>
        </div>
      </div>
      <div class="full_width margintop-20">
        <h1 class="nomargin">
          {{controlDesc}}
        </h1>
        <p>{{getAttestationStatusDesc()}}</p>
      </div>
      <div class="full_width margintop-20 attestation-form">
        <ul class="nav nav-tabs">
          <li class="active"><a data-toggle="tab" href="#home">Attestation Overview</a></li>
          <li><a data-toggle="tab" href="#previousAttestations">Previous Attestation</a></li>
        </ul>
        <div class="attestation_container">
          <div class="tab-content">
            <div id="home" class="tab-pane fade in active">
              <div class="full_width">
                <form (ngSubmit)="onSubmit()" [formGroup]="form">

                  <div *ngFor="let question of questions" class="form-row">
                    <app-question [question]="question" [questions]="questions" #questionComponent [form]="form" (addGuidanceQuestion)="addQuestion($event)" [masterData]="masterData"></app-question>
                  </div>

                  <div class="full_width margintop-30" style="margin-bottom:30px" *ngIf="this.questions && this.questions.length>0">
                    <div class="attestation-btn">
                      <div>
                        <button type="submit" class="btn btn-default" [routerLink]="'/home'">Cancel</button>
                      </div>
                      <div>
                        <!--<button type="submit" class="btn btn-success" [disabled]="!form.valid  || attestationStatus !=1">Submit</button>-->
                        <button type="submit" *ngIf="showSubmitButtons()" class="btn btn-success">Submit</button>
                      </div>
                      <div>
                        <button type="button" *ngIf="showReviewButtons()" (click)="changeStatus(AttestationStatusRevision)" class="btn btn-success">Send for Revision</button>
                      </div>
                      <!--<div>
                        <button type="button" *ngIf="showReviewButtons()" (click)="changeStatus(AttestationStatusReject)" class="btn btn-success">Reject</button>
                      </div>-->
                      <div>
                        <button type="button" *ngIf="showReviewButtons()" (click)="changeStatus(AttestationStatusApprove)" class="btn btn-success">Approve</button>
                      </div>
                      <div>
                        <button type="button" class="btn btn-success" *ngIf="showSubmitButtons()" (click)="saveAsDraft()">Save as draft</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div id="previousAttestations" class="tab-pane fade">
              <div class="full_width margintop-20">
                <div class="table-responsive">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th class="font12-regular">
                          <div class="cool_gray11">
                            Attestation Date
                          </div>
                        </th>
                        <th class="font12-regular">
                          <div class="cool_gray11">
                            Submitted Date
                          </div>
                        </th>
                        <th class="font12-regular">
                          <div class="cool_gray11">Attested By</div>
                        </th>
                        <th class="font12-regular">
                          <div class="cool_gray11">Attestation Status</div>
                        </th>
                        <th class="font12-regular">
                          <div class="cool_gray11 align_center">Actions</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let attestation of previousAttestations">
                        <td class="black">{{attestation.attestationDate | date: 'MM/dd/yyyy'}}</td>
                        <td class="black">{{attestation.attestationSubmittedDate | date: 'MM/dd/yyyy'}}</td>
                        <td class="black">{{attestation.attestedBy}}</td>
                        <td class="black">{{getAttestationStatus(attestation.attestationStatus)}}</td>
                        <td class="align_center">
                          <span style="cursor:pointer !important" class="dds-icon_sm dds-icon_eyeopen" (click)="navigateToAttestationForm(attestation.controlName,attestation.timeStamp) "></span>
                        </td>
                      </tr>

                    </tbody>
                  </table>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
